export default {
  "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["頭部"])},
  "chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["胸部"])},
  "stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腹部"])},
  "frontThigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面大腿"])},
  "upperArm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上手臂"])},
  "lowerArm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下手臂"])},
  "neck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脖子"])},
  "shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["肩膀"])},
  "upperBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上背部"])},
  "middleBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中背部"])},
  "waist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["腰部"])},
  "rearThigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後大腿"])},
  "rearCalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後小腿"])},
  "lightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輕"])},
  "harder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重"])},
  "frontBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正面"])},
  "backBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背面"])}
}