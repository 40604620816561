export default {
    namespaced: true,
    state: {
        isSideMenuShow: false,
        isCheckoutLightboxShow: false,
        isPreMassageLightboxShow: false,
        isCouponLightboxShow: false,
        isChangeCouponLightboxShow: false,
        isEditStockLightboxShow: false,
        isStartServiceLightboxShow: false,
        isAddReservationShow: false,
        isShiftLightboxShow: false,
        isMerchandiseLightboxShow: false
    },
    getters: {},
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
        },
    },
    actions: {},
};
