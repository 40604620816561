<template>
  <div class="customer">
    <AromaSideMenu />
    <div class="top">
      <AromaLogo />
    </div>
    <div class="index-container">
      <div class="wrap title-wrap">
        <div class="title expansion d-none d-xl-block">工資單</div>
        <!-- <span class="branch-name">{{ aromaPayroll?.salary_name }}</span> 原本抓【門市管理>門市列表】_薪資單門市名稱-->
        <span class="branch-name">{{ aromaPayroll?.insured_unit }}</span> <!--20231107 改抓【人事管理>員工列表】_投保單位 -->
      </div>

      <div class="wrap">
        <div class="aroma-payroll">
          <transition appear name="fade" v-if="isLoading && Object.keys(aromaPayroll) > 0">
            <NoData :isFixed="false" />
          </transition>
          <transition-group appear name="fade" v-else>
            <div class="table-header fz-big  text-main-color">
              員工工資單
              <span class="date">{{ moment(aromaPayroll.date, 'YYYY-MM-DD').format('YYYY年MM月DD日') }}</span>
            </div>
            <div class="table-body">
              <dl>
                <dt>員工編號</dt>
                <dd class="text-main-color text-center">{{ aromaPayroll.member_code }}</dd>
              </dl>


              <dl>
                <dt>姓 名</dt>
                <dd class="text-main-color text-center">{{ aromaPayroll.member_name }}</dd>
              </dl>


              <dl>
                <dt>本薪</dt>
                <dd>{{ aromaPayroll.salary }}</dd>
              </dl>


              <dl>
                <dt>伙食津貼</dt>
                <dd>{{ aromaPayroll.food_allowance }}</dd>
              </dl>

              <dl>
                <dt>國定假日加班費</dt>
                <dd>{{ aromaPayroll.national_holiday_overtime_pay }}</dd>
              </dl>

              <dl>
                <dt>休息日加班費</dt>
                <dd>{{ aromaPayroll.rest_overtime_pay }}</dd>
              </dl>

              <dl>
                <dt>休息日8/3倍加班費</dt>
                <dd>{{ aromaPayroll.rest_eight_three_overtime_pay }}</dd>
              </dl>

              <dl>
                <dt>獎金</dt>
                <dd>{{ aromaPayroll.bonus }}</dd>
              </dl>

              <dl>
                <dt>平日加班費</dt>
                <dd>{{ aromaPayroll.week_day_overtime_pay_total }}</dd>
              </dl>

              <dl>
                <dt>休息日加班費</dt>
                <dd>{{ aromaPayroll.rest_overtime_pay_total }}</dd>
              </dl>

              <dl>
                <dt>國定假日加班費</dt>
                <dd>{{ aromaPayroll.vacation_overtime_pay_total }}</dd>
              </dl>

              <dl>
                <dt>特休未休</dt>
                <dd>{{ aromaPayroll.special_overtime_pay_total }}</dd>
              </dl>

              <dl>
                <dt>例假日加班費</dt>
                <dd>{{ aromaPayroll.holiday_overtime_pay_total }}</dd>
              </dl>


              <dl>
                <dt>勞退6%補貼</dt>
                <dd>{{ aromaPayroll.retirement_allowance }}</dd>
              </dl>

              <dl>
                <dt>合計</dt>
                <dd>{{ aromaPayroll.over_time_total }}</dd>
              </dl>

              <dl class="top-line">
                <dt>平日加班時數(4/3倍)(5/3倍)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.usually_four_three }}</div>
                  <div class="cell">{{ aromaPayroll.usually_five_three }}</div>
                  <div class="cell"></div>
                </dd>
              </dl>

              <dl>
                <dt>休息日加班時數(4/3倍)(5/3倍)(8/3倍)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.rest_four_three }}</div>
                  <div class="cell">{{ aromaPayroll.rest_five_three }}</div>
                  <div class="cell">{{ aromaPayroll.rest_eight_three }}</div>
                </dd>
              </dl>

              <dl>
                <dt>國定假日加班時數(1倍)(4/3倍)(5/3倍)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.vacation_one }}</div>
                  <div class="cell">{{ aromaPayroll.vacation_four_three }}</div>
                  <div class="cell">{{ aromaPayroll.vacation_five_three }}</div>
                </dd>
              </dl>

              <dl>
                <dt>特休加班加班時數(1倍)(4/3倍)(5/3倍)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.special_one }}</div>
                  <div class="cell">{{ aromaPayroll.special_four_three }}</div>
                  <div class="cell">{{ aromaPayroll.special_five_three }}</div>
                </dd>
              </dl>

              <dl>
                <dt>例假日加班時數(1倍)(4/3倍)(5/3倍)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.holiday_one }}</div>
                  <div class="cell">{{ aromaPayroll.holiday_four_three }}</div>
                  <div class="cell">{{ aromaPayroll.holiday_five_three }}</div>
                </dd>
              </dl>

              <dl>
                <dt>遲到(分鐘/扣款)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.late_minutes }}</div>
                  <div class="cell">{{ aromaPayroll.late_minutes_deduction }}</div>
                </dd>
              </dl>

              <dl>
                <dt>事假(時數/扣款)</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.personal_hours }}</div>
                  <div class="cell">{{ aromaPayroll.personal_hours_deduction }}</div>
                </dd>
              </dl>

              <dl>
                <dt>病假(時數/扣款)</dt>
                <dd class="px-0">
                  <div class="cell have-right-line">{{ aromaPayroll.sick_hours }}</div>
                  <div class="cell">{{ aromaPayroll.sick_hours_deduction }}</div>
                </dd>
              </dl>

              <dl>
                <dt>曠職(天數/扣款)</dt>
                <dd class="px-0">
                  <div class="cell have-right-line">{{ aromaPayroll.absenteeism_hours }}</div>
                  <div class="cell">{{ aromaPayroll.absenteeism_hours_deduction }}</div>
                </dd>
              </dl>

              <dl>
                <dt>扣款小計</dt>
                <dd>{{ aromaPayroll.leave_deduction }}</dd>
              </dl>

              <dl>
                <dt class="fz-big">應領金額</dt>
                <dd>{{ aromaPayroll.amount_due }}</dd>
              </dl>

              <dl class="top-line">
                <dt>勞保費</dt>
                <dd>{{ aromaPayroll.labor_insurance }}</dd>
              </dl>

              <dl>
                <dt>健保費</dt>
                <dd>{{ aromaPayroll.health_fee }}</dd>
              </dl>

              <dl>
                <dt>借支還款</dt>
                <dd>{{ aromaPayroll.debit_repayment }}</dd>
              </dl>

              <dl>
                <dt>其他扣項</dt>
                <dd>{{ aromaPayroll.other_deductions }}</dd>
              </dl>

              <dl>
                <dt>代付餐費</dt>
                <dd>{{ aromaPayroll.meals_pay }}</dd>
              </dl>


              <dl>
                <dt>小 計</dt>
                <dd>{{ aromaPayroll.deduction_subtotal }}</dd>
              </dl>


              <dl class="top-line">
                <dt class="fz-big">實領金額</dt>
                <dd class="text-red">{{ aromaPayroll.total_str }}</dd>
              </dl>

              <dl class="top-line">
                <dt>公司勞退提繳金額6%</dt>
                <dd>{{ aromaPayroll.retirement_allowance }}</dd>
              </dl>

              <dl class="top-line">
                <dt class="fz-big">獎金</dt>
                <dd class="px-0">
                  <div class="cell">數量</div>
                  <div class="cell">單價</div>
                  <div class="cell">獎金金額</div>
                </dd>
              </dl>

              <dl class="top-line">
                <dt>班數獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.pingban_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.pingban_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.pingban_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>加班獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.general_overtime_num }}</div>
                  <div class="cell" v-html="aromaPayroll.general_overtime_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.general_overtime_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>1+獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.one_overtime_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.one_overtime_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.one_overtime_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>補班獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.make_up_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.make_up_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.make_up_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>老點獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.old_point_amout_bouns_num }}</div>
                  <div class="cell" v-html="aromaPayroll.old_point_amout_bouns_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.old_point_amout_bouns }}</div>
                </dd>
              </dl>

              <dl>
                <dt>課程1獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.class_one_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.class_one_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.class_one_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>課程2獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.class_two_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.class_two_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.class_two_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>競賽獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.competitio_point_bonus_num }}</div>
                  <div class="cell" v-html="aromaPayroll.competitio_point_bonus_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.competitio_point_bonus }}</div>
                </dd>
              </dl>

              <dl>
                <dt>業績獎金</dt>
                <dd class="px-0">
                  <div class="cell">{{ aromaPayroll.performance_point_num }}</div>
                  <div class="cell" v-html="aromaPayroll.performance_point_unit_price"></div>
                  <div class="cell">{{ aromaPayroll.performance_point_bonus }}</div>
                </dd>
              </dl>

            </div>
            <div class="table-footer" v-if="aromaPayroll.is_confirmed === 0">
              <button class="btn reply-btn" :class="{
                'has-info': reply.msg !== ''
              }" @click="showReplyLightbox"><span>問題回報</span></button>
              <button class="btn confirm-btn" @click="showSignLightbox">確認</button>
            </div>
            <div class="table-footer sign" v-else>
              <div class="sign-wrap">
                <p class="text-main-color">員工簽名</p>
                <img :src="aromaPayroll.file_name" alt="">
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
  <SignLightbox v-model:isShow="sign.isShow" @save="salaryConfirmCheck" />
  <ReplyLightbox v-model:isShow="reply.isShow" v-model:replyMsg="reply.msg" @save="saveReply" />
</template>

<script>
import { ref, reactive, computed, nextTick, watch } from "vue";
import { useRoute } from 'vue-router'
import { useStore } from "vuex";
import { basePost, basePostFile } from "@/js/services/baseService";

// import { round } from "lodash";

import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";
import NoData from "@/components/includes/NoData.vue";
import SignLightbox from "@/components/lightbox/SignLightbox.vue";
import ReplyLightbox from "@/components/lightbox/ReplyLightbox.vue";
import moment from "moment";
import Swal from "sweetalert2";

export default {
  name: "AromaPayrollDetail",
  components: {
    AromaLogo,
    AromaSideMenu,
    NoData,
    SignLightbox,
    ReplyLightbox
  },
  setup() {
    const { state } = useStore();
    const route = useRoute()
    const id = ref(route.params.id)
    const isLoading = ref(false);

    const sign = reactive({
      isShow: false,
    })

    const showSignLightbox = () => {
      sign.isShow = true
    }

    const salaryConfirmCheck = async (image) => {
      try {
        var formData = new FormData()
        formData.append('salary_settlement_id', id.value)
        formData.append('pic', image)

        const res = await basePostFile("/api_salary/salary_confirm_check", formData);
        let { status, data } = res;

        if (status === 200 && data.res_code === 1) {
          Swal.fire({
            title: "成功!",
            icon: "success",
            confirmButtonText: "確定",
          }).then(() => {
            sign.isShow = false
          });
          getSalarySettlementsList();
        }
      } catch (err) {
        console.error(err);
      }
    };

    const reply = reactive({
      isShow: false,
      msg: ''
    })

    const showReplyLightbox = () => {
      reply.isShow = true
    }

    const saveReply = async () => {
      try {
        const res = await basePost("/api_salary/salary_problem_check", {
          salary_settlement_id: id.value,
          member_id: state.auth.member_id,
          content: reply.msg
        });
        let { status, data } = res;
        if (status === 200 && data.res_code === 1) {

          Swal.fire({
            title: "成功!",
            icon: "success",
            confirmButtonText: "確定",
          }).then(() => {
            reply.isShow = false
          });

        }
      } catch (err) {
        console.error(err);
      }
    }

    const aromaPayroll = reactive({
      is_confirmed: 1
    });
    // 取得員工工資單
    const getSalarySettlementsList = async () => {
      try {
        const res = await basePost("/api_salary/salary_settlements_detail", {
          salary_settlement_id: id.value,
        });
        let { status, data } = res;

        if (status === 200 && data.res_code === 1) {
          const { res_data } = data;
          Object.assign(aromaPayroll, res_data)
          reply.msg = res_data.problem;
        }
      } catch (err) {
        console.error(err);
      }
    };
    getSalarySettlementsList();

    return { isLoading, sign, showSignLightbox, reply, showReplyLightbox, aromaPayroll, moment, saveReply, salaryConfirmCheck };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";


.fz-big {
  font-size: 20px !important;
}

.fz-middle {
  font-size: 18px !important;
}

.text-red {
  color: #D94343 !important;
}

.text-main-color {
  color: #A4641A !important;
}

.text-center {
  justify-content: center !important;
}

.title-wrap {
  position: relative;

  .branch-name {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #0F2A68;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    line-height: 1.25;

    @media (max-width:1200px) {
      display: block;
      position: relative;

    }
  }
}

.customer {
  .index-container {
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #fff;
      margin-left: 50px;
      margin-bottom: 30px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: -15px;
        border-left: 7px solid #fff;
      }

      a {
        text-decoration: underline !important;
        font: normal normal bold 20px/27px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }
}

.wrap {
  max-width: 1424px;
  margin: auto;

  >.title {
    &.expansion {
      margin-left: -88px !important;

      @media (max-width:1480px) {
        margin-left: 0 !important;
      }
    }
  }
}

.aroma-payroll {
  // border: 1px solid #fff;
  border-radius: 15px;
  box-shadow: 0 34px 77px 0 rgba(0, 0, 0, 0.26);
  background: transparent linear-gradient(121deg, #FFFFFF 0%, #E5E5E5 18%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  padding: clamp(30px, 5vw, 75px) clamp(40px, 6vw, 90px);
  border: 1px solid #fff;

  .payroll-table {
    display: flex;
  }
}

.table-header {
  text-align: center;
  position: relative;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(112, 112, 112, .2);
  margin-bottom: clamp(24px, 3vw, 40px);
  font-weight: 700;

  @media (max-width:640px) {
    display: flex;
    flex-direction: column;
  }

  .date {
    position: absolute;
    right: 0;
    color: #7D7D7D;

    @media (max-width:640px) {
      position: relative;
      margin-top: 10px;
    }
  }
}

.table-body {
  margin-bottom: clamp(24px, 3vw, 40px);

  dl {
    display: flex;
    flex-wrap: wrap;
    // min-height: 50px;
    align-items: center;
    // overflow: hidden;
    line-height: 1.35;

    &:nth-child(even) {
      background-color: rgba(214, 214, 214, .2);
    }

    &:nth-child(odd) {
      background-color: rgba(255, 255, 255, .2);
    }

    &.top-line {
      border-top: 2px solid #000;
    }
  }

  dt,
  dd {
    width: 50%;
    padding: 17px clamp(20px, 3vw, 50px);
    font-weight: 700;

    @media (max-width:991px) {
      width: 100%;
    }
  }

  dt {
    text-align: center;
    border-right: 1px solid rgba(112, 112, 112, .2);
    letter-spacing: 2px;

    @media (max-width:991px) {
      text-align: left;
      border-right: none;
      border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
  }

  dd {
    text-align: right;
    color: #7D7D7D;
    font-size: 18px;
    overflow: hidden;

    @media (max-width:991px) {
      text-align: left;
    }

    &.text-center {
      @media (max-width:991px) {
        text-align: left !important;
      }
    }
  }


  .cell {
    display: inline-block;
    text-align: right;
    padding: 0 clamp(20px, 3vw, 50px);
    width: 33.333%;

    &.one-third {
      width: 33.33%;
    }

    &.two-thirds {
      width: 66.66%;
    }

    +.cell {
      position: relative;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 999%;
        transform: translateY(-50%);
        top: 50%;
        background-color: rgba(112, 112, 112, .2);
      }

      &::after {
        right: 0;
      }

      &::before {
        left: -1px;
      }
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    &.have-right-line {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 999%;
        transform: translateY(-50%);
        top: 50%;
        background-color: rgba(112, 112, 112, .2);
        right: 0;
      }
    }
  }
}

.table-footer {
  display: flex;
  justify-content: center;

  &.sign {
    .sign-wrap {
      margin: 0 0 0 auto;
      width: 300px;
      p {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      img {
        display: block;
        max-width: 100%;
      }
    }
  }
  .btn {
    border-radius: 11px;
    padding: clamp(12px, 1.5vw, 24px);
    color: #fff;
    min-width: clamp(100px, 18vw, 335px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 18px;
    letter-spacing: 2px;

    @media (max-width:640px) {
      margin-left: 5px;
      margin-right: 5px;
      font-size: 16px;
    }

    &:hover {
      box-shadow: inset 0px 11px 20px #00000057, 0px 3px 6px #00000029;
      ;
    }
  }

  .reply-btn {
    display: flex;
    background-color: #A2A2A2;

    &.has-info {
      span {
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 10px;
          background-color: #C80036;
          position: absolute;
          right: -10px;
          top: 0;
        }
      }
    }

  }

  .confirm-btn {
    background: transparent linear-gradient(100deg, #EBC496 0%, #A4641A 36%, #B57831 88%, #A4641A 100%) 0% 0% no-repeat padding-box
  }
}
</style>
