<template>
    <div class="customer">
        <RecordLightbox 
            v-model:opened="isRecordOpened" 
            :orderId="orderId" 
            :submitType="submitType"
            :name="customerName"
            :phone="customerPhone"
            @update="updateList"
        />
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>
                > 消費紀錄
            </div>
        </div>
        <div class="wrap">
            <div class="input-wrap">
                <div class="input-group mb-3 me-3">
                    <div class="select-wrap" style="width: 47.8%">
                        <BaseDatepicker
                            class="radius-left"
                            @selectChange="HandleStartDateChange"
                            format="YYYY.MM.DD"
                            :initVal="aMonthAgo"
                        />
                    </div>
                    <div class="select-wrap rounded-0" style="width: 4.4%; color: #707070">──</div>
                    <div class="select-wrap" style="width: 47.8%">
                        <BaseDatepicker class="radius-right" @selectChange="HandleEndDateChange" format="YYYY.MM.DD" />
                    </div>
                </div>
                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="輸入搜尋關鍵字"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        v-model.trim="keyword"
                        @keyup.enter="getConsumptionRecordList(1)"
                    />
                    <div class="input-group-append">
                        <button class="btn" type="button" @click="getConsumptionRecordList(1)">
                            <img src="@/assets/tgd/reserve/magnifier.svg" alt="" />
                        </button>
                    </div>
                </div>
            </div>
            <transition appear name="fade" v-if="isListRequest">
                <div>
                    <div class="row reserve-container">
                        <div class="reserve-wrap">
                            <div class="order-list-wrap">
                                <div class="reserve-title">
                                    會員名稱 :<span class="ps-3">{{ customerName }}</span>
                                </div>
                                <button class="add-order" @click="addRecord">
                                    新增訂單
                                </button>
                                <div class="order-list-header">
                                    <div class="row position-relative" style="left: 10px">
                                        <div class="col-2 text-center">序</div>
                                        <div class="col-2">消費日期</div>
                                        <div class="col-2">服務時間</div>
                                        <div class="col-2 text-center">消費店別</div>
                                        <div class="col-2 text-center">芳療師</div>
                                        <div class="col-2 text-center">房間</div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-2 text-center money-color">
                                            <img src="@/assets/tgd/customer/money.svg" alt="" />
                                            <span> 現金 </span>
                                        </div>
                                        <div class="col-2 text-center pay-type-color">
                                            <img src="@/assets/tgd/customer/other_money.svg" alt="" />
                                            <span> 其他支付 </span>
                                        </div>
                                        <div class="col-2 text-center sum-color">
                                            <img src="@/assets/tgd/customer/totle_money.svg" alt="" />
                                            <span> 總額 </span>
                                        </div>
                                        <div class="col-6 text-center"></div>
                                    </div>
                                </div>
                                <div
                                    class="order-list num-font"
                                    v-for="(consumptionRecord, index) in consumptionRecordList.data"
                                    :key="consumptionRecord.id"
                                >
                                    <div class="row position-relative" style="left: 10px">
                                        <div class="col-2 text-center time-color">
                                            {{ index + 1 }}
                                        </div>
                                        <div class="col-2 text-center time-color">
                                            {{ consumptionRecord.order_date }}
                                        </div>
                                        <div class="col-2 text-center time-color">
                                            {{ consumptionRecord.service_time }}
                                        </div>
                                        <div class="col-2 text-center">
                                            {{ consumptionRecord.store_name }}
                                        </div>
                                        <div class="col-2 text-center">
                                            {{ consumptionRecord.member_name }}
                                        </div>
                                        <div class="col-2 text-center">
                                            {{ consumptionRecord.room }}
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-2 text-center money-color">
                                            {{ consumptionRecord.cash_payment }}
                                        </div>
                                        <div class="col-2 text-center pay-type-color">
                                            {{ consumptionRecord.other_payment }}
                                            {{ consumptionRecord.payment_method }}
                                        </div>
                                        <div class="col-2 text-center sum-color">
                                            {{ consumptionRecord.amount }}
                                        </div>
                                        <div class="col-6 text-end pe-4">
                                                <button
                                                    v-if="consumptionRecord.garbage == 1"
                                                    class="btn-order"
                                                    @click="cancelAlert(consumptionRecord.id)"
                                                >
                                                    <img
                                                        src="@/assets/tgd/customer/delete.svg"
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    v-if="consumptionRecord.pen == 1"
                                                    class="btn-order"
                                                    @click="editRecord(consumptionRecord.id)"
                                                >
                                                    <img
                                                        src="@/assets/tgd/customer/edit.svg"
                                                        alt=""
                                                    />
                                                </button>
                                                <button
                                                    v-if="consumptionRecord.print == 1"
                                                    class="btn-order"
                                                    @click="getServiceDetails(consumptionRecord.id)"
                                                >
                                                    <img
                                                        src="@/assets/tgd/customer/print.svg"
                                                        alt=""
                                                    />
                                                </button>
                                                <p class="text" v-if="consumptionRecord.is_cancel == 1">訂單已取消</p>
                                                <button
                                                    v-if="consumptionRecord.eye == 1" 
                                                    class="btn-order"
                                                    @click="openRecord(consumptionRecord.id)">
                                                    <img src="@/assets/tgd/customer/view.svg" alt="" />
                                                </button>
                                        </div>
                                    </div>
                                </div>
                                <NoData :isFixed="false" v-show="!consumptionRecordList.data.length" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="pageInfo.totalPage > 1">
                            <BasePagination ref="pagination" :pageInfo="pageInfo" @pageChange="updatePageData" />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Logo from '@/components/includes/Logo.vue';
import NoData from '@/components/includes/NoData.vue';
import RecordLightbox from '@/components/lightbox/RecordLightbox.vue';

import { ref, reactive, watch } from 'vue';
import { useStore } from 'vuex';
import { basePost } from '@/js/services/baseService';
import moment from 'moment';
import Swal from "sweetalert2";
import { flashMessage } from "@/js/swal";

export default {
    name: 'ConsumptionRecord',
    components: {
        Logo,
        NoData,
        RecordLightbox,
    },
    setup() {
        const { state, commit, dispatch } = useStore();
        const isListRequest = ref(false);

        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });

        // 消費紀錄列表
        let consumptionRecordList = reactive({ data: [] });

        const startDate = ref();
        const endDate = ref();

        const keyword = ref('');

        const HandleStartDateChange = (selectVal) => {
            startDate.value = moment(selectVal).format('YYYY-MM-DD');
            getConsumptionRecordList();
        };
        const HandleEndDateChange = (selectVal) => {
            endDate.value = moment(selectVal).format('YYYY-MM-DD');
            getConsumptionRecordList();
        };

        // 客戶 ID
        const customerId = JSON.parse(localStorage.getItem('customerId'));
        // 客戶姓名
        const customerName = JSON.parse(localStorage.getItem('customerName'));
        // 客戶電話
        const customerPhone = JSON.parse(localStorage.getItem('customerPhone'));

        //取得消費紀錄列表
        const getConsumptionRecordList = async (num = 1) => {
            if (!startDate.value || !endDate.value) return;
            try {
                const res = await basePost('/api_customer/consumption_service_log', {
                    customer_id: customerId,
                    store_id: state.auth.store_id,
                    page: num,
                    keyword: keyword.value,
                    start_date: startDate.value,
                    end_date: endDate.value,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    consumptionRecordList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        const updatePageData = (num) => {
            getConsumptionRecordList(num);
        };

        watch(consumptionRecordList, () => {
            if (!isListRequest.value) {
                isListRequest.value = true;
            }
        });

        const aMonthAgo = moment().add(-1, 'M').format();

        // 訂單 ID
        const isRecordOpened = ref(false);
        const orderId = ref('');
        let submitType = ref(0);
        // 0新增，1編輯，2查看
        const editRecord = (val) => {
            orderId.value = val;
            submitType.value = 1
            isRecordOpened.value = true;
        };

        const addRecord = () => {
            orderId.value = 0;
            submitType.value = 0
            isRecordOpened.value = true;
        }

        const openRecord = (val) => {
            orderId.value = val;
            submitType.value = 2
            isRecordOpened.value = true;
        };


        // 取消提示
        const cancelAlert = (orderId) => {
            Swal.fire({
                title: "是否確認取消訂單?",
                showCancelButton: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
            }).then(function (result) {
                if (result.value) {
                    cancelOrder(orderId);
                }
            });
        };
        // 取消訂單
        const cancelOrder = async (orderId) => {
            try {
                const res = await basePost("/api_order/customer_cancel_order_check", {
                    order_id: orderId,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    Swal.fire({
                        title: "取消成功!",
                        icon: "success",
                        confirmButtonText: "確定",
                    }).then(() => {
                        getConsumptionRecordList(pagination.value?.selectedPageNum);
                    });
                } else {
                    flashMessage(data.res_content, "error");
                }
            } catch (err) {
                console.error(err);
            }
        };

        const updateList = () => {
            getConsumptionRecordList(pagination.value?.selectedPageNum);
        }

        const getServiceDetails = (id) => {
            dispatch('ticket/getServiceDetailsHtml', id);
        };

        // 分業的ref
        const pagination = ref()

        return {
            updatePageData,
            consumptionRecordList,
            pageInfo,
            getConsumptionRecordList,
            keyword,
            HandleStartDateChange,
            HandleEndDateChange,
            isListRequest,
            customerName,
            customerPhone,
            aMonthAgo,
            orderId,
            submitType,
            isRecordOpened,
            openRecord,
            editRecord,
            addRecord,
            cancelAlert,
            updateList,
            getServiceDetails,
            pagination
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/mixins';
@import '~@/css/grid';
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1600px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include phone {
            padding: 20px;
        }
        .input-wrap {
            position: absolute;
            top: -68px;
            right: -5px;
            display: flex;
            @media (max-width: 1400px) {
                right: 0;
            }

            .input-group {
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 11px;
                height: 53px;
                width: 350px;
                border-radius: 11px;
                overflow: hidden;

                @include pad_pro {
                    width: 300px;
                    height: 45px;
                }
                .select-wrap {
                    background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                        no-repeat padding-box;
                    display: flex;
                    align-items: center;
                    height: 53px;
                    &:nth-child(1) {
                        justify-content: flex-end;
                    }
                }
                .form-control {
                    background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                        no-repeat padding-box;
                    border: none;
                    border-radius: 11px 0px 0px 11px;
                    padding: 0 15px;
                    position: relative;
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    @include hover(90%);
                }
                .input-group-append {
                    background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                        no-repeat padding-box;
                    border-radius: 0 11px 11px 0;
                    @include hover(130%);
                    .btn {
                        height: 100%;
                        padding: 0 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @media (max-width: 1400px) {
                overflow: overlay;
                margin: 0;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #0f2a68;
                    @include pad_pro_lg {
                        font-size: 16px;
                    }
                    @include pad_pro {
                        margin: 15px 0;
                        margin-top: 20px;
                    }
                }
                .time-color {
                    color: #707070;
                }
                .money-color {
                    color: #a4641a;
                }
                .pay-type-color {
                    color: #0f2a68;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #0f2a68;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1600px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent linear-gradient(122deg, #ffffff 0%, #d9d9d9 18%, #ffffff 100%) 0% 0% no-repeat
                    padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro_lg {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                    position: relative;
                    top: 60px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                        .col-2,
                        .col-1_5 {
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            img {
                                padding-right: 5px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                        }
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
                        no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 25px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    &:hover {
                        filter: contrast(90%);
                    }

                    .btn-order {
                        border: 0;
                        padding: 0;
                        background: none;
                        margin: 0 8px;
                        vertical-align: middle;
                        img {
                            display: block;
                        }
                    }
                    .text {
                        display: inline-block;
                        vertical-align: middle;
                        margin-right: 20px;
                        font-size: 16px;
                        color: #4A4A4A;
                    }
                }
            }
        }
        .add-order {
            position: absolute;
            right: 70px;
            top: 20px;
            padding: 10px 25px;
            border-radius: 21px;
            background: #C80036;
            color: #fff;
            border: none;
            box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.16);
        }
    }
}
</style>
