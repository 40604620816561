<template>
    <div class="no-print-sec">
        <div class="mask" v-show="isShow" @click="closeSideMenu"></div>
        <transition name="slide">
            <div class="side-menu-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <div class="side-menu">
                        <a
                            href="javascript:;"
                            class="menu-icon"
                            @click="closeSideMenu"
                        >
                        </a>
                        <div class="member-name">
                            <p>Hi, {{ memberName }}</p>
                        </div>
                        <!-- 桌機版 -->
                        <template class="d-block">
                            <router-link
                                to="/"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>資訊主頁</span>
                            </router-link>
                            <router-link
                                to="/reservation_table/multi_aroma"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>預約</span>
                            </router-link>
                            <router-link
                                to="/customer"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>客戶管理</span>
                            </router-link>
                            <router-link
                                to="/handover_record"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>交班紀錄</span>
                            </router-link>
                            <router-link
                                to="/schedule"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>芳療師排班</span>
                            </router-link>
                            <router-link
                                to="/stock"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>庫存管理</span>
                            </router-link>
                            <router-link
                                to="/clock_in"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>打卡紀錄</span>
                            </router-link>
                            <a
                                href="javascript:;"
                                class="menu-item"
                                @click="closeSideMenu(), logOut()"
                            >
                                <span>登出</span>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import Swal from "sweetalert2";

export default {
    name: "SideMenu",
    setup() {
        const { state } = useStore();
        const router = useRouter();
        const isShow = computed(() => state.global.isSideMenuShow);
        const { closeSideMenu } = useHandleLightboxOpen();

        const memberName = computed(() => state.auth.member_name);

        //登出
        const logOut = () => {
            localStorage.removeItem("token");
            Swal.fire({
                title: "登出成功!",
                icon: "success",
                confirmButtonText: "確定",
            }).then(() => {
                router.push("/login");
                localStorage.removeItem("customerName", "keyword");
            });
        };

        return { isShow, closeSideMenu, memberName, logOut };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100%);
}

.side-menu-lightbox {
    position: fixed;
    left: 0;
    top: 0;
    width: 185px;
    min-height: 100%;
    z-index: 4;
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
                    background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            background: transparent
                linear-gradient(171deg, #e5e5e5 0%, #f3f3f3 13%, #e2e2e2 100%)
                0% 0% no-repeat padding-box;
            box-shadow: 36px 2px 77px #00000029;
            border: 2px solid #ffffff8c;
            position: absolute;
            z-index: 3;
            min-height: 100%;
            width: 100%;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .member-name {
                padding-top: 35px;
                padding-bottom: 20px;
                p {
                    text-align: center;
                    letter-spacing: 0px;
                    color: #707070;
                    font: normal normal 600 19px/26px Open Sans,
                        Microsoft JhengHei;
                }
            }
            .menu-icon {
                background: no-repeat
                    url("~@/assets/tgd/lightbox/menu-open.png");
                width: 40px;
                height: 32px;
            }
            .menu-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 92px;
                height: 88px;
                border-radius: 10px;
                margin-bottom: 5px;
                span {
                    text-align: center;
                    font: normal normal normal 12px/16px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #707070;
                    padding-top: 3px;
                }
                &:nth-child(1) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/house.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/house-white.png"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(2) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/date.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/date-white.png"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(3) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/user.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/user-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(4) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/2arrow.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/2arrow-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(5) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/list.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/list-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(6) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/box.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/box-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(7) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/time.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/time-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
                &:nth-child(8) {
                    padding-top: 54px;
                    background: 50% 32% no-repeat
                        url("~@/assets/tgd/lightbox/sign-out.svg");
                    border: 0.5px solid transparent;
                    transition: all 0.3s;
                    &:hover {
                        background: 50% 32% no-repeat
                                url("~@/assets/tgd/lightbox/sign-out-white.svg"),
                            transparent
                                linear-gradient(
                                    140deg,
                                    #ffef9c 0%,
                                    #f6eecb 14%,
                                    #ab7f29 100%
                                )
                                0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029,
                            -4px -5px 18px #ffffffad;
                        border: 0.5px solid #ffffff;

                        span {
                            color: #fff;
                        }
                    }
                }
            }
            .menu-icon {
                @include hover(150%);
            }
            ul {
                li {
                    padding-top: 33px;
                    a {
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-size: 16px;
                        img {
                            width: 30px;
                        }
                        span {
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
    }
}
</style>
