<template>
  <div class="select-wrap" :style="selectStyle">
        <input type="text" ref="inputRef" @blur="onInputBlur" />
        <select
            class="text-center"
            @change="handleChange"
            :name="name"
            :value="selectedVal"
        >
            <option selected :value="0" disabled v-if="hasInitVal">
                請選擇
            </option>
            <option
                v-for="option in options"
                :key="option.id"
                :value="option.id"
            >
                {{ option.name }}
            </option>
        </select>
        <div
            class="aselect"
            :class="selectClass"
            @click="toggle()"
            :data-value="dataValue"
            :data-list="options"
        >
            <div class="selector">
                <div class="label">
                    <span>{{ dataValue }}</span>
                </div>
            </div>
        </div>
        <div :class="{ hidden: !visible, visible }">
            <ul>
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    :class="{ current: item.name === dataValue }"
                    @click="select(item.name)"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
  </div>
</template>

<script>
import { useField } from "vee-validate";
import { ref, watch, onMounted } from "vue";
export default {
    emits: ["update:selectVal", "selectChange"],
    props: {
        name: {
            type: String,
            required: true,
        },
        selectClass: {
            type: String,
            default: "",
        },
        selectStyle: {
            type: Object,
            default: {},
        },
        hasInitVal: {
            type: Boolean,
            required: false,
        },
        options: Array,
    },
    setup(props, context) {
        const { emit } = context;
        const { name, value } = props;

        let visible = ref(false);
        let dataValue = ref();
        const inputRef = ref();

        const toggle = () => {
            visible.value = !visible.value;

            if (visible.value) {
                inputRef.value.focus();
            }
        };

        const select = (option) => {
            dataValue.value = option;
            visible.value = false;
        };

        const onInputBlur = () => {
            setTimeout(() => {
                visible.value = false;
            }, 200);
        };

        // validate
        const {
            errorMessage,
            name: thisName,
            value: selectedVal,
            handleChange,
            handleBlur,
        } = useField(name, undefined, {
            initialValue: {
                year: '2021',
                month: '1',
                day: '1'
            },
        });
        const update = (val) => {
            handleChange(val);
            emit("update:selectVal", val);
        };
        const handleChangeSelect = (e) => {
            const val = e.target.value;
            update(val);
        };

        const initVal = () => {
            if (props.options) {
                for (let i = 0; i < props.options.length; i++) {
                    if (props.options[i].id == selectedVal.value) {
                        dataValue.value = props.options[i].name;
                        break;
                    }
                }
            }
        };

        const handleSelector = () => {
            handleBlur();
            emit("selectChange", selectedVal);
            update(selectedVal.value);
        };

        watch(
            selectedVal,
            (val) => {
                initVal();
            },
            {
                immediate: true,
            }
        );

        watch(dataValue, (val) => {
            let id;

            if (props.options) {
                for (let i = 0; i < props.options.length; i++) {
                    if (props.options[i].name === val) {
                        id = props.options[i].id;
                        break;
                    }
                }
            }

            handleChange(id);
            handleSelector();
        });

        onMounted(()=> {
            emit('init', {
                setValue: handleChange
            })
        })

        return {
            selectedVal,
            handleChange,
            visible,
            dataValue,
            toggle,
            select,
            onInputBlur,
            inputRef,
            handleChangeSelect
        };
    }
}
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

// 打卡紀錄日期
.select-wrap {
    @include line(16px, 15px, "", 0, 1px, rgba(112, 112, 112, 0.5), none);
    &:nth-child(1) {
        .aselect {
            padding-left: 34.5px;
        }
    }
    &:nth-child(5) {
        .aselect {
            background: no-repeat url("~@/assets/tgd/reserve/select-arrow.svg")
                    right 37px top 50%,
                transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
            padding-right: 60px;
        }
        &:after {
            display: none;
        }
    }
    select,
    .aselect {
        appearance: none;
        background: no-repeat url("~@/assets/tgd/reserve/select-arrow.svg")
                right 17px top 50%,
            transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
        border: none;
        width: 100%;
        height: 100%;
        font: normal normal 600 16px/22px Open Sans;
        letter-spacing: -0.16px;
        color: #000000;
        padding: 13px 15px;
        padding-right: 38px;
        height: 51px;
        border-radius: 0 !important;
        text-align: center;
        @include hover(90%);
        @include phone {
            padding-top: 12px;
            padding-bottom: 12px;
        }
        .selector {
            overflow: hidden;
            .expanded {
                transform: rotateZ(180deg) translateY(2px);
            }
            .label {
                display: block;
                font: normal normal bold 16px/22px Open Sans;
                letter-spacing: 0px;
                color: #000000;
                white-space: nowrap;
                span {
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

    .radius-left {
        border-radius: 11px 0 0 11px !important;
    }
    .radius-right {
        border-radius: 0 11px 11px 0 !important;
    }
}
ul {
    width: 100%;
    list-style-type: none;
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 1;
    padding: 11px;
    background: #191919 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 8px #00000054;
    border: 1px solid #ffffff;
    border-radius: 5px;
    opacity: 0.95;
    overflow: auto;
    max-height: 300px;
    &::-webkit-scrollbar-track {
            background-color: #191919;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #191919;
        }
}
li {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 9px 24px;
    overflow: hidden;
    border-radius: 5px;
    white-space: nowrap;
    &:hover {
        background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    }
    .current {
        background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    }
}

.hidden {
    visibility: hidden;
}
.visible {
    visibility: visible;
    li {
        cursor: pointer;
    }
}
input[type="text"],
select {
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
</style>

