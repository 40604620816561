<template>
    <div class="reply_lightbox">
        <div class="mask" v-show="isShow" @click="isShow = false"></div>
        <form class="reply-lightbox" v-show="isShow">
            <button type="button" class="btn-close" @click="isShow = false"></button>
            <div class="lightbox-header">
                <div class="title">問題回報</div>
            </div>
            <div class="lightbox-body">
                <div class="content">
                    <textarea name="reply-text" id="reply-text" rows="10" placeholder="請輸入" v-model="replyMsg"></textarea>
                </div>
            </div>
            <div class="lightbox-footer">
                <div class="content">
                    <button type="button" class="btn cancel-btn" @click="isShow = false">取消</button>
                    <button type="button" class="btn save-btn" @click="handleSave">儲存</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";

export default {
    name: "ReplyLightbox",
    props: {
        isShow: Boolean,
        replyMsg: String
    },
    emits: ["update:isShow", "update:replyMsg", "save"],
    setup(props, { emit }) {
        const isShow = computed({
            get() {
                return props.isShow
            },
            set(val) {
                emit("update:isShow", val)
            }
        });

        const replyMsg = computed({
            get() {
                return props.replyMsg
            },
            set(val) {
                emit("update:replyMsg", val)
            }
        });

        function handleSave() {
            emit("save", replyMsg.value)
        }

        return {
            handleSave,
            isShow, 
            replyMsg
        };
    },
};
</script>


<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.reply-lightbox {
    width: 100%;
    max-width: clamp(320px, 50vw, 900px);
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding: 40px 0 20px;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
}

.lightbox-body {
    padding: 40px 0 20px;

}

.lightbox-footer {
    // padding: 10px 33px 31px 33px;
    padding: 10px 0 31px 0;
    transition: all 0.3s ease;
    text-align: end;
}

.content {
    // width: clamp(280px,26vw,400px);
    width: 100%;
    padding-left: clamp(20px, 8vw, 70px);
    padding-right: clamp(20px, 8vw, 70px);
    margin: 0 auto;
}

#reply-text {
    width: 100%;
    border: 1px solid #A2A2A2;
    border-radius: 5px;
    box-shadow: unset;
    padding: 10px;
    line-height: 1.35;
}

.btn {
    display: inline-block;
    border-radius: 11px;
    padding: clamp(12px, 1.5vw, 24px);
    text-align: center;
    color: #fff;

    @media (max-width:640px) {
        font-size: 16px;
    }

    +.btn {
        margin-left: 10px;
    }

    &:hover {
        box-shadow: 4px 9px 12px #00000050;
        color: #fff;
    }


}

.cancel-btn {
    width: clamp(80px, 8vw, 130px);
    background-color: #A2A2A2;
    flex-grow: 1;
}

.save-btn {
    width: clamp(120px, 10vw, 170px);
    background-color: #4A4A4A;
    flex-grow: 2;
}

textarea {
    height: 290px;

    &::-webkit-scrollbar {
        width: 7px;
        background-color: #D6D6D6;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-button {
        background: transparent;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(105, 105, 105, 0.8);
        border: 1px solid slategrey;
    }

    &::-webkit-scrollbar-track {
        box-shadow: transparent;
    }
}
</style>
