<template>
    <div class="main-controller">
        <select :name="name" :value="selectedVal">
            <option v-if="isShowInitVal">請選擇</option>
            <option :value="0" v-if="isShowEmptyOption">{{ emptyOptionVal }}</option>
            <option
                v-for="option in options"
                :key="option.id"
                :value="option.id"
            >
                {{ option.name }}
            </option>
        </select>
        <input
            type="text"
            ref="fakeOptionsControllerRef"
            @blur="toggleShowFakeOptions(false)"
        />
    </div>

    <div 
        class="base-select" 
        :class="[rootClass, { 'disabled': viewAllSchedules }]" 
        ref="rootWrapperRef"
    >
        <div class="subject-item" v-if="title" ref="subjectItemRef">
            <p class="subject">{{ title }}</p>
        </div>
        <div
            class="result-item"
            :style="[getResultWith]"
            @click="toggleShowFakeOptions(true)"
        >
            <p class="result">{{ getResult }}</p>
            <div class="arrow-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="9"
                    viewBox="0 0 17 9"
                >
                    <path
                        id="Polygon_1"
                        data-name="Polygon 1"
                        d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z"
                        transform="translate(17 9) rotate(180)"
                        :fill="iconColor"
                    />
                </svg>
            </div>
        </div>
    </div>
    <transition appear name="fade">
        <div
            class="fake-options sty-scrollbar"
            :style="getFakeOptionsPosition"
            v-show="isShowFakeOptions && !disabled"
        >   
            <div 
                v-if="isFilterSearch"
                class="filter-search-box"
            >
                <input
                    ref="searchInputRef"
                    type="text"
                    v-model="search"
                    @blur="onSearchInputBlur"
                />
            </div>
            <div class="option disabled" v-if="isShowInitVal">請選擇</div>
            <div class="option" v-if="isShowEmptyOption" @click="getSelectedOption(0)">{{ emptyOptionVal }}</div>
            <div
                class="option"
                v-for="option in filterOptions"
                :key="option.id"
                @click="getSelectedOption(option.id)"
            >
                {{ option.name }}
            </div>
        </div>
    </transition>
    <div class="error-msg" v-show="errorMessage">
        {{ errorMessage }}
    </div>
</template>

<script>
import { ref, toRef, computed, onMounted } from "vue";
import { useField } from "vee-validate";

export default {
    name: "BaseSelect",
    emits: ["update:modelValue", "selectChange", 'init'],
    props: {
        name: String,
        rootClass: [Object, Array, String],
        options: {
            // object: id、name
            type: Array,
            default: () => [],
        },
        title: String,
        isShowInitVal: {
            type: Boolean,
            default: false,
        },
        isHasOptionAll: {
            type: Boolean,
            default: false,
        },
        iconColor: {
            type: String,
            default: "#fff",
        },
        modelValue: {
            type: String,
            default: "",
        },
        emptyOptionVal: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "請選擇",
        },
        isValueString: {
            type: Boolean,
            default: false,
        },
        validateOnValueUpdate: {
            type: Boolean,
            default: true,
        },
        isFilterSearch: {
            type: Boolean,
            default: false,
        },
        viewAllSchedules: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {
        const name = toRef(props, 'name');
        const { isShowInitVal, isHasOptionAll, emptyOptionVal, placeholder, isValueString, isFilterSearch } = props;
        const subjectItemRef = ref();
        const getResultWith = computed(() => {
            if (!subjectItemRef.value)
                return {
                    width: `100%`,
                };

            let subjectItemWidth = subjectItemRef.value.offsetWidth;

            return {
                width: `calc(100% - ${subjectItemWidth}px)`,
            };
        });
        const rootWrapperRef = ref();
        const isShowEmptyOption = computed(() => {
            if(props.options && props.emptyOptionVal) {
                return true;
            }

            return false;
        });

        let isShowFakeOptions = ref(false);
        let cacheFakeOptionsPosition = {
            width: "",
            top: "",
            left: "",
        };
        const getFakeOptionsPosition = computed(() => {
            if (!rootWrapperRef.value || !isShowFakeOptions.value) {
                return cacheFakeOptionsPosition;
            }

            let rootWrapperWidth = rootWrapperRef.value.offsetWidth;
            let rootWrapperHeight = rootWrapperRef.value.offsetHeight;
            let rootWrapperOffsetLeft = rootWrapperRef.value.offsetLeft;
            let rootWrapperOffsetTop = rootWrapperRef.value.offsetTop;

            cacheFakeOptionsPosition.width = `${rootWrapperWidth}px`;
            cacheFakeOptionsPosition.top = `${
                rootWrapperHeight + rootWrapperOffsetTop + 5
            }px`;
            cacheFakeOptionsPosition.left = `${rootWrapperOffsetLeft}px`;

            return cacheFakeOptionsPosition;
        });
        const fakeOptionsControllerRef = ref();

        const toggleShowFakeOptions = (status) => {

            setTimeout(()=> {

                if (props.viewAllSchedules) {
                    return;
                }
                if (status) {
                    isShowFakeOptions.value = status;
                    fakeOptionsControllerRef.value.focus();
                } 
                else {
                    if(isFilterSearch){
                        if(searchInputRef.value === document.activeElement){
                            return;
                        }
                    }
                    isShowFakeOptions.value = status;
                }
            }, 200);
        };

        const {
            errorMessage,
            name: selectName,
            value: selectedVal,
            handleChange,
        } = useField(name, undefined, {
            initialValue: isShowInitVal ? 0 : "",
            validateOnValueUpdate: props.validateOnValueUpdate
        });

        const getSelectedOption = (id) => {
            handleChange(id);
            emit("update:modelValue", selectedVal.value);
            emit("selectChange", selectedVal.value);
        };
        
        const getResult = computed(() => {
            if(isShowEmptyOption.value && selectedVal.value === 0) {
                return emptyOptionVal;
            }

            if ((!Number(selectedVal.value) || !selectedVal.value) && !isHasOptionAll && !isValueString) {
                return placeholder;
            }

            function comparison(arr, id) {
                if(arr === null) return null;

                return arr.find((item) => item.id == id);
            }

            const result = comparison(props.options, selectedVal.value);

            if(result === null) {
                handleChange(0);

                return emptyOptionVal;
            }

            if (!result) {
                if(isValueString) {
                    handleChange('');
                }else {
                    handleChange(0);
                }
                return placeholder;
            }

            const { name } = result;

            return name;
        });

        onMounted(()=> {
            emit('init', {
                setValue: getSelectedOption
            })
        })


        const searchInputRef = ref();
        const search = ref('');
        const filterOptions = computed(() => {
            if(search.value === '') return props.options;
            return props.options.filter(item => item.name.includes(search.value))
        })

        const onSearchInputBlur = () => {
            setTimeout(() => {
                isShowFakeOptions.value = false;
                search.value = '';
            }, 200);
        };

        return {
            errorMessage,
            selectedVal,
            subjectItemRef,
            getResultWith,
            rootWrapperRef,
            getFakeOptionsPosition,
            isShowFakeOptions,
            toggleShowFakeOptions,
            fakeOptionsControllerRef,
            getSelectedOption,
            getResult,
            isShowEmptyOption,
            searchInputRef,
            search,
            onSearchInputBlur,
            filterOptions
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";

.main-controller {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.base-select {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
    border-radius: 11px;
    border: 1px solid rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61),
        0px 3px 6px rgba(0, 0, 0, 0.29);
    box-sizing: border-box;
    background: linear-gradient(
        180deg,
        rgba(236, 236, 236, 1),
        rgba(249, 249, 249, 1),
        rgba(255, 255, 255, 1)
    );
    color: rgba(0, 0, 0, 1);
    overflow: hidden;
    cursor: pointer;
    transition: border 0.3s ease;

    &:hover {
        border-color: rgb(239, 155, 53);
    }

    .subject-item {
        padding: 18px 10px 18px 20px;
        position: relative;

        &::after {
            content: "";
            @include size(1px, 50%);
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(214, 214, 214, 1);
        }
    }

    &.disabled{
        background: #D6D6D6; 
        .subject-item{
            color:#888484;
            &:after{
                background: #fff;    
            }
        }   
        .result-item{
            color:#888484;
            .arrow-icon{
                background: #a2a2a2;    
            }    
        }  
        &:hover {
            border-color: rgb(255, 255, 255);
        }  
    }

    .subject {
        margin-bottom: 0;
        word-break: keep-all;
    }

    .result-item {
        width: 100px;
        padding: 18px 30px 18px 10px;
        position: relative;
    }

    .result {
        word-break: keep-all;
    }

    .arrow-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(30px, 100%);
        background: linear-gradient(
            180deg,
            rgba(164, 100, 26, 1),
            rgba(235, 196, 150, 1)
        );
        position: absolute;
        right: 0;
        top: 0;
    }
    
    &.no-style {
        box-shadow: none;
        background: #f6f6f6;
        transition:  .3s;
        .subject-item {
            padding: 15px 10px 15px 20px;
        }
        .result-item {
            padding: 15px 30px 15px 10px;
        }
        .arrow-icon {
            background: transparent;
        }

        &:hover {
            border-color: #fff;
            filter: contrast(90%);
        }
    }
}

.error-msg {
}

.fake-options {
    padding: 11px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.33);
    background: rgba(25, 25, 25, 1);
    position: absolute;
    z-index: 1000;
    
    .option {
        padding: 9px 20px;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        transition: background 0.3s ease;

        &:not(.disabled) {
            cursor: pointer;

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.filter-search-box {
    width: 100%;
    input {
        width: 100%;
        border-radius: 5px;
        padding: 5px;
    }
}
</style>
