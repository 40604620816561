<template>
    <button
        type="button"
        :class="[`base-${type}`, customClassName, { checked: checked }]"
        @click="handleClick(value)"
    >
        <span
            class="icon-wrapper"
            :style="{
                'background-color': showIconBgColor,
            }"
        >
            <img
                src="@/assets/tgd/lightbox/radio-none.svg"
                alt=""
                v-show="!checked && customClassName !== 'w-check-icon'"
            />
            <img
                src="@/assets/tgd/lightbox/radio-checked.svg"
                alt=""
                v-show="checked && customClassName !== 'w-check-icon'"
            />

            <i
                class="icon icon-check"
                v-show="checked && customClassName === 'w-check-icon'"
            ></i>
        </span>
        <span :class="['base-choice-text', textClassName]">
            <slot></slot>
        </span>
    </button>
</template>

<script>
import { computed } from "vue";
import { useField } from "vee-validate";

export default {
    name: "BaseChoice",
    emits: ["eventClick"],
    components: {},
    props: {
        customClassName: String,
        modelValue: {
            type: null,
        },
        name: String,
        type: {
            type: String,
            default: "checkbox",
        },
        value: {
            type: [String, Number],
        },
        textClassName: String,
        iconBgColor: String,
    },
    setup(props, { emit }) {
        const {name, type, value, customClassName} = props;
        // validate
        const { checked, handleChange } = useField(name, undefined, {
            type: type,
            checkedValue: value,
        });
        const showIconBgColor = computed(() => {
            if (checked.value && customClassName === "w-check-icon") {
                return props.iconBgColor;
            }

            return null;
        });

        const handleClick = (val) => {
            handleChange(val);
            emit("eventClick", checked.value);
        };

        return { checked, handleClick, showIconBgColor };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/mixins";

.base-radio,
.base-checkbox {
    display: inline-block;
    width: auto;
    padding: 0;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    margin-right: 10px;

    .icon-wrapper {
        min-width: 18px;
    }

    .base-choice-text {
        margin-left: 9px;
        font: normal normal bold 16px/21px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #000000;
    }
}

.base-checkbox {
    &.w-check-icon {
        margin-bottom: 18px;

        .icon-wrapper {
            display: inline-block;
            @include size(23px);
            border: 2px solid rgba(70, 88, 8, 0.3);
            border-radius: 4px;
        }

        .icon-check {
            font-size: 9px;
            color: #fff;
        }

        .base-choice-text {
            font-weight: 600;
        }
    }

    .icon-wrapper {
        border-radius: 3px;
    }

    .txt {
        margin-left: 5px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.base-radio {
    &.checked {
        .icon-wrapper {
        }
    }

    .icon-wrapper {
        border-radius: 50%;
        position: relative;
        vertical-align: middle;
    }
}
</style>
