<template>
    <div class="customer">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>
                >
                <router-link :to="{ name: 'CouponRecord' }"
                    >票劵紀錄</router-link
                >
                > 使用紀錄
            </div>
        </div>
        <div class="wrap">
            <div class="input-wrap">
                <div class="input-group search-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="輸入搜尋關鍵字"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        v-model.trim="keyword"
                        @keyup.enter="getTicketUseRecordList(1)"
                    />
                    <div class="input-group-append">
                        <button class="btn" type="button" @click="getTicketUseRecordList(1)">
                            <img
                                src="@/assets/tgd/reserve/magnifier.svg"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
            <transition appear name="fade" v-if="isListRequest">
                <div>
                    <div class="row reserve-container">
                        <div class="reserve-wrap">
                            <div class="order-list-wrap">
                                <div class="order-list-header">
                                    <div class="row">
                                        <div class="col-2 text-center">票劵號碼</div>
                                        <div class="col-2 text-center position-relative" style="left:-50px;">使用會員</div>
                                        <div class="col-4 position-relative" style="left:-50px;">#訂單編號</div>
                                        <div class="col-1 text-center">使用狀態</div>
                                        <div class="col-3 text-center">使用日期</div>
                                    </div>
                                </div>
                                <div
                                    class="order-list num-font"
                                    v-for="ticketUseRecord in ticketUseRecordList.data"
                                    :key="ticketUseRecord.id"
                                >
                                    <div class="row">
                                        <div class="col-2 text-center blue">
                                            {{ ticketUseRecord.ticket_number }}
                                        </div>
                                        <div class="col-2 text-center position-relative" style="left:-50px;">
                                            {{ ticketUseRecord.customers_name }}
                                        </div>
                                        <div class="col-4 gray position-relative" style="left:-50px;" >
                                            <template v-if="ticketUseRecord.is_change === 1">
                                                已兌換票券: {{ ticketTxt(ticketUseRecord.change_list) }}
                                               
                                            </template>
                                            <template v-else-if="ticketUseRecord.is_change === 2">
                                                已兌換商品: {{ merchandiseTxt(ticketUseRecord.change_list) }}
                                            </template>
                                            <template v-else>
                                                {{ ticketUseRecord.order_number }}
                                            </template>
                                        </div>
                                        <div
                                            class="col-1 text-center"
                                            :class="{
                                                'pay-type-color':
                                                    ticketUseRecord.useStatusStr ==
                                                    '已使用',
                                                'sum-color':
                                                    ticketUseRecord.useStatusStr ==
                                                    '已換劵',
                                                'sum-color':
                                                    ticketUseRecord.useStatusStr ==
                                                    '已作廢',
                                                'blue':
                                                    ticketUseRecord.useStatusStr ==
                                                    '未使用',
                                            }"
                                        >
                                            {{ ticketUseRecord.useStatusStr }}
                                        </div>
                                        <div class="col-3 text-center pay-type-color">
                                            <img
                                                src="@/assets/tgd/customer/time.svg"
                                                alt=""
                                                v-if="ticketUseRecord.use_date"
                                            />
                                            {{ ticketUseRecord.use_date }}
                                        </div>
                                    </div>
                                </div>
                                <NoData 
                                    :isFixed="false"
                                    v-show="!ticketUseRecordList.data.length"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12" v-if="pageInfo.totalPage > 1">
                            <BasePagination
                                :pageInfo="pageInfo"
                                @pageChange="updatePageData"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";

import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";

export default {
    name: "UsageRecord",
    components: {
        Logo,
        NoData
    },
    setup() {
        const { state } = useStore();
        const isListRequest = ref(false);

        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });
        let ticketUseRecordList = reactive({ data: [] });
        const keyword = ref('');

        // 訂單 ID
        const orderId = JSON.parse(localStorage.getItem("orderId"));

        //取得票劵使用紀錄
        const getTicketUseRecordList = async (num = 1) => {
            try {
                const res = await basePost("/api_customer/ticket_use_log", {
                    page: num,
                    order_id: orderId,
                    keyword: keyword.value
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    ticketUseRecordList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };
        const updatePageData = (num) => {
            getTicketUseRecordList(num);
        };
        getTicketUseRecordList();

        watch(ticketUseRecordList, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        function ticketTxt(arr) {
            return arr.map(item=> {
                return `${item.name} (${item.txt})`
            }).join(',')
        }

        function merchandiseTxt(arr) {
            return arr.map(item=> {
                return `${item.name} * ${item.txt}`
            }).join(',')
        }

        return { 
            updatePageData, 
            ticketUseRecordList, 
            pageInfo, 
            getTicketUseRecordList, 
            keyword, 
            isListRequest,
            ticketTxt,
            merchandiseTxt
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap{
        max-width: 1425px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include phone {
            padding: 20px;
        }
        .input-wrap {
            position: absolute;
            top: -68px;
            right: -5px;
            display: flex;
            @include pad_air {
                position: relative;
                top: 0;
                left: -10px;
            }
            @include phone {
                flex-direction: column;
            }
            .search-group {
                width: 350px;
                .form-control {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border: none;
                    border-radius: 11px 0px 0px 11px;
                    padding: 0 15px;
                    position: relative;
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    @include hover(90%);
                }
                .input-group-append {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border-radius: 0 11px 11px 0;
                    @include hover(130%);
                    .btn {
                        height: 100%;
                        padding: 0 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .input-group {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 11px;
                height: 53px;
                @include phone{
                    height: 45px;
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            @include pad{
                height: 100vh;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #0f2a68;
                    @include pad_pro {
                        margin: 15px 0;
                        margin-top: 20px;
                    }
                }
                .col-3 {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        padding-right: 10px;
                    }
                }
                .pay-type-color {
                    color: #707070;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #0f2a68;
                }
                .gray {
                    color: #4a4a4a;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1425px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(122deg, #ffffff 0%, #d9d9d9 18%, #ffffff 100%)
                    0% 0% no-repeat padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro {
                    margin-bottom: 15px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 30px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    &:hover {
                        filter: contrast(90%);
                    }
                }
            }

        }
    }
}
</style>
