import Swal from "sweetalert2";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener("mouseenter", Swal.stopTimer);
        // toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const flashMessage = (
    message = "message is successfully",
    icon = "success",
    timer = 1000
) => {
    Toast.fire({
        icon: icon,
        title: message,
        timer
    });
};

const confirmMessage = (
    message = "message is successfully",
    icon = "success",
    callback
) => {
    Swal.fire({
        title: message,
        icon: icon,
        confirmButtonText: "確定",
        showCancelButton: true,
        cancelButtonText: "取消",
    }).then((res) => {
        if ( res.value ) {
            callback();
        }
    });
};

export {
    flashMessage,
    confirmMessage
}
