<template>
    <div class="qrcode-card">
        <div class="qrcode-card-container" v-if="!isLoading">
            <CheckoutCardOrderLocation 
                :orderLocation="orderLocation" 
                :changeLocation="changeLocation" 
                :submitType="1" 
                v-if="orderLocation.data" 
                :customerName="customerName"
            />
            <div class="qrcode-wrap">
                <div class="qrcode-header">
                    <div class="qrcode-title">
                        <img src="@/assets/tgd/lightbox/phone-book.svg" alt="" />
                        <span>客戶備註</span>
                    </div>
                </div>
                <div class="body">
                    <div class="position-relative textarea-wrap">
                        <div class="tag-wrap px-3">
                            <button v-for="(item, idx) in remark.remarkTag" :key="item" class="tag"
                                @click="remark.remarkTag.splice(idx, 1)">
                                {{ remark.remarkTagObject[item] }}
                            </button>
                        </div>
                        <textarea v-model="customerMemo" placeholder="請輸入"></textarea>
                    </div>
                    <div class="tag-wrap">
                        <button v-for="item in remark.remarkTagLastList" :key="item.id" class="tag"
                            @click="remark.remarkTag.push(item.id)">
                            {{ item.name }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="btn-wrap">
                <button class="btn tab-menu2-close" @click="saveLocation">
                    確認
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, reactive, computed } from "vue";
import { basePost } from "@/js/services/baseService";
import CheckoutCardOrderLocation from '@/components/lightbox/CheckoutCardOrderLocation.vue';
import { useRoute } from 'vue-router';
import Swal from "sweetalert2";
import { flashMessage } from "@/js/swal";

export default {
    name: "QrCodeCard",
    components: {
        CheckoutCardOrderLocation,
    },
    setup(props) {
        const route = useRoute();
        const customerId = computed(() => route.query.customer_id);
        const reserveDetailsId = computed(() => route.query.reserve_details_id);
        const resolveReserveDetailsId = ref('');
        // 20231110 進行中訂單
        const order_id = computed(() => route.query.order_id);
        // 判斷網址是否含有order_id
        const hasOrderId = computed(() => {
            return 'order_id' in route.query && route.query.order_id !== null;
        });

        const customerName = ref('')
        const customerMemo = ref('')

        // 如果有包含order_id => 進行中訂單
        if (hasOrderId.value) {

            // 進行中訂單qr code 連結所呼叫的api
            const getCheckOutData = async () => {
                let dataObj = {
                    order_id: route.query.order_id,
                };
                const res = await basePost('/api_common/order_qrcode_location', dataObj);
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {

                    // console.log('dataName',data.res_data.customer_name)

                    customerName.value = data.res_data.customer_name;
                    customerMemo.value = data.res_data.memo_external;

                    return data.res_data;

                } 
                else {
                    flashMessage(data.res_content, "error");
                }
            };

            const orderLocation = reactive({ data: {} });
            const isLoading = ref(true);

            var return_order_id;
            getCheckOutData().then((data) => {
                isLoading.value = false
                orderLocation.data = data.order_location_ary||null;
                resolveReserveDetailsId.value = data.reserve_details_id;

                remark.remarkTagList = data.remark_tag_list;
                remark.remark = data.remark;
                remark.remarkTag = data.remark_tag;
                remark.customer_id = data.customer_id;

                // console.log('other_qrcode_location_orderID', data.order_id)

                return_order_id = data.order_id;
            });

            // 勾選或取消勾選加強部位
            const changeLocation = (e) => {
                const inputValue = e.target.value.split('-');
                const inputCheckOrNot = e.target.checked;
                const block = inputValue[0];
                const type = inputValue[1];

                if (inputCheckOrNot) {
                    orderLocation.data[block] = [];
                    orderLocation.data[block].push(type);
                } else {
                    orderLocation.data[block].splice(orderLocation.data[block].indexOf(type), 1);
                }
            };

            const remark = reactive({
                remark: '',
                remarkTag: [],
                remarkTagList: [],
                remarkTagLastList: computed(() => {
                    return remark.remarkTagList.filter((item) => {
                        return !remark.remarkTag.includes(item.id) && !item.in_use_only;
                    });
                }),
                remarkTagObject: computed(() => {
                    return remark.remarkTagList.reduce((prev, curr) => {
                        return { ...prev, [curr.id]: curr.name };
                    }, {});
                }),
                customer_id:''
            });

            async function saveLocation() {
                const customerNameText = document.querySelector('.customerNameText').value;  

                let dataObj = {
                    order_id: return_order_id,
                    location: JSON.stringify(orderLocation.data),
                    remark: customerMemo.value,
                    remark_tag: JSON.stringify(remark.remarkTag),
                    customer_id: remark.customer_id,
                    customer_name:customerNameText
                };
                // console.log('dataObj',dataObj)
                const res = await basePost('/api_common/order_qrcode_location_check', dataObj);
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    Swal.fire({
                        title: "已收到您的需求!",
                        icon: "success",
                        confirmButtonText: "確定",
                    })
                }
            }  
            return {
                isLoading,
                orderLocation,
                changeLocation,
                saveLocation,
                remark,
                customerName,
                customerMemo
            }; 
        } 
        else {
            // qr code 連結所呼叫的api
            const getCheckOutData = async () => {
                let dataObj = {
                    // customer_id: customerId.value,
                    reserve_details_id: reserveDetailsId.value,
                };
                const res = await basePost('/api_common/reserve_qrcode_location', dataObj);
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {

                    // console.log('data',data)

                    customerName.value = data.res_data.customer_name;
                    customerMemo.value = data.res_data.memo_external;

                    return data.res_data;

                } else {
                    flashMessage(data.res_content, "error");
                }
            };

            const orderLocation = reactive({ data: {} });
            const isLoading = ref(true);
            getCheckOutData().then((data) => {
                isLoading.value = false
                orderLocation.data = data.order_location_ary||null;
                resolveReserveDetailsId.value = data.reserve_details_id;

                remark.remarkTagList = data.remark_tag_list;
                remark.remark = data.remark;
                remark.remarkTag = data.remark_tag;
                remark.customer_id = data.customer_id;
            });

            // 勾選或取消勾選加強部位
            const changeLocation = (e) => {
                const inputValue = e.target.value.split('-');
                const inputCheckOrNot = e.target.checked;
                const block = inputValue[0];
                const type = inputValue[1];

                if (inputCheckOrNot) {
                    orderLocation.data[block] = [];
                    orderLocation.data[block].push(type);
                } else {
                    orderLocation.data[block].splice(orderLocation.data[block].indexOf(type), 1);
                }
            };

            const remark = reactive({
                remark: '',
                remarkTag: [],
                remarkTagList: [],
                remarkTagLastList: computed(() => {
                    return remark.remarkTagList.filter((item) => {
                        return !remark.remarkTag.includes(item.id) && !item.in_use_only;
                    });
                }),
                remarkTagObject: computed(() => {
                    return remark.remarkTagList.reduce((prev, curr) => {
                        return { ...prev, [curr.id]: curr.name };
                    }, {});
                }),
                customer_id:''
            });

            async function saveLocation() {
                const customerNameText = document.querySelector('.customerNameText').value;   
                let dataObj = {
                    reserve_details_id: resolveReserveDetailsId.value,
                    location: JSON.stringify(orderLocation.data),
                    remark: customerMemo.value,
                    remark_tag: JSON.stringify(remark.remarkTag),
                    customer_id: remark.customer_id,
                    customer_name:customerNameText
                };
                const res = await basePost('/api_common/reserve_location_check', dataObj);
                const { status, data } = res;
                
                if (status === 200 && data.res_code === 1) {
                    Swal.fire({
                        title: "已收到您的需求!",
                        icon: "success",
                        confirmButtonText: "確定",
                    })
                }
            }  
            return {
                isLoading,
                orderLocation,
                changeLocation,
                saveLocation,
                remark,
                customerName,
                customerMemo
            }; 
        }


    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.qrcode-card {
    margin: 0 -10px;

    &:before {
        content: '';
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .qrcode-card-container {
        max-width: 900px;
        margin: 0 auto;
        position: relative;
        z-index: 1;
    }

    .btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: 20px;

        .btn {
            height: 70px;
            width: 217px;
            border-radius: 11px;
            padding: 15px;
            box-shadow: 0px 3px 6px #00000029;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #4a4a4a 0% 0% no-repeat padding-box;
            font: normal normal bold 18px/24px Microsoft JhengHei;
            letter-spacing: 0px;
            filter: contrast(100%);
            transition: all 0.3s;

            &:hover {
                filter: contrast(130%);
            }
        }
    }
}

.qrcode-wrap {
    .qrcode-header {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #a4641a;

        .qrcode-title {
            font-size: 20px;
            color: #a4641a;
            display: flex;
            align-items: center;
            font-weight: bold;

            span {
                line-height: 22px;
                padding-left: 10px;
            }
        }
    }


    .body {
        padding: 20px 0 10px;
        &.remaining {
            max-height: none;
            padding-bottom: 20px;
        }
        .tag-wrap {
            display: flex;
            flex-wrap: wrap;
            padding-top: 10px;
        }

        .tag {
            color: #a4641a;
            padding: 5px;
            border: 1px solid #a4641a;
            border-radius: 11px;
            margin: 0 10px 10px 0;
        }
    }
}

.textarea-wrap {
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
    border-radius: 11px;
    @include hover(90%);
    border: 1px solid #ffffff;
    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029 ;
    textarea {
        width: 100%;
        padding: 15px 30px;
        background: transparent;
        border: none;
        border-radius: 11px;
        font: normal normal bold 18px/24px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #000000;
        resize: none;
        box-shadow: none !important;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }

        &.height-auto {
            height: auto;
        }
    }
}
</style>
