<template>
    <label :class="rootClass">
        <input
            type="radio"
            :name="name"
            :value="value"
            :checked="checked"
            @change="handleChange"
            :disabled="disabled"
        />
        <span>{{ label }}</span>
    </label>
</template>

<script>
import { useField } from "vee-validate";

export default {
    props: {
        modelValue: {
            type: String,
        },
        name: String,
        label: String,
        value: {
            type: String,
        },
        rootClass: {
            type: String,
            default: "base-radio",
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },
    setup({ value, name }) {
        const { checked, handleChange } = useField(name, undefined, {
            type: "radio",
            checkedValue: value,
        });

        return {
            checked,
            handleChange,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/grid";
@import "~@/css/mixins";
label {
    margin-bottom: 10px;
    margin-right: 10px;
    input {
        display: none;
    }
    input + span {
        display: inline-block;
        padding-left: 38px;
        line-height: 28px;
        background: url("~@/assets/tgd/lightbox/radio-none.svg") no-repeat;
        user-select: none;
    }
    input:checked + span {
        background: url("~@/assets/tgd/lightbox/radio-checked.svg") no-repeat;
        color: #a4641a;
    }
    span {
        font-size: 16px;
    }
}
</style>
