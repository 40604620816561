<template>
    <div class="no-print-sec">
        <div class="mask" v-show="isShow" @click="$emit('update:isShow', false)"></div>
        <transition name="slide">
            <div class="change-coupon-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <div class="side-menu">
                        <div class="side-menu-close" @click="$emit('update:isShow', false)">
                            <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item" style="background: #eee">
                                <div class="title mt-5">簡訊控管</div>
                                <div class="title mt-1">{{ name }}</div>
                            </div>
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item">
                                <div class="row">
                                    <div class="col-12">
                                        <div>{{ smsInfo.smsSendTicket.title }}</div>
                                        <b-form-checkbox
                                            v-model="smsInfo.smsSendTicket.value"
                                            size="lg"
                                            switch
                                            @change="changeSms('1', $event)"
                                        ></b-form-checkbox>
                                    </div>
                                    <div class="col-12">
                                        <div>{{ smsInfo.smsVoidTicket.title }}</div>
                                        <b-form-checkbox
                                            v-model="smsInfo.smsVoidTicket.value"
                                            size="lg"
                                            switch
                                            @change="changeSms('2', $event)"
                                        ></b-form-checkbox>
                                    </div>
                                    <div class="col-12">
                                        <div>{{ smsInfo.smsSatisfaction.title }}</div>
                                        <b-form-checkbox
                                            v-model="smsInfo.smsSatisfaction.value"
                                            size="lg"
                                            switch
                                            @change="changeSms('3', $event)"
                                        ></b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { basePost } from '@/js/services/baseService';
import { flashMessage } from "@/js/swal";
import { debounce } from "lodash";

export default {
    name: 'RoomRightLightbox',
    props: {
        isShow: Boolean,
        id: Number,
        name: String,
    },
    emits: ['update:isShow'],
    setup(props) {
        const { state } = useStore();
        const storeId = computed(() => state.auth.store_id);

        const smsInfo = reactive({
            smsSendTicket: {
                type: 1,
                title: '',
                value: false,
            },
            smsVoidTicket: {
                type: 2,
                title: '',
                value: false,
            },
            smsSatisfaction: {
                type: 3,
                title: '',
                value: false,
            },
        });

        

        const getSMS = async () => {
            try {
                const res = await basePost('/api_customer/sms_control', {
                    customer_id: props.id,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const resData = data.res_data;

                    smsInfo.smsSendTicket.title = resData.list[1];
                    smsInfo.smsVoidTicket.title = resData.list[2];
                    smsInfo.smsSatisfaction.title = resData.list[3];

                    smsInfo.smsSendTicket.value = !!resData.smsSendTicketStatus;
                    smsInfo.smsVoidTicket.value = !!resData.smsVoidTicketStatus;
                    smsInfo.smsSatisfaction.value = !!resData.smsSatisfactionStatus;
                }
            } catch (err) {
                console.error(err);
            }
        };

        // 有id就拿資料
        watch(
            () => props.id,
            (val) => {
                if (!val) return;
                getSMS();
            }
        );
        // // 關掉清除紀錄
        watch(
            () => props.isShow,
            (val) => {
                if (!val) {
                    smsInfo.smsSendTicket.value = false;
                    smsInfo.smsVoidTicket.value = false;
                    smsInfo.smsSatisfaction.value = false;
                }
            }
        );
        const changeSms = (type, status) => {
            setSMS(type, status);
        };

        
        const setSmsDebounce = async (type, smsStatus) => {
            try {
                const res = await basePost('/api_customer/sms_control_checked', {
                    customer_id: props.id,
                    type,
                    status: smsStatus ? 1 : 0,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    flashMessage('修改成功')
                }
            } catch (err) {
                console.error(err);
            }
        };
        const setSMS = debounce(setSmsDebounce, 250);

        return {
            smsInfo,
            changeSms,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/mixins';
@import '~@/css/grid';
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 350px;
.change-coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    background: #f8f8f8;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu-top {
            .side-menu-item {
                padding-top: 21px;
                padding-bottom: 15px;
                padding-left: 30px;
                padding-right: 30px;
            }
            .col-12 {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .title {
            color: #000;
            font-size: 22px;
            font-weight: bold;
        }
    }
}
</style>
