<template>
    <PunchProcessLightbox
        :isShow="isShowModal"
        :leaveTime="correspondLeaveTime"
        :canSignIn8="canSignIn8"
        @closeModal="handleCloseModal"
        @send="handleSendSignChecked"
    />
    <div class="login">
        <input
            type="text"
            class="position-absolute opacity-0"
            v-model="cardNumber"
            @change="getMemberNum"
            ref="cardNumberRef"
            @blur="handleBlur"
        />
        <div class="login-container">
            <h2 :class="{ 'opacity-0': isHiddenMsg }">
                hi {{ memberInfo.name }}, 現在時間:
                {{ moment().format("HH:mm") }}
            </h2>
            <h3 class="pb-0" :class="{ 'opacity-0': isHiddenMsg }">
                {{ showSubject }}打卡成功：<span>{{ memberInfo.time }}</span>
            </h3>
            <h3
                :class="{
                    'opacity-0': isHiddenMsg || !memberInfo.overtimeZone,
                }"
            >
                加班時間：<span>{{ memberInfo.overtimeZone }}</span>
            </h3>
            <div class="row justify-content-center">
                <div class="col-6">
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text" >卡片號碼</span>
                        </div>
                        <input
                            class="form-control"
                            type="text"
                            v-model="inputCardNumber"
                            placeholder="請輸入卡號"
                        />
                    </div>
                </div>
            </div>
            <div class="login-type row">
                <div class="col-4">
                    <input
                        id="work"
                        type="radio"
                        v-model="type"
                        value="entry"
                        :disabled="isChecking"
                        @click="inputFocus"
                    />
                    <label for="work" class="type">
                        <img src="@/assets/tgd/aroma/work.png" alt="" />
                        <div class="title">上班</div>
                    </label>
                </div>
                <div class="col-4">
                    <input
                        id="sign_in"
                        type="radio"
                        v-model="type"
                        value="sign"
                        :disabled="isChecking"
                        @click="inputFocus"
                    />
                    <label for="sign_in" class="type">
                        <img src="@/assets/tgd/aroma/sign_in.png" alt="" />
                        <div class="title">簽到</div>
                    </label>
                </div>
                <div class="col-4">
                    <input
                        id="get_out_work"
                        type="radio"
                        v-model="type"
                        value="out"
                        :disabled="isChecking"
                        @click="inputFocus"
                    />
                    <label for="get_out_work" class="type">
                        <img src="@/assets/tgd/aroma/get_ott_work.png" alt="" />
                        <div class="title">下班</div>
                    </label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, watch, reactive, computed, onMounted } from "vue";
import moment from "moment";
import { basePost } from "@/js/services/baseService";
import PunchProcessLightbox from "@/components/lightbox/PunchProcessLightbox";
import useSwalCustom from "@/hooks/common/useSwalCustom";

export default {
    name: "ClockIndex",
    components: { PunchProcessLightbox },
    setup() {
        const swalCustom = new useSwalCustom({
            confirmButtonText: "確定",
        });
        const isChecking = ref(false);
        let isHiddenMsg = ref(true);

        let isShowModal = ref(false);
        let type = ref("");
        const cardNumberRef = ref();
        let cardNumber = ref("");

        let correspondLeaveTime = ref("");

        const showSubject = ref('');

        const memberInfo = reactive({
            name: "",
            time: "",
            overtimeZone: "",
        });
        let cleanMemberInfoTimer = null;
        const cleanMemberInfo = (timeout = 0) => {
            clearTimeout(cleanMemberInfoTimer);

            cleanMemberInfoTimer = setTimeout(() => {
                memberInfo.name = "";
                memberInfo.time = "";
                memberInfo.overtimeZone = "";
                isHiddenMsg.value = true;
            }, timeout);
        };

        const punch = async (type, num) => {
            try {
                const res = await basePost("/api_member/member_punch_checked", {
                    type,
                    card_number: num,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    memberInfo.name = data.res_data.member_name;
                    memberInfo.time = moment().format("HH:mm");
                    memberInfo.overtimeZone = "";

                    swalCustom
                        .fire({
                            title: "打卡成功",
                            icon: "success",
                        })
                        .then(() => {
                            isChecking.value = false;
                            cardNumber.value = "";
                            inputCardNumber.value = "";
                            cleanMemberInfo(5000);
                        });

                    isHiddenMsg.value = false;
                } else {
                    swalCustom
                        .fire({
                            title: "錯誤",
                            text: data.res_content,
                            icon: "error",
                        })
                        .then(() => {
                            isChecking.value = false;
                            cardNumber.value = "";
                        });
                    cleanMemberInfo();
                }
            } catch (err) {
                swalCustom
                    .fire({
                        title: "錯誤",
                        text: err.message,
                        icon: "error",
                    })
                    .then(() => {
                        isChecking.value = false;
                        cardNumber.value = "";
                    });

                console.error(err);

                cleanMemberInfo();
            }
        };

        const canSignIn8 = ref(false);
        const getSignInfo = async (num) => {
            try {
                const res = await basePost("/api_member/sign_in", {
                    card_number: num,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { type, scheduled_end_time, member_type, member_name, is_vacation } =
                        data.res_data;

                    if (member_type !== 1) {
                        memberInfo.name = member_name;
                        memberInfo.time = moment().format("HH:mm");
                        memberInfo.overtimeZone = "";
                        isHiddenMsg.value = false;

                        swalCustom
                        .fire({
                            title: "簽到成功",
                            icon: "success",
                        })
                        .then(() => {
                            isChecking.value = false;
                            cardNumber.value = "";
                            inputCardNumber.value = "";
                            cleanMemberInfo(5000);
                        });
                        return;
                    }
                    if (is_vacation == 1) {
                        memberInfo.name = member_name;
                        isShowModal.value = true;
                        correspondLeaveTime.value = scheduled_end_time;
                        if(type == 1) {
                            //    可以簽八
                            canSignIn8.value = true;
                        } else {
                            canSignIn8.value = false;
                        }
                    } else {
                        // 休息日上班
                        handleSendSignChecked({ over_time: "" }, 0);
                        isChecking.value = false;
                        cardNumber.value = "";
                        inputCardNumber.value = "";
                    }
                } else {
                    swalCustom
                        .fire({
                            title: "錯誤",
                            text: data.res_content,
                            icon: "error",
                        })
                        .then(() => {
                            isChecking.value = false;
                            cardNumber.value = "";
                        });

                    isHiddenMsg.value = true;
                }
            } catch (err) {
                swalCustom
                    .fire({
                        title: "錯誤",
                        text: err.message,
                        icon: "error",
                    })
                    .then(() => {
                        isChecking.value = false;
                        cardNumber.value = "";
                    });

                console.error(err);

                isHiddenMsg.value = true;
            }
        };

        const getMemberNum = (e) => {
            const num = e.target.value;

            switch (type.value) {
                case "entry":
                case "out":
                    isChecking.value = true;
                    punch(type.value, num);
                    break;
                case "sign":
                    isChecking.value = true;
                    getSignInfo(num);
                    break;
            }
        };

        const handleSendSignChecked = async (time, sign_eight) => {
            try {
                const card_number = cardNumber.value || inputCardNumber.value;
                const { over_time } = time;

                const res = await basePost("/api_member/sign_checked", {
                    card_number,
                    over_time,
                    sign_eight 
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    swalCustom
                        .fire({
                            title: "簽到成功",
                            icon: "success",
                        })
                        .then(() => {
                            isChecking.value = false;
                            isShowModal.value = false;
                            cardNumber.value = "";
                            inputCardNumber.value = ""
                            cleanMemberInfo(5000);
                        });

                    const { over_start_time, over_end_time } =
                        data.res_data;
                    memberInfo.time = moment().format("HH:mm");

                    if (over_time) {
                        memberInfo.overtimeZone = `${over_start_time} - ${over_end_time}`;
                    }else {
                        memberInfo.overtimeZone = "";
                    }

                    isHiddenMsg.value = false;
                } else {
                    swalCustom
                        .fire({
                            title: "錯誤",
                            text: data.res_content,
                            icon: "error",
                        })
                        .then(() => {
                            isChecking.value = false;
                            isShowModal.value = false;
                            cardNumber.value = "";
                        });

                    cleanMemberInfo();
                }

                isShowModal.value = false;
            } catch (err) {
                swalCustom
                    .fire({
                        title: "錯誤",
                        text: err.message,
                        icon: "error",
                    })
                    .then(() => {
                        isChecking.value = false;
                        isShowModal.value = false;
                        cardNumber.value = "";
                    });

                console.error(err);

                cleanMemberInfo();
                isShowModal.value = false;
            }
        };

        const inputFocus = () => {
            cardNumberRef.value.focus();
        };
        watch(type, (type) => {
            if(type) {
                switch (type) {
                    case "entry":
                        showSubject.value = "上班";
                        break;
                    case "out":
                        showSubject.value = "下班";
                        break;
                    case "sign":
                        showSubject.value = "簽到";
                        break;
                }

                isHiddenMsg.value = true;
                clearTimeout(cleanMemberInfoTimer);
            }
            inputFocus();
        });

        const handleCloseModal = () => {
            handleSendSignChecked({ over_time: "" }, 0);
            isChecking.value = false;
            cardNumber.value = "";
            isShowModal.value = false;
        };

        const handleBlur = () => {
            type.value = "";
        };
        const inputCardNumber = ref('');

        watch([type, inputCardNumber], ([type, inputCardNumber]) => {
            if(type && inputCardNumber) {
                inputCardNumberChecked(type, inputCardNumber);
            }
        });

        const inputCardNumberChecked = (type, num) => {
            switch (type) {
                case "entry":
                case "out":
                    isChecking.value = true;
                    punch(type, num);
                    break;
                case "sign":
                    isChecking.value = true;
                    getSignInfo(num);
                    break;
            }
        }

        watch(()=>isShowModal.value, isShowModal => {
            if(!isShowModal) {
                canSignIn8.value = false;
            }
        })

        onMounted(() => {
            const fixedOrderInfo = document.getElementById('fixed_order_info');

            if (fixedOrderInfo) {
                fixedOrderInfo.style.display = 'none';
            }
        });


        return {
            isShowModal,
            handleCloseModal,
            type,
            cardNumberRef,
            isHiddenMsg,
            cardNumber,
            getMemberNum,
            memberInfo,
            handleSendSignChecked,
            isChecking,
            showSubject,
            inputFocus,
            moment,
            correspondLeaveTime,
            handleBlur,
            inputCardNumber,
            canSignIn8
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.login {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    .login-container {
        max-width: 900px;
        margin: 0 auto;
        padding: 0 15px;
        h2 {
            text-align: center;
            font: normal normal bold 30px Open Sans;
            letter-spacing: 0px;
            color: rgba(125, 125, 125, 1);
            padding-bottom: 5%;
        }
        h3 {
            text-align: center;
            font: normal normal bold 44px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            padding-bottom: 40px;
            span {
                font: normal normal bold 40px/55px Open Sans;
                letter-spacing: 0px;
                color: rgba(164, 100, 26, 1);
            }
        }
    }
    .login-type {
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;

        input[type="radio"] {
            display: none;
        }
        input[type="radio"]:checked + .type {
            background: transparent
                linear-gradient(180deg, #a4641a 0%, #ebc496 100%) 0% 0%
                no-repeat padding-box;
            .title,
            span {
                color: #fff;
            }
            &:hover {
                filter: contrast(100%);
            }
        }
        .type {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: transparent
                linear-gradient(180deg, #c9c9c9 0%, #d2d2d2 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            box-shadow: -3px -4px 17px #ffffff9c;
            border: 3px solid rgba(255, 255, 255, 0.5);
            border-radius: 99px;
            padding: 45px;
            padding-top: 25px;
            cursor: pointer;
            filter: contrast(100%);
            transition: all 0.3s;
            &:hover {
                filter: contrast(130%);
            }
            img {
                width: 175px;
            }
            .title {
                text-align: center;
                font: normal normal bold 36px/48px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #4a4a4a;
            }
            span {
                text-align: center;
                font: normal normal bold 28px/38px Open Sans;
                letter-spacing: 0px;
                color: #707070;
            }
        }
    }
    .input-area {
        padding-bottom: 175px;
        .btn-wrap {
            display: flex;
            justify-content: center;
            .btn {
                background: transparent
                    linear-gradient(
                        108deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 15px;
                font: normal normal bold 21px/28px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
                width: 211px;
                height: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                @include hover(130%);
                img {
                    padding-right: 10px;
                    width: 45px;
                }
            }
        }
        .time {
            display: flex;
            justify-content: center;
            padding-top: 28px;
            letter-spacing: 0px;
            color: #b77f1a;
            font: normal normal bold 40px/55px Open Sans;
            span {
                padding: 0 50px;
            }
        }
    }
    .btn {
        width: 80px;
        height: 54px;
        display: flex;
        justify-content: center;
        align-items: center;
        filter: contrast(100%);
        transition: all 0.3s;
        &:hover {
            filter: contrast(140%);
        }
        &.btn-checkout {
            background: transparent
            linear-gradient(
                    293deg,
                    #a4641a 0%,
                    #a4641a 64%,
                    #ebc496 100%
            )
            0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff85;
            border-radius: 11px;
            color: #fff;
            font: normal normal bold 18px/24px Microsoft JhengHei;
            margin-right: 15px;
        }
    }
}

.input-group {
    box-shadow: 0 3px 6px #00000029 !important;
    border-radius: 11px;
    height: 53px !important;
    position: relative;
    .input-group-text,
    .form-control {
        background: transparent linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0 0 no-repeat;
        border: none;
    }
    .input-group-text {
        border-radius: 11px 0 0 11px;
        padding: 15px;
        position: relative;
        font-weight: bold;
        &:after {
            content: "";
            position: absolute;
            border-right: 1px solid #eee;
            top: 10px;
            bottom: 10px;
            right: 0;
            z-index: 1;
        }
    }
    .form-control {
        border-radius: 0 11px 11px 0;
        padding: 14px;
        font: normal normal 600 16px/22px Open Sans;
    }
}
</style>
