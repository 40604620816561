<template>
   <div :class="`no-data ${isFixed ? 'is-fixed': ''}`">
       <img src="@/assets/no-data.svg" alt="">
       <span>NO DATA</span>
   </div>
</template>

<script>
export default {
    props: {
        isFixed: {
            type: Boolean,
            default: true
        }
    }
}
</script>

<style scoped lang="scss">
.no-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span{
        font: normal normal 600 28px/35px Open Sans;
        letter-spacing: 0px;
        color: #949494;
        padding-top: 15px;
    }
    &.is-fixed {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
