<template>
    <div class="stock">
        <EditStockLightbox @submited="onStockSubmited()" />
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">庫存管理</div>
        </div>
            <div class="wrap">
                <div class="input-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="輸入搜尋關鍵字"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        v-model.trim="searchKeyword"
                        @keyup.enter="getStockList()"
                    />
                    <div class="input-group-append">
                        <button class="btn" type="button" @click="getStockList()">
                            <img src="@/assets/tgd/reserve/magnifier.svg" alt="" />
                        </button>
                    </div>
                </div>
                <transition appear name="fade" v-if="isListRequest">
                    <NoData v-show="!stockList.data.length" />
                </transition>
                <transition appear name="fade" v-if="isListRequest">
                    <div class="row reserve-container" v-show="stockList.data.length">
                        <div class="reserve-wrap">
                            <div class="order-list-wrap">
                                <div class="row order-list-header">
                                    <div
                                        class="col-2 text-center position-relative"
                                        style="left: -30px"
                                    >
                                        序
                                    </div>
                                    <div
                                        class="col-4 text-center position-relative"
                                        style="left: -110px"
                                    >
                                        品名
                                    </div>
                                    <div
                                        class="col-1 text-center position-relative"
                                        style="left: -50px"
                                    >
                                        剩餘數量
                                    </div>
                                    <div
                                        class="col-2 text-center position-relative"
                                        style="left: -30px"
                                    >
                                        數量
                                    </div>
                                    <div
                                        class="col-3 position-relative"
                                        style="left: -10px"
                                    ></div>
                                </div>
                                <div
                                    class="row order-list"
                                    v-for="(item, index) in stockList.data"
                                    :key="item.id"
                                >
                                    <div
                                        class="col-2 text-center position-relative"
                                        style="left: -30px"
                                    >
                                        {{ index + 1 }}
                                    </div>
                                    <div
                                        class="
                                            col-4
                                            d-flex
                                            align-items-center
                                            position-relative
                                        "
                                        style="left: -70px"
                                    >
                                        <!-- 2022/1/28 PM : 先引藏照片 -->
                                        <!-- <div class="img-wrap">
                                            <img
                                                src="@/assets/tgd/stock/ex-img@2x.png"
                                                alt=""
                                            />
                                        </div> -->
                                        {{ item.name }}
                                    </div>
                                    <div
                                        class="
                                            col-1
                                            text-center
                                            num-color
                                            position-relative
                                        "
                                        style="left: -50px"
                                    >
                                        {{ item.amount  }}
                                    </div>
                                    <div
                                        class="
                                            col-2
                                            text-center
                                            d-flex
                                            justify-content-center
                                            position-relative
                                        "
                                        style="left: -30px"
                                    >
                                        <div
                                            class="counter"
                                        >
                                            <BaseCounter
                                                :name="`${item.id}`"
                                                class="stock-counter"
                                                :value="item.num || 0"
                                                v-model="item.num"
                                                :maxAmount="99999"
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="
                                            col-3
                                            d-flex
                                            justify-content-end
                                            position-relative
                                        "
                                        style="left: -10px"
                                    >
                                        <div class="btn-wrap">
                                            <a
                                                href="javascript:;"
                                                class="btn btn-add"
                                                @click="addCart(item)"
                                                >加入</a
                                            >

                                            <a
                                                href="javascript:;"
                                                class="btn btn-consume"
                                                @click="useNow(item)"
                                                >立即領用</a
                                            >
                                            <router-link
                                                :to="{ name: 'Record', params: { commodity_id: item.id }}"
                                                class="btn btn-coupon"
                                                @click="passId(item.id)"
                                                >紀錄查看
                                                </router-link
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        <div class="submit-btn">
            <button class="btn" @click="toSubmit()">
                <img src="@/assets/tgd/reserve/note.svg" alt="" />
                送出修改庫存
            </button>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

import Logo from "@/components/includes/Logo.vue";
import EditStockLightbox from "@/components/lightbox/EditStockLightbox.vue";
import NoData from "@/components/includes/NoData.vue";
import { flashMessage } from "@/js/swal";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "Stock",
    components: {
        Logo,
        EditStockLightbox,
        NoData,
        FixedOrderInfo
    },
    setup() {
        const { state, commit, dispatch, getters } = useStore();

        const isListRequest = ref(false);
        const isShow = computed(() => state.global.isEditStockLightboxShow);

        const cart = computed(()=> getters["stockCart/list"]);
        const cartNum = computed(()=> Object.keys(cart.value.data).length )
        const storeId = computed(() => {
            return state.auth.store_id;
        })
        const { openEditStockLightbox } = useHandleLightboxOpen();
        const stockList = reactive({ data: [] });
        const searchKeyword = ref('');

        const addCart = (item) => {

            if ( !checkSelectItem(item) ) return;

            dispatch("stockCart/add", item);
            flashMessage("加入修改庫存");
        };

        // 取得庫存
        const getStockList = async () => {
            try {
                const res = await basePost("/api_commodity/commodity_list", {
                    store_id: storeId.value,
                    keyword: searchKeyword.value
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    stockList.data = res_data.list;
                }
            } catch {
                console.error(err);
            }
        };

        // 取得庫存
        const useNow = async (item) => {

            if ( !checkSelectItem(item) ) return;

            try {
                const res = await basePost("/api_commodity/commodity_check", {
                    store_id: storeId.value,
                    type: 4,
                    commodity: JSON.stringify([
                        {
                            commodity_id: item.id,
                            amount: item.num,
                        },
                    ]),
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    flashMessage("成功領用");
                    item.amount =  item.amount - item.num;
                } else {
                    flashMessage(data.res_content, "error");
                    getStockList();
                }
            } catch {
                console.error(err);
            }
        };

        function itemInstock(item){
            return parseFloat(item.amount) > 0;
        }

        function checkSelectItem(item){
            var pass = true;

            // if ( !(parseFloat(item.amount) > 0) ) {
            //     flashMessage("庫存不足", "info");
            //     pass = false;
            //     return pass;
            // }

            if ( !(parseFloat(item.num) > 0)  ) {
                flashMessage("請輸入數量", "info");
                pass = false
                return pass;
            }

            return pass;
        }

        function toSubmit(){

            if ( cartNum.value <= 1) {

                flashMessage("請先加入品項!", "info");

                return
            }

            openEditStockLightbox()
        }

        function onStockSubmited(){
            stockList.data.forEach((item)=>{
                item.num = 0
            })
        }

        watch(stockList, () => {
            if (!isListRequest.value) {
                isListRequest.value = true;
            }
        });

        watch(isShow, () => {
            if (!isShow.value) {
                getStockList();
            }
        });

        getStockList();

        const passId = (commodity_id) => {
            commit("passIdToNextPage/update", { key: "commodityId", value: commodity_id })
            localStorage.setItem("commodityId", JSON.stringify(commodity_id));
        }

        return {
            stockList,
            addCart,
            useNow,
            searchKeyword,
            getStockList,
            isListRequest,
            itemInstock,
            toSubmit,
            onStockSubmited,
            passId
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.stock {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include phone {
            padding: 20px;
        }
        .input-group {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            width: 300px;
            position: absolute;
            right: -5px;
            top: -67px;
            @include phone {
                height: 45px;
                position: relative;
                top: 0;
                right: 10px;
            }
            .form-control {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 0 15px;
                position: relative;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #000000;
                @include hover(90%);
            }
            .input-group-append {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                border-radius: 0 11px 11px 0;
                @include hover(130%);
                .btn {
                    height: 100%;
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .order-list-wrap {
                    min-height: 938px;
                    margin-bottom: 15px;
                    box-shadow: 0px 34px 77px #00000042;
                    border: 1px solid #ffffff;
                    border-radius: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    width: 1400px;
                    &:hover {
                        filter: contrast(105%);
                    }
                    @include pad_pro_lg {
                        box-shadow: unset;
                    }
                }
                .order-list-wrap {
                    background: transparent
                        linear-gradient(
                            122deg,
                            #ffffff 0%,
                            #d9d9d9 18%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    padding: 75px;
                    padding-bottom: 45px;
                    font: normal normal bold 16px/22px Open Sans;
                    @include pad_pro_lg {
                        padding: 0 20px;
                        padding-bottom: 40px;
                        padding-top: 60px;
                    }
                    @include pad_pro {
                        margin-bottom: 15px;
                    }
                    @include pad {
                        font-size: 14px;
                    }
                    .order-list-header {
                        position: relative;
                        top: -35px;
                    }
                    .order-list {
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        padding: 15px 0;
                        align-items: center;
                        margin-bottom: 15px;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(90%);
                        }
                        .img-wrap {
                            padding-right: 35px;
                            display: flex;
                            img {
                                border-radius: 7px;
                                width: 98px;
                                height: 59px;
                            }
                        }
                        .btn-wrap {
                            display: flex;
                            .btn {
                                width: 122px;
                                height: 42px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                filter: contrast(100%);
                                transition: all 0.3s;
                                border-radius: 21px;
                                font: normal normal bold 16px/21px Microsoft
                                    JhengHei;
                                color: #fff;
                                &:hover {
                                    filter: contrast(140%);
                                }
                                @include pad {
                                    font-size: 14px;
                                }
                            }
                            .btn-consume {
                                background: #4a4a4a 0% 0% no-repeat padding-box;
                                margin-right: 25px;
                            }
                            .btn-coupon {
                                background: #a2a2a2 0% 0% no-repeat padding-box;
                            }
                            .btn-add {
                                text-decoration: underline !important;
                                font: normal normal bold 16px/21px Microsoft JhengHei;
                                letter-spacing: 0px;
                                color: #0F2A68;
                                margin-right: 44px;
                                width: 36px;

                            }
                        }
                        .num-color {
                            color: #a4641a;
                            font: normal normal bold 22px/30px Open Sans;
                            @include pad {
                                font-size: 18px;
                            }
                        }
                        .counter {
                            width: 130px;
                            height: 53px;
                            border-radius: 11px;
                            background: #e5e5e5 0% 0% no-repeat padding-box;
                            display: flex;
                            justify-content: space-around;
                            align-items: center;
                            font: normal normal bold 22px/30px Open Sans;
                            letter-spacing: 0px;
                            color: #0f2a68;
                            transition: all 0.3s;
                            &:hover {
                                background: #dbdbdb 0% 0% no-repeat padding-box;
                                box-shadow: inset 0px 3px 6px #00000029;
                            }
                            a {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            @include pad {
                                font-size: 18px;
                            }
                        }
                        .counter-disable {
                            filter: grayscale(100%);
                            opacity: 0.5;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .submit-btn {
        position: fixed;
        right: 50px;
        bottom: 50px;
        @include phone {
            right: 30px;
            bottom: 30px;
        }
        .btn {
            background: transparent
                linear-gradient(180deg, #ebc496 0%, #a4641a 36%, #a4641a 100%)
                0% 0% no-repeat padding-box;
            box-shadow: 0px 14px 18px #2c2e3b52;
            border: 1px solid #ffffff85;
            border-radius: 11px;
            font: normal normal bold 18px/24px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #ffffff;
            width: 268px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            @include hover(130%);
            @include pad {
                width: 250px;
                height: 65px;
                font-size: 16px;
            }
            @include phone {
                width: 150px;
                height: 53px;
                font-size: 14px;
            }
            img {
                padding-right: 10px;
            }
        }
    }
}
</style>
