<template>
    <div class="no-print-sec">
        <div
            class="mask"
            v-show="isShow"
            @click="closeChangeCouponLightbox"
        ></div>
        <transition name="slide">
            <div class="change-coupon-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <form class="side-menu"  @submit="toSubmit">
                        <div
                            class="side-menu-close"
                            @click="closeChangeCouponLightbox"
                        >
                            <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item">
                                <div class="title mb-3">原始票劵：</div>
                                <div class="row form">
                                    <div
                                        class="col-5 position-relative"
                                        style="z-index: 100"
                                    >
                                        <BaseInput
                                            title="類型"
                                            type="text"
                                            class="form-control"
                                            name="select_coupon"
                                            readonly
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="劵號"
                                            placeholder="請輸入"
                                            type="text"
                                            class="form-control"
                                            rootClass="coupon-text"
                                            name="input_coupon"
                                            @blur="checkTicket()"
                                            :isCoupon="true"
                                        />
                                    </div>
                                </div>
                                <div class="title mb-3 pt-3">購買會員：</div>
                                <div class="row form">
                                    <div class="col-5">
                                        <BaseInput
                                            title="會員電話"
                                            type="tel"
                                            class="form-control"
                                            name="phone"
                                            readonly
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="會員名稱"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            readonly
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu-item" style="background: #F8F8F8;">
                                <div class="mb-3">
                                    <BaseRadio
                                        class="me-3"
                                        type="radio"
                                        label="券換券"
                                        value="ticket"
                                        name="ticket_or_merchandise"
                                    />
                                    <BaseRadio
                                        label="券換商品"
                                        type="radio"
                                        value="merchandise"
                                        name="ticket_or_merchandise"
                                    />
                                </div>
                                <div class="form row" v-show="values.ticket_or_merchandise === 'ticket'">
                                    <div class="col-9 p-0">
                                        <BaseSelect
                                            title=""
                                            :options="ticketInfo.change_ticket_list"
                                            name="ticket_change_id"
                                        />
                                    </div>
                                    <template
                                            v-for="(field, idx ) in fieldsTicket"
                                            :key="field.key"
                                        >
                                        <div class="row g-0">
                                            <div class="col-5 px-0">
                                                <BaseInput
                                                    title="類型"
                                                    type="text"
                                                    class="form-control"
                                                    :name="`ticket[${idx}].name`"
                                                    disabled
                                                />
                                            </div>
                                            <div class="col-7">
                                                <BaseInput
                                                    title="票號"
                                                    placeholder="請輸入"
                                                    type="text"
                                                    class="form-control"
                                                    :name="`ticket[${idx}].ticket_number`"
                                                />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="form row" v-show="values.ticket_or_merchandise === 'merchandise'">
                                    <div class="col-9 p-0">
                                        <BaseSelect
                                            title=""
                                            :options="ticketInfo.change_merchandise_list"
                                            name="merchandise_change_id"
                                        />
                                    </div>
                                    <div class="col-9 p-0">
                                        <template
                                            v-for="(field, idx) in fieldsMerchandise"
                                            :key="field.key"
                                        >
                                            <div class="row no-gutters g-0 merchandise-info">
                                                <div class="col-8 fw-bold">
                                                    {{ field.value.name }}
                                                </div>
                                                <div class="col-4 text-end num-color">
                                                    {{ field.value.num }}
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="side-menu-bottom">
                            <div class="side-menu2-item">
                                    <div class="row no-gutters sum">
                                        <div class="col-5">差額</div>
                                        <div class="col-7">
                                            $ {{ numberFormat(coupon_count) }}
                                        </div>
                                    </div>
                            </div>
                            <div class="side-menu2-item">
                                <div class="form">
                                    <div class="row no-gutters">
                                        <div class="col-5">現金付款</div>
                                        <div class="col-7">
                                            <BaseCalcSelectInput
                                                selectDate=""
                                                inputClass="custom-radius"
                                                placeholder="請輸入"
                                                selectName="calc_select"
                                                inputName="calc_input"
                                            />
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-5">剩餘款項</div>
                                        <div class="col-7">
                                            <div class="row no-gutters">
                                                <div class="col-6 px-0 pay-type">
                                                    信用卡
                                                </div>
                                                <div class="col-6 px-0 text-end fz-20 last-color">
                                                    ${{
                                                        numberFormat(
                                                            coupon_count -
                                                            values.calc_input
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5"></div>
                                        <div class="col-7">
                                            <BaseInput
                                                rootClass="authorization-input"
                                                title="授權碼"
                                                placeholder="請輸入授權碼"
                                                type="text"
                                                class="form-control"
                                                name="authorization_code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu2-item">
                                <div class="form">
                                    <div 
                                        v-if="storeInvoice.is_two"
                                        class="row" 
                                    >
                                        <div class="col-5">選擇要開立發票的公司</div>
                                        <div class="col-7 radio-wrap">
                                            <template
                                                v-for="store_data in storeInvoice.invoice_list"
                                                :key="store_data.id"
                                            >
                                                <BaseRadio
                                                    type="radio"
                                                    name="invoice_type"
                                                    :label="store_data.uniform_numbers"
                                                    :value="store_data.id"
                                                    v-model="invoice_type"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-5 ps-0">
                                            <div class="input-group">
                                                <button
                                                    class="
                                                        btn
                                                        btn-block
                                                        btn-cancel
                                                    "
                                                    @click="
                                                        closeChangeCouponLightbox
                                                    "
                                                >
                                                    取消
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-7 p-0">
                                            <div class="input-group">
                                                <button
                                                    class="
                                                        btn
                                                        btn-block
                                                        btn-checkout
                                                    "
                                                    :disabled="isSubmitting"
                                                >
                                                    <span>總計</span>
                                                    <div class="price">
                                                        <span>NT$</span>
                                                        <strong>{{
                                                            numberFormat(
                                                                coupon_count -
                                                                values.calc_input
                                                            )
                                                        }}</strong>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { useForm, useFieldArray, useSubmitForm } from "vee-validate";
import * as yup from "yup";
import { basePost } from "@/js/services/baseService";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { flashMessage } from "@/js/swal";
import { numberFormat } from "@/js/functions";

export default {
    name: "ChangeCouponLightbox",
    setup() {
        const { state } = useStore();
        const storeId = computed(() => state.auth.store_id);
        const isShow = computed(() => state.global.isChangeCouponLightboxShow);
        
        const ticketOrMerchandise = ref(0);

        // 全部票券加起來的價格
        let coupon_count = ref(0);

        let formData = reactive({
            input_coupon: "",
            phone: "",
            name: "",
        });

        // form驗證設定
        const validationSchema = yup.object({
            input_coupon: yup.string().required("必填"),
            ticket_change_id: yup.number().when("ticket_or_merchandise", (ticket_or_merchandise, schema) => {
                if (ticket_or_merchandise === "ticket") {
                    return schema.min(1, "必填")
                }
                return schema;
            }),
            merchandise_change_id: yup.number().when("ticket_or_merchandise", (ticket_or_merchandise, schema) => {
                if (ticket_or_merchandise === "merchandise") {
                    return schema.min(1, "必填")
                }
                return schema;
            }),
            calc_input: yup.string().required("必填"),
            ticket: yup.array().when("ticket_or_merchandise", (ticket_or_merchandise, schema) => {
                if (ticket_or_merchandise === "ticket") {
                    return schema.of(
                        yup.object({
                            ticket_number: yup.string().required("必填")
                        })
                    ).strict()
                }
                return schema;
            }),
            authorization_code: yup.string().when("calc_input", (calc_input, schema) => {
                if (coupon_count.value - Number(calc_input) > 0) {
                    return schema.required("必填");
                }
                return schema;
            })
        });

        const { closeChangeCouponLightbox } = useHandleLightboxOpen();
        const { values, isSubmitting, setValues, resetForm } = useForm({
            validateOnMount: false,
            validationSchema,
            formData,
            initialValues: {
                calc_input: 0,
                ticket_or_merchandise: 'ticket',
                ticket_change_id: 0,
                merchandise_change_id: 0,
                // invoice_type: 1
            },
        });

        const {  fields: fieldsTicket, replace: replaceTicket  } = useFieldArray("ticket");
        const {  fields: fieldsMerchandise, replace: replaceMerchandise } = useFieldArray("merchandise");
        replaceTicket([]);
        replaceMerchandise([]);


        const toSubmit = useSubmitForm(async (values, actions) => {
            

            const ticket = values.ticket.map(item => {
                return {
                    ticket_id: item.id,
                    ticket_number: item.ticket_number,
                    type: item.type
                }
            });
            const merchandise  = values.merchandise.map(item => {
                return {
                    merchandise_id: item.id,
                    num:item.num
                }
            });
            const ticketChangeId = values.ticket_or_merchandise === 'ticket' 
                ? values.ticket_change_id : values.merchandise_change_id


            let res = await basePost("/api_ticket/changer_tickets_check", {
                store_id: storeId.value,
                customer_phone: values.phone,
                name: values.name,
                original_ticket_number: values.input_coupon,
                ticket_change_id: ticketChangeId,
                ticket: JSON.stringify(ticket),
                merchandise: JSON.stringify(merchandise),
                difference: coupon_count.value,
                payment_method: values.pay_type || 'credit',
                cash_payment: values.calc_input,
                authorization_code: values.authorization_code,
                invoice_type: values.invoice_type
            });

            const { data, status } = res;
            if (status == 200 && data.res_code == 1) {
                flashMessage("換券成功");
                closeChangeCouponLightbox();
                resetForm();
            } else {
                flashMessage(data.res_content, "error");
            }
        });

        watch(isShow, () => {
            if (isShow.value) return;
            resetForm();
            ticketInfo.change_ticket_list = [];
            ticketInfo.change_merchandise_list = [];
            ticketInfo.payment_method = [];
            replaceTicket([]);
            replaceMerchandise([]);
        });
        const checkTicket = async () => {
            const res = await basePost("/api_ticket/changer_tickets_customer", {
                ticket_number: values.input_coupon,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                values.phone = data.res_data.phone;
                values.name = data.res_data.name;
                values.select_coupon = data.res_data.ticket_name;
                checkTicketInfo();
            } else {
                flashMessage(data.res_content, "error");
                values.phone = '';
                values.name = '';
                values.select_coupon = '';
                Object.assign(ticketInfo, initTicketInfo())
                replaceTicket([]);
                replaceMerchandise([]);
            }
        };
        
        const initTicketInfo = () => {
            return {
                change_ticket_list: [],
                change_merchandise_list: [],
                payment_method: []
            }
        }
        // 更換的票券
        const ticketInfo = reactive(initTicketInfo());

        const checkTicketInfo = async () => {
            const res = await basePost("/api_ticket/changer_tickets", {
                store_id: storeId.value,
                ticket_number: values.input_coupon,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                ticketInfo.change_ticket_list = data.res_data.change_ticket_list
                ticketInfo.change_merchandise_list = data.res_data.change_merchandise_list
                ticketInfo.payment_method = data.res_data.payment_method
            } else {
                flashMessage(data.res_content, "error");
            }
        };


        watch(() => values.ticket_change_id, (id) =>{
            const chooseTicket = ticketInfo.change_ticket_list.find(item =>{
                return item.id === id
            })
            if(!chooseTicket) return;
            replaceTicket([...chooseTicket.detail_list]);
        })

        watch(() => values.merchandise_change_id, (id) =>{
            const chooseMerchandise = ticketInfo.change_merchandise_list.find(item =>{
                return item.id === id
            })
            if(!chooseMerchandise) return;
            replaceMerchandise([...chooseMerchandise.detail_list]);
        })


        // const keyTicket = async (item, idx) => {
        //     const res = await basePost("/api_ticket/useTicketVerify", {
        //         store_id: storeId.value,
        //         amount: 1,
        //         start_num: item.ticket_number,
        //     });
        //     const { status, data } = res;
        //     if (status === 200 && data.res_code === 1) {
        //         item.ticket_id = data.res_data.id;
        //         values["coupon_type_" + idx] = data.res_data.name;
        //     } else {
        //         flashMessage(data.res_content, "error");
        //     }
        // };


        watch([
                () => values.calc_input,
                () => values.ticket_or_merchandise,
                () => values.ticket_change_id,
                () => values.merchandise_change_id,
            ],
            () => {
                countResult();
            }
        );
        const countResult = async () => {
            const ticket_or_merchandise = values.ticket_or_merchandise;
            let total_amount = 0
            if(ticket_or_merchandise === 'ticket' && values.ticket_change_id) {
                const chooseTicket = ticketInfo.change_ticket_list.find(item => {
                    return item.id === values.ticket_change_id
                })
                total_amount += Number(chooseTicket.difference);
            }
            if(ticket_or_merchandise === 'merchandise' && values.merchandise_change_id) {
                const chooseMerchandise = ticketInfo.change_merchandise_list.find(item => {
                    return item.id === values.merchandise_change_id
                })
                total_amount += Number(chooseMerchandise.difference);
            }
            coupon_count.value = total_amount;
        };


        const storeInvoice = reactive({
            is_two: 0,
            invoice_list: []
        });

        const defaultValue = ref('');

        //取得下拉選項
        const getStoreInvoiceList = async () => {
            try {
                const res = await basePost('/api_common/store_invoice_list', {
                    store_id: storeId.value,
                });
                const { status, data } = res;
                if(status === 200 && data.res_code === 1) {
                    storeInvoice.is_two = data.res_data.is_two;
                    storeInvoice.invoice_list = data.res_data.invoice_list;

                    if (storeInvoice.invoice_list && storeInvoice.invoice_list.length > 0) {
                        setValues({ 
                            ...values, invoice_type: storeInvoice.invoice_list[0].id 
                        });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        };

        watch(isShow, () => {
            if (isShow.value) {
                getStoreInvoiceList();
            }
        });
        
        return {
            isShow,
            closeChangeCouponLightbox,
            values,
            toSubmit,
            isSubmitting,
            checkTicket,
            // keyTicket,
            ticketOrMerchandise,
            coupon_count,
            ticketInfo,
            fieldsTicket,
            fieldsMerchandise,
            numberFormat,
            storeInvoice
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 640px;
$tab-width: 120px;
$tab-menu-width: 895px;
.change-coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #fff 100%) 0% 0%
        no-repeat padding-box;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            height: 100%;
            background: #e1e1e1;
            @include phone {
                box-shadow: unset;
            }
        }
        .col-5 {
            flex: 0 0 48%;
            max-width: 48%;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        .col-7 {
            flex: 0 0 52%;
            max-width: 52%;
            position: relative;
            padding-right: 0;
            @include phone {
                flex: 0 0 100%;
                max-width: 100%;
                padding: 0;
            }
        }
        .side-menu-top {
            background: #e1e1e1;
            .side-menu-item {
                padding-top: 45px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                    padding-top: 35px;
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 15px;
                            position: relative;
                            font-weight: bold;
                            &:after {
                                content: "";
                                position: absolute;
                                border-right: 1px solid #eee;
                                top: 10px;
                                bottom: 10px;
                                right: 0;
                                z-index: 1;
                            }
                            .select-coupon {
                                border-radius: 11px 0px 0px 11px;
                                padding-right: 30px;
                                height: 100%;
                                color: #0f2a68;
                                background: #f9f9f9
                                    url("~@/assets/tgd/lightbox/box1-arrow1.svg")
                                    no-repeat 90%;
                            }
                        }
                        .coupon-text {
                            color: #0f2a68;
                        }
                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 101%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .merchandise-info {
                        padding: 15px 0 8px;
                        .num-color {
                            font: normal normal 600 16px Open Sans;
                            color: #7D7D7D;
                        }
                        &:not(:last-child) {
                            border-bottom: 1px dashed rgba(112, 112, 112, .2);
                        }
                    }
                }
            }
        }
        .side-menu-bottom {
            background: #e1e1e1;
            .side-menu2-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #ccc;
                @include phone {
                    padding: 20px;
                }
                label {
                    margin-bottom: 10px !important;
                    margin-right: 10px !important;
                    input[type="radio"] {
                        display: none;
                    }
                    input[type="radio"] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/radio-none.svg")
                            no-repeat;
                        user-select: none;
                    }
                    input[type="radio"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                            no-repeat;
                        color: #a4641a;
                    }
                    span {
                        font-size: 16px;
                    }
                }
                .sum {
                    letter-spacing: 0px;
                    color: #000000;
                    .col-5 {
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                    }
                    .col-7 {
                        font: normal normal bold 20px/27px Open Sans;
                        text-align: right;
                    }
                }
                .col-5 {
                    flex: 0 0 48%;
                    max-width: 48%;
                    @include phone {
                        flex: 0 0 40% !important;
                        max-width: 40% !important;
                        padding-right: 5px;
                    }
                }
                .col-7 {
                    flex: 0 0 52%;
                    max-width: 52%;
                    @include phone {
                        flex: 0 0 60% !important;
                        max-width: 60% !important;
                        padding-left: 5px !important;
                    }
                }
                .col-4 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .col-8 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .form {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    .row {
                        align-items: center;
                        padding-bottom: 10px;
                        .last-color {
                            letter-spacing: 0px;
                            color: #c80036;
                            font: normal normal bold 18px/27px Open Sans;
                        }
                        .fz-20 {
                            font-size: 20px;
                        }
                        .input-group {
                            border-radius: 11px;
                            height: 70px;
                            .input-group-text {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 15px;
                                font-weight: bold;
                                width: 100%;
                                &:after {
                                    display: none;
                                }
                            }
                            .money-text {
                                color: #a4641a;
                            }
                            .select-icon {
                                font-weight: blod;
                                color: #a4641a;
                                font-size: 30px;
                                text-align: center;
                            }

                            .form-control {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 0px 11px 11px 0px;
                                padding: 15px;
                                font: normal normal 600 20px/22px Open Sans;
                                text-align: right;
                            }
                            .custom-rounded {
                                border-radius: 11px;
                            }
                            .btn {
                                height: 70px;
                                border-radius: 11px;
                                padding: 15px;
                                box-shadow: 0px 3px 6px #00000029;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font: normal normal bold 18px/24px Microsoft
                                    JhengHei;
                                letter-spacing: 0px;
                                @include phone {
                                    height: 60px;
                                    font-size: 16px;
                                }
                                span {
                                    position: relative;
                                    top: 2px;
                                }
                                .price {
                                    display: flex;
                                    align-items: center;
                                    font: normal normal 600 16px/22px Open Sans;
                                    strong {
                                        font: normal normal bold 29px/39px Open
                                            Sans;
                                        letter-spacing: 0px;
                                        padding-left: 20px;
                                        @include phone {
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }
                            .btn-cancel {
                                background: #a2a2a2 0% 0% no-repeat padding-box;
                                justify-content: center;
                            }
                            .btn-checkout {
                                background: #4a4a4a 0% 0% no-repeat padding-box;
                            }
                        }
                    }
                }
                .pay-type {
                    height: 43px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                :deep(.authorization-input) {
                    margin-bottom: 0 !important;
                    box-shadow: none !important;
                }
            }
        }
        .btn-wrap {
            position: absolute;
            bottom: 50px;
            padding-right: 72px;
            padding-left: 45px;
            @include phone {
                position: relative;
                padding: 20px;
                bottom: 0;
            }
            .col-md-5 {
                @include phone {
                    flex: 0 0 40% !important;
                    max-width: 40% !important;
                    padding-right: 5px;
                }
            }
            .col-md-7 {
                @include phone {
                    flex: 0 0 60% !important;
                    max-width: 60% !important;
                    padding-left: 5px;
                }
            }
            .btn-block {
                width: 100%;
                height: 70px;
                border-radius: 11px;
                padding: 15px;
                box-shadow: 0px 3px 6px #00000029;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font: normal normal bold 18px/24px Microsoft JhengHei;
                letter-spacing: 0px;
                @include hover(140%);
            }
            .btn-cancel {
                background: #a2a2a2 0% 0% no-repeat padding-box;
                justify-content: center;
                @include phone {
                    height: 60px;
                    font-size: 16px;
                }
            }
            .btn-checkout {
                background: #484848 0% 0% no-repeat padding-box;
                justify-content: center;
                @include phone {
                    height: 60px;
                    font-size: 16px;
                }
            }
        }
    }
}

.side-menu-open,
.tab-menu1-open,
.tab-menu2-open {
    opacity: 0;
    z-index: -1;
    position: absolute;
}
</style>
