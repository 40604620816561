<template>
    <div class="position-relative">
        <div
            class="input-group"
            :class="[
                { 'select-add': dataValue === '+' },
                { 'select-reduce': dataValue === '-' },
            ]"
        >
            <input
                type="text"
                class="input-blur"
                ref="inputRef"
                @blur="onInputBlur"
            />
            <div class="input-group-prepend" :style="selectStyle">
                <div class="input-group-text p-0">
                    <select
                        :name="selectName"
                        @change="handleChangeSelect"
                        :value="selectedVal"
                        v-if="selectDate"
                    >
                        <option
                            v-for="option in options"
                            :key="option.id"
                            :value="option.id"
                        >
                            {{ option.name }}
                        </option>
                    </select>

                    <div
                        class="aselect"
                        :data-value="dataValue"
                        :data-list="options"
                        v-if="selectDate"
                        @click="toggle()"
                    >
                        <div class="selector">
                            <div class="label">
                                <span>{{
                                    dataValue ? dataValue : "請選擇"
                                }}</span>
                            </div>
                        </div>
                    </div>
                    <div :class="{ hidden: !visible || disabled }">
                        <ul 
                            class="sty-scrollbar"
                            :class="{'filter-style': isFilterSearch}"
                        >
                            <li
                                v-if="isFilterSearch"
                                class="filter-search-box"
                            >
                                <input
                                    ref="searchInputRef"
                                    type="text"
                                    v-model="search"
                                    @blur="onSearchInputBlur"
                                />
                            </li>
                            <li v-if="search === ''" @click="select(0)">請選擇</li>
                            <li
                                :class="{ current: item.name === dataValue }"
                                v-for="item in filterOptions"
                                :key="item.id"
                                @click="select(item.name)"
                            >
                                {{ item.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <input
                :type="type"
                class="form-control"
                :class="inputClass"
                :name="inputName"
                @input="handleInputChange($event, type)"
                :placeholder="placeholder"
                :value="inputVal"
                :readonly="readonly"
                :disabled="disabled || disableInput"
            />
        </div>
        <div class="error-msg" v-show="inputErrorMessage">
            {{ inputErrorMessage }}
        </div>
    </div>
</template>

<script>
import { useField } from "vee-validate";
import { computed, ref, watch } from 'vue';

export default {
    name: "BaseCalcSelectInput",
    props: {
        selectDate: {
            type: String,
            default: "",
        },
        selectClass: {
            type: String,
            default: "",
        },
        selectStyle: {
            type: String,
            default: "",
        },
        inputClass: {
            type: String,
            default: "",
        },
        selectedValue: {
            type: [String, Number],
            default: "",
        },
        inputValue: {
            type: [String, Number],
            default: "",
        },
        selectName: {
            type: String,
            required: true,
        },
        inputName: {
            type: String,
            required: true,
        },
        options: Array,
        placeholder: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "text",
        },
        readonly: Boolean,
        disabled: {
            type: Boolean,
            default: false,
        },
        disableInput: {
            type: Boolean,
            default: false,
        },
        isFilterSearch: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {
        const { selectName, inputName, selectedValue, inputValue, options, isFilterSearch} =
            props;

        let visible = ref(false);
        let dataValue = ref();
        const inputRef = ref();

        const toggle = () => {
            visible.value = !visible.value;

            if (visible.value) {
                inputRef.value.focus();
            }
        };

        const select = (option) => {
            dataValue.value = option;
            visible.value = false;
        };

        const onInputBlur = () => {
            setTimeout(() => {
                if(isFilterSearch){
                    if(searchInputRef.value === document.activeElement){
                        return;
                    }
                }
                visible.value = false;
            }, 200);
        };

        // validate
        const {
            errorMessage: selectErrorMessage,
            value: selectedVal,
            handleChange: handleChangeSelect,
        } = useField(selectName, undefined, {
            initialValue: selectedValue,
        });

        const {
            errorMessage: inputErrorMessage,
            value: inputVal,
            handleChange: handleChangeInput,
        } = useField(inputName, undefined, {
            initialValue: inputValue,
        });
        const handleInputChange = (e, type) => {
            if (type === "number") {
                const filteredVal = e.target.value.replace(/[^0-9]/g,'');

                e.target.value = filteredVal;
                handleChangeInput(filteredVal);
            } else {
                handleChangeInput(e.target.value);
            }
        };

        if (options) {
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === selectedVal.value) {
                    dataValue.value = options[i].name;
                    break;
                }
            }
        }

        watch(dataValue, (val) => {
            let id;

            for (let i = 0; i < options.length; i++) {
                if (options[i].name === val) {
                    id = options[i].id;
                    break;
                }
            }

            handleChangeSelect(id);
        });

        watch(selectedVal, (val) => {
            let hasDataValue = false;
            for (let i = 0; i < options.length; i++) {
                if (options[i].id === val) {
                    dataValue.value = options[i].name;
                    hasDataValue = true;
                    break;
                }
            }
            if(!hasDataValue) {
                dataValue.value = 0;
            }
        });

        const searchInputRef = ref();
        const search = ref('');
        const filterOptions = computed(() => {
            if(search.value === '') return options;
            return options.filter(item => item.name.includes(search.value))
        })

        const onSearchInputBlur = () => {
            setTimeout(() => {
                visible.value = false;
                search.value = '';
            }, 200);
        };

        return {
            selectErrorMessage,
            inputErrorMessage,
            selectedVal,
            inputVal,
            handleChangeSelect,
            handleInputChange,
            visible,
            dataValue,
            toggle,
            select,
            onInputBlur,
            inputRef,
            search,
            filterOptions,
            onSearchInputBlur,
            searchInputRef
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/grid";
@import "~@/css/mixins";
.form {
    font: normal normal bold 18px/24px Microsoft JhengHei;
    letter-spacing: 0px;
    color: #000000;
    .row {
        align-items: center;
        padding-bottom: 10px;
        .last-color {
            color: #d94343;
            font: normal normal 600 20px/22px Open Sans;
        }
        .fz-20 {
            font-size: 20px;
        }
        .select-add {
            .label {
                font-weight: bold !important;
                color: #a4641a !important;
                font-size: 28px !important;
            }
            .form-control {
                color: #a4641a;
            }
        }
        .select-reduce {
            .label {
                font-weight: bold !important;
                color: #d94343 !important;
                font-size: 30px !important;
                span {
                    position: relative;
                    top: -2px;
                }
            }
            .form-control {
                color: #d94343;
            }
        }
        .input-group {
            box-shadow: unset !important;
            border-radius: 11px;
            height: 53px;
            .input-group-text {
                background: #f6f6f6;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 13px;
                font-weight: bold;
                width: 100%;
                &:after {
                    display: none;
                }
            }
            .money-text {
                color: #a4641a;
            }

            .form-control {
                background: #f6f6f6;
                border: none;
                border-radius: 0px 11px 11px 0px;
                padding: 15px;
                height: 53px;
                font: normal normal 600 20px/22px Open Sans;
                text-align: right;
                @include phone {
                    font-size: 18px !important;
                    padding: 12px !important;
                }

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                    -moz-appearance: textfield;
                }
            }
            .custom-radius {
                border-radius: 11px !important;
            }
            select {
                flex: 1;
                background: #f6f6f6 url("~@/assets/tgd/lightbox/box2-arrow.svg")
                    no-repeat 95%;
                border: none;
                border-radius: 11px 0px 0px 11px;
                padding: 15px;
                padding-right: 30px;
                text-align: center;
                font: normal normal 600 16px/22px Open Sans;
                appearance: none;
            }
        }
        .aselect {
            width: 100%;
            flex: 1;
            background: #f6f6f6 url("~@/assets/tgd/lightbox/box2-arrow.svg")
                no-repeat 95%;
            border: none;
            border-radius: 11px 0px 0px 11px;
            padding: 15px;
            padding-right: 30px;
            text-align: center;
            font: normal normal 600 16px/22px Open Sans;
            appearance: none;

            .selector {
                overflow: hidden;
                .expanded {
                    transform: rotateZ(180deg) translateY(2px);
                }
                .label {
                    display: block;
                    font: normal normal bold 16px/22px Open Sans;
                    letter-spacing: 0px;
                    color: #000000;
                    white-space: nowrap;
                }
            }
        }
        ul {
            width: 100%;
            list-style-type: none;
            position: absolute;
            top: 120%;
            left: 0;
            z-index: 1;
            padding: 11px;
            background: #191919 0% 0% no-repeat padding-box;
            box-shadow: 0px 10px 8px #00000054;
            border: 1px solid #ffffff;
            border-radius: 5px;
            opacity: 0.95;
            overflow-x: hidden;
        }
        li {
            font: normal normal 600 16px/22px Open Sans;
            letter-spacing: 0px;
            color: #ffffff;
            padding: 9px 24px;
            overflow: hidden;
            border-radius: 5px;
            white-space: nowrap;
            &:hover {
                background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
            }
            &:nth-child(1) {
                &:hover {
                    background: unset;
                }
            }
            .current {
                background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
            }
            &.filter-search-box {
                padding: 0;
                input {
                    padding: 5px;
                    width: 100%;
                }
            }
        }
        .filter-style {
            li:not(.filter-search-box) {
                text-align: left;
                padding: 9px 15px;
                white-space: normal;
            }
        }

        .hidden {
            visibility: hidden;
        }
        .visible {
            visibility: visible;
            li {
                cursor: pointer;
            }
        }
        .input-blur,
        select {
            opacity: 0;
            position: absolute;
            pointer-events: none;
        }
    }
}
</style>
