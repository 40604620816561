export default {
  "head": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head"])},
  "chest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chest"])},
  "stomach": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stomach"])},
  "frontThigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Thigh"])},
  "upperArm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper Arm"])},
  "lowerArm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower Arm"])},
  "neck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neck"])},
  "shoulder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shoulder"])},
  "upperBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upper Back"])},
  "middleBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle Back"])},
  "waist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waist"])},
  "rearThigh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rear Thigh"])},
  "rearCalf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rear Calf"])},
  "lightly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lightly"])},
  "harder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Harder"])},
  "frontBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front Body"])},
  "backBody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Body"])}
}