import { basePost } from "@/js/services/baseService";
export default {
    namespaced: true,
    state: {
        // 芳療師基本資訊
        aromatherapistInfo: []
    },
    getters: {},
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
            localStorage.setItem("memberData", JSON.stringify(state[key]));
        },
    },
    actions: {
        // 取得芳療師基本資訊
        async getMemberInfo({ commit, state, dispatch }, params) {
            try {
                const { store_id, member_id } = params;
                const res = await basePost("/api_reserve/member_list", {
                    store_id: store_id,
                    member_id: member_id
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "aromatherapistInfo",
                        value: data.res_data,
                    })
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
