<template>
    <div class="customer">
        <RecordTicketLightbox 
            v-model:opened="isRecordTicketOpened" 
            :submitType="submitType"
            :name="customerName"
            :phone="customerPhone"
            @update="updateList"
        />
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>
                > 票劵紀錄
            </div>
        </div>
        <div class="wrap">
            <div class="input-wrap">
                <BaseSelect
                    style="border-radius: 11px !important"
                    rootClass="select-group mb-3 me-3"
                    :isHasOptionAll="true"
                    @selectChange="handleSelectChange"
                    :options="[
                        {
                            id: '0',
                            name: '全部',
                        },
                        {
                            id: '1',
                            name: '尚有剩餘',
                        },
                        {
                            id: '2',
                            name: '使用完畢',
                        },
                    ]"
                />
                <div class="input-group search-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="輸入搜尋關鍵字"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        v-model.trim="keyword"
                        @keyup.enter="getTicketRecordList(1)"
                    />
                    <div class="input-group-append">
                        <button
                            class="btn"
                            type="button"
                            @click="getTicketRecordList(1)"
                        >
                            <img
                                src="@/assets/tgd/reserve/magnifier.svg"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
            <transition appear name="fade" v-if="isListRequest">
                <div class="row reserve-container">
                    <div class="warn-info">
                        ※垃圾桶為取消訂單，將取消該訂單所有票券的出售，將票券回歸為未售出狀態<br />
                        ※點擊退費將作廢該訂單所有票券
                    </div>
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="order-list-header">
                                <div class="row no-gutters">
                                    <div class="col-1">購買日期</div>
                                    <div class="col-1">購買店別</div>
                                    <div class="col-1">購買金額</div>
                                    <div class="col-1">已使用數量</div>
                                    <div class="col-1">剩餘數量</div>
                                    <div class="col-1">
                                        <button class="add-order" @click="addRecordTicket">
                                            新增訂單
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="order-list num-font"
                                v-for="ticketRecord in ticketRecordList.data"
                                :key="ticketRecord.order_id"
                                @click="goPage('CouponRecordDetail' ,ticketRecord.order_id)"
                            >
                                <div class="row no-gutters">
                                    <div class="col-1 pay-type-color">
                                        <img
                                            src="@/assets/tgd/customer/time.svg"
                                            alt=""
                                        />
                                        {{ ticketRecord.order_date }}
                                    </div>
                                    <div class="col-1">
                                        {{ ticketRecord.store_name }}
                                    </div>
                                    <div class="col-1 sum-color">
                                        $ {{ ticketRecord.amount }}
                                    </div>
                                    <div
                                        class="col-1 blue cursor-pointer"
                                        @click.stop="goPage('UsageRecord' ,ticketRecord.order_id)"
                                    >
                                        {{ ticketRecord.useAmount }}
                                    </div>
                                    <div
                                        class="col-1"
                                        v-if="ticketRecord.remaining"
                                    >
                                        {{ ticketRecord.remaining }}
                                    </div>
                                    <div class="col-1 last-color" v-else>
                                        <span>{{
                                            ticketRecord.remaining
                                        }}</span>
                                        <small>{{
                                            ticketRecord.refund_date
                                        }}</small>
                                    </div>
                                    <div
                                        class="col-1"
                                        
                                    >
                                        <button 
                                            v-if="ticketRecord.is_cancel == 1"
                                            class="btn-order" 
                                            @click.stop="confirmCancelOrderTicket(ticketRecord.order_id)" 
                                        >
                                            <img
                                                src="@/assets/tgd/customer/delete.svg"
                                                alt=""
                                            />
                                        </button>
                                        <!-- PM 隱藏「退費」按鈕 -->
                                        <!-- <div
                                            v-if="ticketRecord.is_show_refund == 1"
                                            class="btn-cancel"
                                            @click.stop="
                                                getOrderId(
                                                    ticketRecord.order_id
                                                )
                                            "
                                        >
                                            <img
                                                src="@/assets/tgd/customer/refund.svg"
                                                alt=""
                                            />
                                            退費
                                        </div> -->
                                        <div 
                                            class="d-flex align-items-center"
                                            v-if="
                                                ticketRecord.orderStatus == '已退費' ||
                                                ticketRecord.orderStatus == '已取消'
                                            "
                                        >
                                            <img
                                                src="@/assets/tgd/customer/refunded.svg"
                                                alt=""
                                            />
                                            {{ ticketRecord.orderStatus }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <NoData
                                :isFixed="false"
                                v-show="!ticketRecordList.data.length"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="row">
            <div class="col-12" v-if="pageInfo.totalPage > 1">
                <BasePagination
                    :pageInfo="pageInfo"
                    @pageChange="updatePageData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";
import RecordTicketLightbox from '@/components/lightbox/RecordTicketLightbox.vue';

import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
    name: "Coupon",
    components: {
        Logo,
        NoData,
        RecordTicketLightbox
    },
    setup() {
        const router = useRouter();
        const { state, commit } = useStore();
        const isListRequest = ref(false);

        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });

        // 票劵列表
        let ticketRecordList = reactive({ data: [] });
        // 使用狀態選項
        const selectedUseStatus = ref("0");
        // 關鍵字搜尋
        const keyword = ref("");
        // 訂單 ID
        const orderId = ref("");

        // 客戶 ID
        const customerId = JSON.parse(localStorage.getItem("customerId"));
        // 客戶姓名
        const customerName = JSON.parse(localStorage.getItem('customerName'));
        // 客戶電話
        const customerPhone = JSON.parse(localStorage.getItem('customerPhone'));


        // 取得票劵紀錄列表
        const getTicketRecordList = async (num = 1) => {
            try {
                const res = await basePost(
                    "/api_customer/consumption_ticket_log",
                    {
                        customer_id: customerId,
                        store_id: state.auth.store_id,
                        page: num,
                        keyword: keyword.value,
                        type: selectedUseStatus.value,
                    }
                );

                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    ticketRecordList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        const handleSelectChange = (selectVal) => {
            selectedUseStatus.value = selectVal;
            getTicketRecordList();
        };

        const updatePageData = (num) => {
            getTicketRecordList(num);
        };
        getTicketRecordList();

        // 退費票劵
        const refundTicket = async () => {
            try {
                const res = await basePost(
                    "/api_customer/ticket_refund_check",
                    {
                        order_id: orderId.value,
                    }
                );
                const { status, data } = res;
                Swal.fire({
                        title: "退費成功!",
                        icon: "success",
                        confirmButtonText: "確定",
                    }).then(() => {
                        getTicketRecordList();
                    });
            } catch (err) {
                console.error(err);
            }
        };

        // 傳訂單ID請求退費api
        const getOrderId = (id) => {
            orderId.value = id;
            Swal.fire({
                title: "是否確定退費?",
                showCancelButton: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
            }).then(function (result) {
                if (result.value) {
                    refundTicket();
                }
            });
        };

        function goPage(pageName, order_id){
            passId(order_id);
            router.push({ name: pageName })
        }
        // 傳訂單ID到使用紀錄頁
        const passId = (order_id) => {
            commit("passIdToNextPage/update", { key: "orderId", value: order_id })
            localStorage.setItem("orderId", JSON.stringify(order_id));
        }

        watch(ticketRecordList, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        // 訂單 ID
        const isRecordTicketOpened = ref(false);
        let submitType = ref(0);
        // 0新增

        const addRecordTicket = () => {
            isRecordTicketOpened.value = true;
        }

        const updateList = () => {
            getTicketRecordList()
        }


        // 退費票劵確認
        const confirmCancelOrderTicket = (id) => {
            Swal.fire({
                title: "是否確定取消訂單?",
                showCancelButton: true,
                confirmButtonText: "確定",
                cancelButtonText: "取消",
            }).then(function (result) {
                if (result.value) {
                    cancelOrderTicket(id);
                }
            });
        }
        // 退費票劵
        const cancelOrderTicket = async (id) => {
            try {
                const res = await basePost(
                    "/api_ticket/buy_cancel_del_order_ticket",
                    {
                        order_id: id,
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    Swal.fire({
                        title: "修改成功!",
                        icon: "success",
                        confirmButtonText: "確定",
                    }).then(() => {
                        getTicketRecordList();
                    });
                }
            } catch (err) {
                console.error(err);
            }
        };

        return {
            updatePageData,
            ticketRecordList,
            pageInfo,
            selectedUseStatus,
            getTicketRecordList,
            keyword,
            getOrderId,
            handleSelectChange,
            isListRequest,
            passId,
            goPage,
            customerName,
            customerPhone,
            isRecordTicketOpened,
            submitType,
            addRecordTicket,
            updateList,
            confirmCancelOrderTicket
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1425px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 1400px) {
            padding: 0;
        }
        .input-wrap {
            position: relative;
            top: -68px;
            right: -5px;
            display: flex;
            @include pad_air {
                top: 0;
                left: -10px;
            }
            @include phone {
                flex-direction: column;
            }
            .select-group {
                width: 225px;
                select {
                    flex: 1;
                    background: #f9f9f9
                        url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                        no-repeat 101%;
                    border: none;
                    border-radius: 11px;
                    padding: 15px;
                    font: normal normal 600 16px/22px Open Sans;
                    appearance: none;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(130%);
                    }
                    @include phone {
                        padding: 12px;
                    }
                }
            }
            .search-group {
                width: 350px;
                .form-control {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border: none;
                    border-radius: 11px 0px 0px 11px;
                    padding: 0 15px;
                    position: relative;
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    @include hover(90%);
                }
                .input-group-append {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border-radius: 0 11px 11px 0;
                    @include hover(130%);
                    .btn {
                        height: 100%;
                        padding: 0 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .input-group {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 11px;
                height: 53px;
                @include phone {
                    width: 100%;
                    height: 45px;
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @media (max-width: 1400px) {
                overflow: overlay;
                margin: 0;
            }
            .warn-info {
                color: #d94343;
                font-size: 20px;
                font-weight: bold;
                text-shadow: 0px 0px 1px #fff;
                margin-bottom: 10px;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #0f2a68;
                    @include pad_pro {
                        margin: 15px 0;
                        margin-top: 20px;
                    }
                }
                .pay-type-color {
                    color: #707070;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #007cff;
                }
                .last-color {
                    color: #a4641a;
                    flex-direction: column;
                    text-align: center;
                    small {
                        font-size: 14px;
                    }
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1425px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro_lg {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                    position: relative;
                    top: 60px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                        .col-1 {
                            width: 11.11111%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            img {
                                padding-right: 5px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                            &:last-child {
                                margin: 0 0 0 auto;
                            }
                        }
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 25px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                        .col-1 {
                            width: 11.11111%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            img {
                                padding-right: 5px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                            a {
                                text-decoration: underline !important;
                            }
                        }
                        > div {
                            &:last-child {
                                width: 20%;
                                margin: 0 0 0 auto;
                                align-items: center;
                                justify-content: flex-end;
                                padding-right: 10px;
                            }
                        }
                    }
                    &:hover {
                        filter: contrast(90%);
                    }

                    .btn-cancel {
                        background: #707070;
                        border-radius: 21px;
                        font: normal normal bold 14px/19px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #ffffff;
                        width: 92px;
                        padding: 8px 0;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        cursor: pointer;
                        @include hover(90%);
                        img {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }

    .btn-order {
        border: 0;
        padding: 0;
        background: none;
        margin: 0 8px;
        vertical-align: middle;
        img {
            display: block;
        }
    }
    .add-order {
        padding: 10px 25px;
        border-radius: 21px;
        background: #C80036;
        color: #fff;
        border: none;
        box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.16);
    }
}
:deep(.select-group) {
    max-width: 224.5px;
    margin-left: auto;
}
</style>
