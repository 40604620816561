export default {
    namespaced: true,
    state: {
        customerId: '',
        customerName: '',
        customerPhone: '',
        keyword: '',
        orderId: '',
        commodityId: '',
        order_date: ''
    },
    getters: {},
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
        },
    },
    actions: {},
};
