<template>
    <SideMenu />
    <CouponLightbox />
    <MerchandiseLightbox />
    <ChangeCouponLightbox />
    <div class="no-print-sec">
        <router-view></router-view>
    </div>
    <BaseTicket />
</template>


<script>
import { provide } from 'vue';
import SideMenu from '@/components/includes/SideMenu';
import CouponLightbox from '@/components/lightbox/CouponLightbox';
import ChangeCouponLightbox from '@/components/lightbox/ChangeCouponLightbox';
import MerchandiseLightbox from '@/components/lightbox/MerchandiseLightbox';
import useBarcodeScanner from '@/hooks/common/useBarcodeScanner';
import { useStore } from "vuex";
import { computed } from 'vue';

export default {
    name: 'App',
    components: {
        SideMenu,
        CouponLightbox,
        ChangeCouponLightbox,
        MerchandiseLightbox
    },
    setup() {
        const { isBarcodeScanner, resetBarcodeScanner } = useBarcodeScanner();
        provide('barcodeScanner', { isBarcodeScanner, resetBarcodeScanner });

        const store = useStore();
        const isCounterStaff = computed(() => store.state.auth.isCounterStaff);

        return {
            isCounterStaff,
        };
    },
};
</script>

<style lang="scss">
@import '~@/css/grid';
@import '~@/css/mixins';
#app {
    background-image: url('~@/assets/tgd/login/bg2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    background-attachment: fixed;
    min-height: 100vh;
    padding: 40px;
    position: relative;
    @media (max-width: 1400px) {
        padding: 40px 20px;
    }
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
                @include phone {
                    border-color: #a4641a;
                }
            }
            @include phone {
                margin-bottom: 20px;
                color: #a4641a;
                a {
                    color: #a4641a;
                }
            }
        }
    }
    .top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 70px;
        @media (max-width: 1200px) {
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
    }
}
</style>
