<template>
    <div ref="refRoot" >
        <div class="mask" v-show="isShow" @click="closeEditStockLightbox"></div>
        <transition name="slide"  @before-enter="beforeEnter">
            <div class="change-coupon-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <div class="side-menu">
                        <div
                            class="side-menu-close"
                            @click="closeEditStockLightbox"
                        >
                            <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item">
                                <div class="title mb-3">庫存變更：</div>
                                <ul class="stock-list-wrap">
                                    <li
                                        class="stock-list"
                                        v-for="(item, index) in stockList.data"
                                        :key="index"
                                    >
                                        <div class="row no-gutters">
                                            <div class="col-md-4">
                                                <img
                                                    src="@/assets/tgd/stock/ex-img@2x.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="col-md-4 pt-md-0 pt-3">
                                                {{ item.name }}
                                            </div>
                                            <div
                                                class="
                                                    col-md-4
                                                    justify-content-md-between
                                                "
                                            >
                                                <div class="counter">
                                                    <BaseCounter
                                                        class="stockBox-counter"
                                                        :maxAmount="9999"
                                                        :value="item.num"
                                                        v-model="item.num"
                                                        @click="
                                                            replaceCart(
                                                                item,
                                                                index
                                                            )
                                                        "
                                                    />
                                                </div>
                                                <a
                                                    href="javascript:;"
                                                    class="pe-2"
                                                    @click="
                                                        delCart(item, index)
                                                    "
                                                >
                                                    <img
                                                        class="garbage"
                                                        src="@/assets/tgd/stock/garbege.svg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="side-menu-bottom">
                            <div class="side-menu2-item">
                                <div class="title mb-3">選擇類型：</div>
                                <form class="row">
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="blue_in"
                                            name="stock"
                                            v-model="currentType"
                                            value="1"
                                        />
                                        <label
                                            for="blue_in"
                                            class="btn btn-block btn-blue"
                                        >
                                            <img
                                                src="@/assets/tgd/stock/blue_in.svg"
                                                alt=""
                                            />
                                            入庫
                                        </label>
                                    </div>
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="blue_bag_in"
                                            name="stock"
                                            v-model="currentType"
                                            value="2"
                                        />
                                        <label
                                            for="blue_bag_in"
                                            class="btn btn-block btn-blue"
                                        >
                                            <div class="fake-img"></div>
                                            調貨入庫
                                        </label>
                                    </div>
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="blue_box_in"
                                            name="stock"
                                            v-model="currentType"
                                            value="3"
                                        />
                                        <label
                                            for="blue_box_in"
                                            class="btn btn-block btn-blue"
                                        >
                                            <img
                                                src="@/assets/tgd/stock/blue_box_in.svg"
                                                alt=""
                                            />
                                            其他入庫
                                        </label>
                                    </div>
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="red_out"
                                            name="stock"
                                            v-model="currentType"
                                            value="4"
                                        />
                                        <label
                                            for="red_out"
                                            class="btn btn-block btn-red"
                                        >
                                            <img
                                                src="@/assets/tgd/stock/red_out.svg"
                                                alt=""
                                            />
                                            領用
                                        </label>
                                    </div>
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="red_bag_in"
                                            name="stock"
                                            v-model="currentType"
                                            value="5"
                                        />
                                        <label
                                            for="red_bag_in"
                                            class="btn btn-block btn-red"
                                        >
                                            <img
                                                src="@/assets/tgd/stock/red_bag_in.svg"
                                                alt=""
                                            />
                                            調貨出庫
                                        </label>
                                    </div>
                                    <div class="col-4 p-0 pb-2">
                                        <input
                                            type="radio"
                                            id="red_box_out"
                                            name="stock"
                                            v-model="currentType"
                                            value="6"
                                        />
                                        <label
                                            for="red_box_out"
                                            class="btn btn-block btn-red"
                                        >
                                            <img
                                                src="@/assets/tgd/stock/red_box_out.svg"
                                                alt=""
                                            />
                                            其他出庫
                                        </label>
                                    </div>
                                </form>
                                <div class="title mb-3 pt-5">備註：</div>
                                <form class="row no-gutters">
                                    <div class="col-12">
                                        <div class="textarea-wrap">
                                            <textarea
                                                v-model="remark"
                                            ></textarea>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="container btn-wrap">
                            <div class="row pt-3">
                                <div class="col-5">
                                    <div class="input-group">
                                        <button
                                            class="btn btn-block btn-cancel"
                                            @click="closeEditStockLightbox"
                                        >
                                            取消
                                        </button>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="input-group">
                                        <button
                                            class="btn btn-block btn-checkout"
                                            @click="useNow"
                                        >
                                            確認
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, onMounted, inject, computed, watch, reactive, nextTick } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { flashMessage, confirmMessage } from "@/js/swal";

export default {
    name: "EditStockLightbox",
    setup(props, {emit}) {
        const { state, commit, dispatch, getters } = useStore();

        const refRoot = ref(null);
        const isShow = computed(() => state.global.isEditStockLightboxShow);
        const { closeEditStockLightbox } = useHandleLightboxOpen();

        const storeId = computed(() => {
            return state.auth.store_id;
        })

        const stockList = reactive({ data: [] });
        let currentType = ref(1);
        let remark = ref("");

        watch(isShow, () => {
            if (isShow.value) {
                getStockList();
            }
        });

        function resetScroll(){
            nextTick(()=>{
                refRoot.value.querySelector('.side-menu-wrap').scrollTop = 0;
            })
        }

        function beforeEnter(){
            resetScroll()
        }

        // 取得庫存
        const getStockList = async () => {
            const ids = computed(()=> getters["stockCart/ids"]);
            const cartList = computed(()=> getters["stockCart/list"]);

            const res = await basePost("/api_commodity/commodity_list", {
                store_id: storeId.value,
                ids: ids.value,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                const { res_data } = data;
                stockList.data = res_data.list;

                stockList.data.map(function (value, key) {
                    stockList.data[key].num = cartList.value.data[value.id];
                });
            }
        };
        const delCart = (item, index) => {
            confirmMessage('確定刪除項目?', 'question', ()=>{
                stockList.data.splice(index, 1);
                dispatch("stockCart/delete", item.id);
            })
        };

        const replaceCart = (item, index) => {
            dispatch("stockCart/add", item);
            if (item.num == 0) {
                stockList.data.splice(index, 1);
            }
        };

        // 取得庫存
        const useNow = async () => {

            if ( stockList.data.length == 0 ) {
                flashMessage("尚未加入任何品項!", 'info');
                return 
            }

            try {
                const res = await basePost("/api_commodity/commodity_check", {
                    store_id: storeId.value,
                    type: currentType.value,
                    remark: remark.value,
                    commodity: JSON.stringify(
                        stockList.data.map(function (item, key) {
                            return {
                                commodity_id: item.id,
                                amount: item.num,
                            };
                        })
                    ),
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    flashMessage("成功");
                    dispatch("stockCart/empty");
                    closeEditStockLightbox();
                    emit('submited')
                } else {
                    flashMessage(data.res_content, "error");
                    getStockList();
                }
            } catch {
                console.error(err);
            }
        };

        

        return {
            refRoot,
            isShow,
            beforeEnter,
            closeEditStockLightbox,
            stockList,
            delCart,
            replaceCart,
            useNow,
            currentType,
            remark,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 640px;
$tab-width: 120px;
$tab-menu-width: 895px;
.change-coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #fff 100%) 0% 0%
        no-repeat padding-box;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            height: 100%;
            width: 100%;
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #fff 100%) 0%
                0% no-repeat padding-box;
            @include phone {
                box-shadow: unset;
            }
        }
        .side-menu-top {
            background: #e1e1e1;
            .side-menu-item {
                padding-top: 45px;
                padding-bottom: 45px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                    padding-top: 35px;
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .stock-list-wrap {
                    .stock-list {
                        padding: 15px;
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #ffffff 67%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #000000;
                        margin-bottom: 10px;
                        @include hover(90%);
                        .col-md-4 {
                            display: flex;
                            align-items: center;
                            @include phone {
                                justify-content: center;
                            }
                            .counter {
                                @include phone {
                                    padding-right: 20px;
                                }
                                span {
                                    font: normal normal bold 28px/30px Open Sans;
                                    letter-spacing: 0px;
                                    color: #a4641a;
                                    @include line(
                                        -15px,
                                        -15px,
                                        "",
                                        -20px,
                                        1px,
                                        #ddd,
                                        block
                                    );
                                    a {
                                        font: normal normal bold 30px/30px Open
                                            Sans;
                                        letter-spacing: 0px;
                                        color: #a4641a;
                                        padding: 0 5px;
                                    }
                                }
                            }
                            .pe-2 {
                                display: flex;
                                position: relative;
                                @include phone {
                                    padding-left: 20px;
                                }
                            }
                            .garbage {
                                width: 22px;
                                height: auto;
                            }
                        }
                        img {
                            width: 135px;
                            height: 81px;
                            border-radius: 7px;
                        }
                    }
                }
            }
        }
        .side-menu-bottom {
            background: #fcfcfc;
            .side-menu2-item {
                padding-top: 45px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                @include phone {
                    padding: 20px;
                    padding-top: 35px;
                }

                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .col-4 {
                    display: flex;
                    justify-content: center;
                    &:nth-child(2) {
                        label {
                            background: no-repeat
                                    url("~@/assets/tgd/stock/bag-in-blue.svg"),
                                #fff;
                            background-position: 17% 50%;
                            &:hover {
                                background: no-repeat
                                        url("~@/assets/tgd/stock/blue_bag_in.svg"),
                                    #0f2a68;
                                background-position: 17% 50%;
                            }
                            @include phone {
                                background-position: 50% 19%;
                            }
                            .fake-img {
                                width: 30px;
                                height: 32px;
                            }
                        }
                        input:checked + label {
                            background: no-repeat
                                    url("~@/assets/tgd/stock/blue_bag_in.svg"),
                                #0f2a68;
                            background-position: 17% 50%;
                            @include phone {
                                background-position: 50% 19%;
                            }
                        }
                    }
                    @include phone {
                        display: flex;
                        justify-content: center;
                    }

                    input[type="radio"] {
                        display: none;
                    }
                    input:checked + .btn-blue {
                        background: #0f2a68;
                        color: #fff;
                    }
                    input:checked + .btn-red {
                        background: #d94343;
                        color: #fff;
                    }
                    .btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 52px;
                        width: 165px;
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        letter-spacing: 0px;
                        border-radius: 7px;
                        transition: ease 0.3s;
                        img {
                            padding-right: 20px;
                            @include phone {
                                padding: 0;
                                padding-bottom: 2px;
                            }
                        }
                        @include phone {
                            width: 90%;
                            flex-direction: column;
                            height: unset;
                            padding: 5px 0;
                        }
                    }
                    .btn-blue {
                        border: 2px solid #0f2a68;
                        color: #0f2a68;
                        &:hover {
                            background: #0f2a68;
                            color: #fff;
                        }
                    }
                    .btn-red {
                        border: 2px solid #d94343;
                        color: #d94343;
                        &:hover {
                            background: #d94343;
                            color: #fff;
                        }
                    }
                }
                .textarea-wrap {
                    textarea {
                        width: 100%;
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        padding: 20px;
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        border-radius: 11px;
                        font: normal normal bold 20px/27px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #000000;
                        @include hover(90%);
                        &::-webkit-scrollbar-track {
                            background-color: #d6d6d6;
                            border-radius: 2px;
                        }
                        &::-webkit-scrollbar {
                            width: 7px;
                        }
                        &::-webkit-scrollbar-thumb {
                            background: #707070;
                            box-shadow: 0px 3px 6px #00000029;
                            border-radius: 9px;
                        }
                        @include phone {
                            padding: 15px;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        .btn-wrap {
            padding-right: 72px;
            padding-left: 45px;
            padding-bottom: 20px;
            @include phone {
                padding: 0 15px;
                padding-bottom: 20px;
                position: unset;
            }
            .col-5 {
                @include phone {
                    flex: 0 0 40%;
                    padding-right: 5px;
                }
            }
            .col-7 {
                @include phone {
                    flex: 0 0 60%;
                    padding-left: 5px;
                }
            }
            .btn-block {
                width: 100%;
                height: 70px;
                border-radius: 11px;
                padding: 15px;
                box-shadow: 0px 3px 6px #00000029;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font: normal normal bold 18px/24px Microsoft JhengHei;
                letter-spacing: 0px;
                @include hover(140%);
                @include phone {
                    height: 60px;
                }
            }
            .btn-cancel {
                background: #a2a2a2 0% 0% no-repeat padding-box;
                justify-content: center;
            }
            .btn-checkout {
                background: #484848 0% 0% no-repeat padding-box;
                justify-content: center;
            }
        }
    }
}

.side-menu-open,
.tab-menu1-open,
.tab-menu2-open {
    opacity: 0;
    z-index: -1;
    position: absolute;
}
</style>
