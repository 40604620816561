<template>
    <div id="fixed_order_info">
        <div class="block_wrap">
            <div class="blocks">
                <div class="title_">未排</div>   
                <div class="number">{{ notScheduled }}</div>
                <div class="tips_content" v-if="notScheduled !== 0">尚有{{ notScheduled }}筆<br>預約未排定芳療師</div>
            </div>  
            <div class="blocks">
                <div class="title_">今日取消</div>   
                <div class="number">{{ todayCancel }}</div>
                <div class="tips_content" v-if="todayCancel !== 0">尚有{{ todayCancel }}筆<br>取消預約未確認</div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, onMounted, onBeforeUnmount } from "vue";
import Swal from "sweetalert2";

export default {
    name: "FixedOrderInfo",
    props: {
        isCounterStaff: Boolean, 
    },
    setup(props) {
        
        
        const { state, dispatch } = useStore();
        const storeId = computed(() => state.auth.store_id);
        let reservationList = computed(() => state.reservation.list);

        // 尚未排芳療師的數量
        const notScheduled = computed(() => {
            if (!reservationList.value || reservationList.value.length === 0) return 0;

            return reservationList.value.filter(item =>
                item.member.some(member => member.member_name === '')
            ).length;
        });

        // 今日inline預約取消的數量
        const todayCancel = computed(() => {
            if (!reservationList.value || reservationList.value.length === 0) return 0;
            
            return reservationList.value.filter(item => 
                item.is_show_inline_cancel
            ).length;
        });
        
        let intervalId;

        onMounted(() => {
            
            dispatch("reservation/getReservationList", {
                store_id: storeId.value,
                id: 0,
            })

            intervalId = setInterval(() => {

                //如果未排不等於0
                if (notScheduled.value !== 0) {
                    Swal.fire({
                        title: `尚有${notScheduled.value}筆預約未排定芳療師!`,
                        icon: "warning",
                        confirmButtonText: "確定",
                    });
                }

                // 如果本日取消不等於0
                if(todayCancel.value !== 0){
                    
                    Swal.fire({
                        title: `尚有${todayCancel.value}筆取消預約未確認!`,
                        icon: "warning",
                        confirmButtonText: "確定",
                    });
                }
                
            }, 5 * 60 * 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(intervalId);
        });

        return {
            notScheduled,
            todayCancel
        }
    },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

#fixed_order_info{
    position: fixed;
    left: 0;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    background: #000;
    width: 100px;
    height: 300px;
    box-shadow: 0 34px 77px #00000042;
    border-radius: 0 15px 15px 0;
    background: transparent linear-gradient(162deg, #ffffff 0%, #D9D9D9 18%, #ffffff 100%) 0% 0% no-repeat padding-box;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    .block_wrap{
        .blocks{
            padding: 20px 0;
            text-align: center;
            position: relative;
            .title_{
                font-size: 16px;   
                font-weight: bold; 
                margin-bottom: 15px;
            }
            .number{
                font-size: 60px;
                font-weight: bold;
                color: #D94343;
            }
            &+ .blocks{
                border-top: 1px #fff solid;
                .number{
                    color: #A4641A;
                }
            }
            .tips_content{
                position: absolute;
                left: calc(100% + 5px);
                bottom: -20px;
                background: rgba(0,0,0,.8);
                border: 1px #707070 solid;
                width: 160px;
                padding: 13px;
                border-radius: 4px;
                display: none;
                text-align: left;
                color: #fff;
                line-height: 1.6;
            }
            &:hover{
                .tips_content{
                    display: block;
                }    
            }
        }
    }
}

</style>
