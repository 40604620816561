<template>
    <div class="input-group" :class="class">
        <input type="text" ref="inputRef" @blur="onInputBlur" />
        <div class="input-group-prepend" v-if="title">
            <span class="input-group-text">{{ title }}</span>
        </div>
        <select
            :style="style"
            @change="handleChangeSelect"
            :name="name"
            :value="selectedVal"
        >
            <option selected :value="0" disabled v-if="hasInitVal">
                請選擇
            </option>
            <option
                v-for="option in options"
                :key="option.id"
                :value="option.id"
            >
                {{ option.name }}
            </option>
        </select>

        <div
            class="aselect"
            :data-value="dataValue"
            :data-list="options"
            :style="style"
            :class="selectClass"
            @click="toggle()"
        >
            <div class="selector">
                <div class="label">
                    <span>{{ dataValue ? dataValue : "請選擇" }}</span>
                </div>
            </div>
        </div>
        <div :class="{ hidden: !visible, visible }">
            <ul>
                <li
                    v-for="(item, index) in options"
                    :key="index"
                    :class="{ current: item.name === dataValue }"
                    @click="select(item.name)"
                >
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
    <div class="error-msg" v-show="errorMessage">
        {{ errorMessage }}
    </div>
</template>

<script>
import { useField } from "vee-validate";
import { ref, watch, onMounted } from "vue";

export default {
    name: "BaseSelect_bak",
    emits: ["update:selectVal", "selectChange"],
    props: {
        title: {
            type: String,
            default: "",
        },
        rootClass: {
            type: String,
            default: "",
        },
        class: {
            type: String,
            default: "",
        },
        style: {
            type: Object,
            default: () => ({}),
        },
        name: {
            type: String,
            required: true,
        },
        options: Array,
        selectClass: {
            type: String,
            default: "",
        },

        hasInitVal: {
            type: Boolean,
            required: false,
        },
    },
    setup(props, context) {
        const { emit } = context;
        const { name, value, selectVal } = props;

        let visible = ref(false);
        let dataValue = ref();
        const inputRef = ref();

        const toggle = () => {
            visible.value = !visible.value;

            if (visible.value) {
                inputRef.value.focus();
            }
        };

        const select = (option) => {
            dataValue.value = option;
            visible.value = false;
        };

        const onInputBlur = () => {
            setTimeout(() => {
                visible.value = false;
            }, 200);
        };

        // validate
        const {
            errorMessage,
            name: thisName,
            value: selectedVal,
            handleChange,
            handleBlur,
        } = useField(name, undefined, {
            initialValue: value,
        });
        const update = (val) => {
            handleChange(val);
            emit("update:selectVal", val);
        };
        const handleChangeSelect = (e) => {
            const val = e.target.value;
            update(val);
        };

        const initVal = () => {
            if (props.options) {
                for (let i = 0; i < props.options.length; i++) {
                    if (props.options[i].id == selectedVal.value) {
                        dataValue.value = props.options[i].name;
                        break;
                    }
                }
            }
        };

        const handleSelector = () => {
            handleBlur();
            emit("selectChange", selectedVal);
            update(selectedVal.value);
        };

        watch(
            selectedVal,
            (val) => {
                initVal();
            },
            {
                immediate: true,
            }
        );

        watch(dataValue, (val) => {
            let id;

            if (props.options) {
                for (let i = 0; i < props.options.length; i++) {
                    if (props.options[i].name === val) {
                        id = props.options[i].id;
                        break;
                    }
                }
            }

            handleChange(id);
            handleSelector();
        });

        
        // 初始值
        if ( selectVal ) {
            update(selectVal)
        }

        onMounted(()=>{
            emit('init', {
                setValue: update
            })
        })

        return {
            errorMessage,
            selectedVal,
            handleChange,
            visible,
            dataValue,
            toggle,
            select,
            onInputBlur,
            inputRef,
            handleChangeSelect,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/grid";
@import "~@/css/mixins";
.list-input {
    width: 300px;
    position: absolute !important;
    right: -5px;
    top: -67px;
    @include phone {
        height: 45px;
        position: relative !important;
        top: 0;
        right: 10px;
    }
}
.select-group {
    width: 225px;
}


.input-group {
    border: 2px solid #fff;
    background: url("~@/assets/tgd/lightbox/select-arrow-box.svg") no-repeat
            100%,
        transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
    border-radius: 11px;
    transition: all 0.2s;
    height: 53px;
    margin-bottom: 15px;
    position: relative;
    &:hover {
        border: 2px solid #ef9b35;
    }
    .input-group-prepend {
        position: relative;
        left: 2px;
    }
    .input-group-text {
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        border: none;
        border-radius: 11px 0px 0px 11px;
        padding: 12px 15px;
        position: relative;
        font-weight: bold;

        &:after {
            content: "";
            position: absolute;
            border-right: 1px solid #eee;
            top: 10px;
            bottom: 10px;
            right: 0;
            z-index: 1;
        }
        .select-coupon {
            border-radius: 11px 0px 0px 11px;
            padding-right: 32px;
            height: 100%;
            color: #0f2a68;
            background: #f9f9f9 url("~@/assets/tgd/lightbox/box1-arrow1.svg")
                no-repeat 90%;
        }

        .select-money {
            border-radius: 11px 0px 0px 11px;
            padding-right: 32px;
            height: 100%;
            color: #a4641a;
            background: #f9f9f9 url("~@/assets/tgd/lightbox/box1-arrow2.svg")
                no-repeat 90%;
        }
    }
    .form-control {
        background: transparent
            linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        border: none;
        border-radius: 0px 11px 11px 0px;
        padding: 12px;
        font: normal normal 600 16px/22px Open Sans;
    }
    .custom-rounded {
        border-radius: 0px 11px 11px 0px;
    }
}
.aselect {
    flex: 1;
    border: none;
    padding: 11px 15px;
    font: normal normal 600 16px/22px Open Sans;
    border-radius: 11px !important;
    appearance: none;
    filter: contrast(100%);
    transition: all 0.3s;
    cursor: pointer;

    .selector {
        overflow: hidden;
        .expanded {
            transform: rotateZ(180deg) translateY(2px);
        }
        .label {
            display: block;
            font: normal normal bold 16px/22px Open Sans;
            letter-spacing: 0px;
            color: #000000;
            white-space: nowrap;
            span {
                position: relative;
                top: 2px;
            }
        }
    }
}
ul {
    width: 100%;
    list-style-type: none;
    position: absolute;
    top: 120%;
    left: 0;
    z-index: 1;
    padding: 11px;
    background: #191919 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 8px #00000054;
    border: 1px solid #ffffff;
    border-radius: 5px;
    opacity: 0.95;
    overflow: auto;
    max-height: 300px;
    &::-webkit-scrollbar-track {
            background-color: #191919;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #191919;
        }
}
li {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #ffffff;
    padding: 9px 24px;
    overflow: hidden;
    border-radius: 5px;
    white-space: nowrap;
    &:hover {
        background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    }
    .current {
        background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
    }
}

.hidden {
    visibility: hidden;
}
.visible {
    visibility: visible;
    li {
        cursor: pointer;
    }
}

input[type="text"],
select {
    opacity: 0;
    position: absolute;
    pointer-events: none;
}
</style>
