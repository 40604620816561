<template>
    <div class="news-wrap">
        <div class="qrcode_custom_name">
            <span>會員名稱</span>
            <input type="text" name="qrcode_custom_name" v-model="customerName" class="customerNameText">
        </div>
        <div class="news-header">
            <div class="news-title">
                <img src="@/assets/tgd/lightbox/small-massage.svg" alt="" />
                <span>需求設定</span>
            </div>
            
            <div class="lang-select-box" :class="langMenu ? 'show' : ''" @click="langMenuToogle">
                <div class="lang-select-btn">
                    <p>{{ localeText }}</p>
                    <div class="lang-select-arrow">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="9"
                            viewBox="0 0 17 9"
                        >
                            <path
                                id="Polygon_1"
                                data-name="Polygon 1"
                                d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z"
                                transform="translate(17 9) rotate(180)"
                                :fill="iconColor"
                            />
                        </svg>
                    </div>
                </div>
                <ul class="lang-select-list">
                    <li><a href="javascript:;" @click="$i18n.locale = 'en'" >English</a></li>
                    <li><a href="javascript:;" @click="$i18n.locale = 'zh-TW'">繁體中文</a></li>
                </ul>
            </div>
            
        </div>
        <div class="news-body" :class="locale==='en'?'en':'zh-TW'">
            <div class="row no-gutters">
                <div class="col-12 text-gold">{{ t("frontBody") }}</div>
                <div class="col-12">
                    <div class="news-body-left">
                        <div class="check-area container">
                            <div class="row no-gutters align-items-center">
                                <div class="col-6"></div>
                                <div class="col-3">{{ t("lightly") }}</div>
                                <div class="col-3">{{ t("harder") }}</div>
                            </div>
                            <div class="row no-gutters align-items-center pt-2">
                                <div class="col-6">{{ t("head") }}</div>
                                <div class="col-3">
                                    <input
                                        id="head-1"
                                        :checked="orderLocation.data[1].indexOf(1) >= 0"
                                        name="head"
                                        type="radio"
                                        value="1-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="head-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="head-2"
                                        :checked="orderLocation.data[1].indexOf(2) >= 0"
                                        name="head"
                                        type="radio"
                                        value="1-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="head-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 85px; visibility: hidden;">
                                <div class="col-6">{{ t("chest") }}</div>
                                <div class="col-3">
                                    <input
                                        id="chest-1"
                                        :checked="orderLocation.data[2].indexOf(1) >= 0"
                                        name="chest"
                                        type="radio"
                                        value="2-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="chest-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="chest-2"
                                        :checked="orderLocation.data[2].indexOf(2) >= 0"
                                        name="chest"
                                        type="radio"
                                        value="2-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="chest-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 75px;  visibility: hidden;">
                                <div class="col-6">{{ t("stomach") }}</div>
                                <div class="col-3">
                                    <input
                                        id="belly-1"
                                        :checked="orderLocation.data[3].indexOf(1) >= 0"
                                        name="belly"
                                        type="radio"
                                        value="3-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="belly-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="belly-2"
                                        :checked="orderLocation.data[3].indexOf(2) >= 0"
                                        name="belly"
                                        type="radio"
                                        value="3-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="belly-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 74px">
                                <div class="col-6">{{ t("frontThigh") }}</div>
                                <div class="col-3">
                                    <input
                                        id="thigh-1"
                                        :checked="orderLocation.data[4].indexOf(1) >= 0"
                                        name="thigh"
                                        type="radio"
                                        value="4-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="thigh-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="thigh-2"
                                        :checked="orderLocation.data[4].indexOf(2) >= 0"
                                        name="thigh"
                                        type="radio"
                                        value="4-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="thigh-2"></label>
                                </div>
                            </div>
                        </div>
                        <div class="front-body">
                            <div class="body_img">
                                <img alt="" src="@/assets/tgd/lightbox/body-frant.svg" />
                            </div>
                        </div>
                        <div class="center-check container p-0" >
                            <div class="row no-gutters align-items-center">
                                <div class="col-6"></div>
                                <div class="col-3">{{ t("lightly") }}</div>
                                <div class="col-3">{{ t("harder") }}</div>
                            </div>
                            <div class="row no-gutters align-items-center pt-2">
                                <div class="col-6">{{ t("upperArm") }}</div>
                                <div class="col-3">
                                    <input
                                        id="upper-arm-1"
                                        :checked="orderLocation.data[5].indexOf(1) >= 0"
                                        name="upper-arm"
                                        type="radio"
                                        value="5-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="upper-arm-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="upper-arm-2"
                                        :checked="orderLocation.data[5].indexOf(2) >= 0"
                                        name="upper-arm"
                                        type="radio"
                                        value="5-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="upper-arm-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 48px">
                                <div class="col-6">{{ t("lowerArm") }}</div>
                                <div class="col-3">
                                    <input
                                        id="lower-arm-1"
                                        :checked="orderLocation.data[6].indexOf(1) >= 0"
                                        name="lower-arm"
                                        type="radio"
                                        value="6-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="lower-arm-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="lower-arm-2"
                                        :checked="orderLocation.data[6].indexOf(2) >= 0"
                                        name="lower-arm"
                                        type="radio"
                                        value="6-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="lower-arm-2"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-gold">{{ t("backBody") }}</div>
                <div class="col-12">
                    <div class="news-body-right justify-content-end">
                        <div class="body_img">
                            <img alt="" src="@/assets/tgd/lightbox/body-back.svg" />
                        </div>
                        <div class="check-area container">
                            <div class="row no-gutters align-items-center">
                                <div class="col-6"></div>
                                <div class="col-3">{{ t("lightly") }}</div>
                                <div class="col-3">{{ t("harder") }}</div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 59px">
                                <div class="col-6">{{ t("neck") }}</div>
                                <div class="col-3">
                                    <input
                                        id="neck-1"
                                        :checked="orderLocation.data[7].indexOf(1) >= 0"
                                        name="neck"
                                        type="radio"
                                        value="7-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="neck-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="neck-2"
                                        :checked="orderLocation.data[7].indexOf(2) >= 0"
                                        name="neck"
                                        type="radio"
                                        value="7-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="neck-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 21px">
                                <div class="col-6">{{ t("shoulder") }}</div>
                                <div class="col-3">
                                    <input
                                        id="shoulder-1"
                                        :checked="orderLocation.data[8].indexOf(1) >= 0"
                                        name="shoulder"
                                        type="radio"
                                        value="8-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="shoulder-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="shoulder-2"
                                        :checked="orderLocation.data[8].indexOf(2) >= 0"
                                        name="shoulder"
                                        type="radio"
                                        value="8-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="shoulder-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 20px">
                                <div class="col-6">{{ t("upperBack") }}</div>
                                <div class="col-3">
                                    <input
                                        id="upper-back-1"
                                        :checked="orderLocation.data[9].indexOf(1) >= 0"
                                        name="upper-back"
                                        type="radio"
                                        value="9-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="upper-back-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="upper-back-2"
                                        :checked="orderLocation.data[9].indexOf(2) >= 0"
                                        name="upper-back"
                                        type="radio"
                                        value="9-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="upper-back-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 17px">
                                <div class="col-6">{{ t("middleBack") }}</div>
                                <div class="col-3">
                                    <input
                                        id="mid-back-1"
                                        :checked="orderLocation.data[10].indexOf(1) >= 0"
                                        name="mid-back"
                                        type="radio"
                                        value="10-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="mid-back-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="mid-back-2"
                                        :checked="orderLocation.data[10].indexOf(2) >= 0"
                                        name="mid-back"
                                        type="radio"
                                        value="10-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="mid-back-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 17px">
                                <div class="col-6">{{ t("waist") }}</div>
                                <div class="col-3">
                                    <input
                                        id="waist-1"
                                        :checked="orderLocation.data[11].indexOf(1) >= 0"
                                        name="waist"
                                        type="radio"
                                        value="11-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="waist-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="waist-2"
                                        :checked="orderLocation.data[11].indexOf(2) >= 0"
                                        name="waist"
                                        type="radio"
                                        value="11-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="waist-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 73px">
                                <div class="col-6">{{ t("rearThigh") }}</div>
                                <div class="col-3">
                                    <input
                                        id="hind-thigh-1"
                                        :checked="orderLocation.data[12].indexOf(1) >= 0"
                                        name="hind-thigh"
                                        type="radio"
                                        value="12-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="hind-thigh-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="hind-thigh-2"
                                        :checked="orderLocation.data[12].indexOf(2) >= 0"
                                        name="hind-thigh"
                                        type="radio"
                                        value="12-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="hind-thigh-2"></label>
                                </div>
                            </div>
                            <div class="row no-gutters align-items-center" style="padding-top: 73px">
                                <div class="col-6">{{ t("rearCalf") }}</div>
                                <div class="col-3">
                                    <input
                                        id="hind-calf-1"
                                        :checked="orderLocation.data[13].indexOf(1) >= 0"
                                        name="hind-calf"
                                        type="radio"
                                        value="13-1"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="hind-calf-1"></label>
                                </div>
                                <div class="col-3">
                                    <input
                                        id="hind-calf-2"
                                        :checked="orderLocation.data[13].indexOf(2) >= 0"
                                        name="hind-calf"
                                        type="radio"
                                        value="13-2"
                                        @change="changeLocation($event)"
                                    />
                                    <label for="hind-calf-2"></label>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n'
export default {
    name: 'CheckoutOrderLocation',
    props: {
        orderLocation: {
            type: Object,
            required: true,
        },
        changeLocation: {
            type: Function,
            required: true,
        },
        customerName: String,
    },
    setup(props) {
        const { t, locale } = useI18n()
        const langMenu = ref(false)
        const langMenuToogle = () => {
            langMenu.value = !langMenu.value
        }
        const localeText = computed(() => {
            if (locale.value === 'en') {
                return 'English'
            } else {
                return '繁體中文'
            }
        })
        return {
            t,
            locale,
            localeOptions: [
                {
                    id: 'en',
                    name: 'English'
                },
                {
                    id: 'zh-TW',
                    name: '繁體中文'
                }
            ],
            localeText,
            langMenu,
            langMenuToogle
        }
    }
};
</script>

<style lang="scss" scoped>
@import '~@/css/reset';
@import '~@/css/mixins';
@import '~@/css/grid';
.news-wrap {
    .news-header {
        display: flex;
        align-items: center;
        padding-bottom: 15px;
        border-bottom: 1px solid #a4641a;
        justify-content: space-between;
        .news-title {
            font-size: 20px;
            color: #a4641a;
            display: flex;
            align-items: center;
            font-weight: bold;
            span {
                line-height: 22px;
                padding-left: 10px;
            }
        }
    }
    .news-body {
        font: normal normal bold 18px/24px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #000000;
        position: relative;

        &.en{
            
            @media (max-width:600px) {
                font-size: 16px;
            }
            .news-body-left{
                @media (max-width:600px) {
                    max-width: 100%;
                    height: 600px;
                }
                .check-area{
                    width: 294px;
                    margin-left: -110px;
                    @media (max-width:600px) {
                        width: 230px;
                        margin-left: 0;
                        z-index: 1;
                    }
                }
                .col-6{
                    max-width: 64px;
                    @media (max-width:600px) {
                        max-width:52px;
                    }
                }
            }   
            .center-check{
                width: 294px;
                left: 60%;
                @media (max-width:600px) {
                    width: 230px;
                    left: unset;
                    right: 0;
                }
                .no-gutters{
                    @media (max-width:600px) {
                            justify-content: end;
                        }
                    .col-3{
                        justify-content: center;
                    }
                    .col-6{
                        max-width: 100px;
                        
                        @media (max-width:600px) {
                            max-width: 46px;
                        }
                    }
                }
            }
            .front-body{
                margin-left: -100px;
                @media (max-width:600px) {
                    position: absolute;
                    transform: translateX(-50%);
                    left: 50%;
                    width: 100%;
                    margin-left: 0;
                    text-align: center;
                    // margin-top: 20px;
                }
            }
            .news-body-right{
                max-width: 530px;
                .col-12{
                    .check-area{
                        margin-left: 0;
                    }
                }
            }
            .col-12 {
                .check-area{
                    min-width: 294px;
                    .no-gutters {
                        .col-3{
                            justify-content: center;
                        }
                    }
                }
            }
        }

        .text-gold {
            padding-bottom: 50px;
            padding-top: 45px;
            color: #a4641a;
            text-align: center;
        }

        .news-body-left,
        .news-body-right {
            max-width: 382px;
            display: flex;
            margin: 0 auto;
        }
      
        .col-12 {
            .news-body-right {
                .check-area{
                    @media (max-width:600px) {
                        min-width: 220px;
                        margin-left: -100px;
                    }
                }
            }
            .check-area {
                min-width: 120px;
                width: 100%;
                position: relative;
                top: -10px;
                padding: 0;
                .no-gutters {
                    .col-3 {
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 10px;
                    }
                }
            }
        }
        .center-check {
            width: 120px;
            position: absolute;
            top: 270px;
            left: 65%;
            .no-gutters {
                .col-3 {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 10px;
                }
            }
        }
        input {
            display: none;
        }
        label {
            background: no-repeat center/contain url(~@/assets/tgd/lightbox/checkbox-none.svg);
            padding: 10px;
        }
        input:checked + label {
            background: no-repeat center/contain url(~@/assets/tgd/lightbox/checkbox-block.svg);
        }
    }
}
.lang-select-box{
    position: relative;
    z-index: 9;
    &.show{
        .lang-select-list{
            opacity: 1;
            pointer-events: auto;
        }
    }
    
    .lang-select-arrow{
        position: absolute;
        height: 100%;
        width: 30px;
        background: linear-gradient(180deg, #a4641a, #ebc496);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        pointer-events: none;
        svg{
            filter: invert(1);
        }
    }
    .lang-select-btn{
        min-width: 130px;
        overflow: hidden;
        background: linear-gradient(180deg, #ececec, #f9f9f9, white);
        box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61), 0px 3px 6px rgba(0, 0, 0, 0.29);
        border-radius: 11px;
        border: 1px solid white;
        position: relative;
        p{
            padding: 12px 50px 12px 16px;
        }
    }
    .lang-select-list{
        position: absolute;
        padding: 11px;
        border-radius: 5px;
        border: 1px solid white;
        box-shadow: 0px 10px 8px 0 rgba(0, 0, 0, 0.33);
        background: #191919;
        margin-top: 6px;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;

        a{
            display: block;
            padding: 9px 20px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 500;
            color: white;
            &:hover{
                background: rgba(255, 255, 255, 0.1);
            }
        }
    }
}

.qrcode_custom_name{
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 11px;
    height: 53px !important;
    border: 1px #fff solid;
    border-radius: 13px;
    display: inline-flex;
    align-items: center;
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box;
    overflow: hidden;
    margin-bottom: 20px;
    span{
        display: block;
        position: relative;
        padding: 15px;   
        font-weight: bold;
        &:after{
            content: '';
            display: block;
            width: 1px;
            height: 22px;
            background: #eee;
            position: absolute;
            right: 0;
            top: 15px;
        }
    }
    input{
        border: 0;
        padding: 15px;
        flex:1;
        font-weight: bold;
        background: 0;
        font: normal normal 600 16px/22px Open Sans;
        transition: .4s ease;
    }
    &:hover{
        input{
            background: transparent
            linear-gradient(180deg, #eee 0%, #ededed 35%, #ffffff 100%) 0% 0%
            no-repeat padding-box;
        }
    }
}

.body_img{
    filter: grayscale(100%);
}

</style>
