import { basePost } from "@/js/services/baseService";
import moment from "moment";

export default {
    namespaced: true,
    state: {
        currentDate: moment().format("YYYY-MM-DD"),
        shiftList: [],
        scheduleList: [],
        // scheduleCanlenderList: [],
        leaveInfo: null,
        selectedMemberId: 0,
        selectedRosterDaysId: 0,
        selectedDate: 0,
        // 允許挪假的日期
        allowedChangeDates: [],
        shiftMemberInfo: {
            name: "",
            date: ""
        }
    },
    getters: {
        getYear: (state) => {
            return moment(state.currentDate).format("YYYY");
        },
        getMonth: (state) => {
            return moment(state.currentDate).format("MM");
        },
        getDays: (state) => {
            return moment(state.currentDate).daysInMonth();
        },
        getCurrentDate: (state) => {
            return moment(state.currentDate).date();
        },
    },
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
        },
    },
    actions: {
        async getScheduleList({ commit }, params) {
            try {
                const { store_id, year, month } = params;
                const res = await basePost("/api_roster/roster_list", {
                    store_id,
                    year,
                    month,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { member_list, arrange_list } = data.res_data;

                    commit("update", {
                        key: "scheduleList",
                        value: member_list,
                    });

                    commit("update", {
                        key: "shiftList",
                        value: arrange_list,
                    });

                    // console.log("scheduleList: ", member_list);
                    // console.log("shiftList: ", arrange_list);
                }
            } catch (err) {
                console.error(err);
            }
        },
        // async getScheduleCanlenderList({ commit }, params) {
        //     try {
        //         const { store_id, year, month } = params;
        //         const res = await basePost("/api_roster/roster_list", {
        //             store_id,
        //             year,
        //             month,
        //         });
        //         const { status, data } = res;

        //         if (status === 200 && data.res_code === 1) {
        //             const { member_list, arrange_list } = data.res_data;

        //             commit("update", {
        //                 key: "scheduleCanlenderList",
        //                 value: canlender_member_list,
        //             });

        //             commit("update", {
        //                 key: "shiftList",
        //                 value: canlender_arrange_list,
        //             });

        //             console.log("scheduleList: ", member_list);
        //             console.log("shiftList: ", arrange_list);
        //         }
        //     } catch (err) {
        //         console.error(err);
        //     }
        // },
        async getLeaveInfo({ commit, state }, params) {
            const { store_id, roster_days_id, memberId, date } = params;

            commit("update", {
                key: "selectedMemberId",
                value: memberId,
            });

            commit("update", {
                key: "selectedRosterDaysId",
                value: roster_days_id,
            });

            commit("update", {
                key: "selectedDate",
                value: date,
            });

            try {
                const res = await basePost("/api_roster/leave", {
                    store_id,
                    roster_days_id,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "leaveInfo",
                        value: data.res_data,
                    });
                    // console.log("data: ", state.leaveInfo);
                }
            } catch (err) {
                console.error(err);
            }
        },
        async getAllowedChangeDates({ commit, state, getters }, params) {
            try {
                const { store_id, member_id } = params;
                const res = await basePost(
                    "/api_roster/can_toster_leave_list",
                    {
                        store_id,
                        member_id,
                        year: getters.getYear,
                        month: getters.getMonth,
                    }
                );
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    state.allowedChangeDates = data.res_data.list;
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
