<template>
    <div class="customer">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>
                > 購買記錄
            </div>
        </div>
        <div class="wrap">
            <div class="input-wrap">
                <div class="input-group mb-3 me-3">
                    <div class="select-wrap" style="width: 47.8%">
                        <BaseDatepicker
                            class="radius-left"
                            @selectChange="HandleStartDateChange"
                            format="YYYY.MM.DD"
                            :initVal="aMonthAgo"
                        />
                    </div>
                    <div
                        class="select-wrap rounded-0"
                        style="width: 4.4%; color: #707070"
                    >
                        ──
                    </div>
                    <div class="select-wrap" style="width: 47.8%">
                        <BaseDatepicker
                            class="radius-right"
                            @selectChange="HandleEndDateChange"
                            format="YYYY.MM.DD"
                        />
                    </div>
                </div>
                <div class="input-group search-group mb-3">
                    <input
                        type="text"
                        class="form-control"
                        placeholder="輸入搜尋關鍵字"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                        v-model.trim="keyword"
                        @keyup.enter="getMerchandiseRecordList(1)"
                    />
                    <div class="input-group-append">
                        <button
                            class="btn"
                            type="button"
                            @click="getMerchandiseRecordList(1)"
                        >
                            <img
                                src="@/assets/tgd/reserve/magnifier.svg"
                                alt=""
                            />
                        </button>
                    </div>
                </div>
            </div>
            <transition appear name="fade" v-if="isListRequest">
                <div class="row reserve-container">
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="order-list-header">
                                <div class="row no-gutters">
                                    <div class="col-1">序</div>
                                    <div class="col-2 blue">#訂單編號</div>
                                    <div class="col-2">購買內容</div>
                                    <div class="col-1">購買日期</div>
                                    <div class="col-1">購買分店</div>
                                    <div class="col-1 cash-color">
                                        <img
                                            src="@/assets/tgd/customer/money.svg"
                                            alt=""
                                        />
                                        <span> 現金 </span>
                                    </div>
                                    <div
                                        class="col-1 blue"
                                    >
                                        <img
                                            src="@/assets/tgd/customer/other_money.svg"
                                            alt=""
                                        />
                                        <span> 其他支付 </span>
                                    </div>
                                    <div class="col-1 sum-color">
                                        <img
                                            src="@/assets/tgd/customer/totle_money.svg"
                                            alt=""
                                        />
                                        <span> 訂單金額 </span>
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                            </div>
                            <div
                                class="order-list num-font"
                                v-for="(item, index) in merchandiseRecordList.list"
                                :key="index"
                            >
                                <div class="row no-gutters">
                                    <div class="col-1">
                                        {{ index + 1 }}
                                    </div>
                                    <div class="col-2 order-number-color">
                                        {{ item.order_number }}
                                    </div>
                                    <div class="col-2">
                                        <template v-for="(val, i) in item.buy_list" :key="i">
                                            <div>{{ val.name }} * {{ val.num }}</div>
                                        </template>
                                    </div>
                                    <div class="col-1">
                                        {{ item.buy_date }}
                                    </div>
                                    <div class="col-1">
                                        {{ item.buy_store }}
                                    </div>
                                    <div class="col-1 cash-color">
                                        {{ item.cash_payment }}
                                    </div>

                                    <div class="col-1 blue" >
                                        {{ item.other_payment }} {{ item.payment_method }}
                                    </div>
                                    <div class="col-1 sum-color">
                                        {{ item.amount }}
                                    </div>
                                    <div class="col-2 text-center" v-if="item.order_status == 9">
                                        已取消
                                    </div>
                                    <div class="col-2" v-else>
                                        <div
                                            v-if="item.is_cancel == 1"
                                            class="btn-cancel mx-auto"
                                            @click="confirmRefund(item.order_id)"
                                        >
                                            <img
                                                src="@/assets/tgd/customer/garbege.svg"
                                                alt=""
                                            />
                                            取消訂單
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NoData
                                :isFixed="false"
                                v-show="!merchandiseRecordList.list.length"
                            />
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="row">
            <div class="col-12" v-if="pageInfo.totalPage > 1">
                <BasePagination
                    :pageInfo="pageInfo"
                    @pageChange="updatePageData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";

import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import moment from "moment";
import Swal from "sweetalert2";

export default {
    name: "Coupon",
    components: {
        Logo,
        NoData,
    },
    setup() {
        const { state, commit } = useStore();
        const isListRequest = ref(false);

        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });

        // 購買列表
        let merchandiseRecordList = reactive({ list: [] });

        // 關鍵字搜尋
        const keyword = ref("");

        // 客戶 ID
        const customerId = JSON.parse(localStorage.getItem("customerId"));

        const startDate = ref();
        const endDate = ref();

        const HandleStartDateChange = (selectVal) => {
            startDate.value = moment(selectVal).format("YYYY-MM-DD");
            getMerchandiseRecordList(1);

        }
        const HandleEndDateChange = (selectVal) => {
            endDate.value = moment(selectVal).format("YYYY-MM-DD");
            getMerchandiseRecordList(1);
        }


        // 取得商品購買紀錄列表
        const getMerchandiseRecordList = async (num = 1) => {
            if(!startDate.value || !endDate.value) return;
            try {
                const res = await basePost("/api_customer/merchandise_buy_log",
                    {
                        customer_id: customerId,
                        page: num,
                        keyword: keyword.value,
                        search_start_date: startDate.value,
                        search_end_date: endDate.value,
                    }
                );

                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    merchandiseRecordList.list = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        let thisPage = ref(1);
        const updatePageData = (num) => {
            thisPage.value = num
            getMerchandiseRecordList(num);
        };

        const confirmRefund = (id) => {
            Swal.fire({
                title: '取消訂單',
                text: '是否進行退費',
                icon: 'question',
                showCloseButton: true,
                confirmButtonText: "已退費",
                showDenyButton: true,
                denyButtonText: "未退費",
            }).then((res) => {
                if (res.isConfirmed) {
                    refund(id, 9)
                } else if (res.isDenied) {
                    refund(id, null)
                }
            });
        }

        // 取消訂單
        const refund = async (orderId, is_refund) => {
            try {
                const res = await basePost("/api_order/cancel_order_check",
                    {
                        order_id: orderId,
                        is_refund
                    }
                );
                const { status, data } = res;
                Swal.fire({
                    title: "取消成功!",
                    icon: "success",
                    confirmButtonText: "確定",
                }).then(() => {
                    getMerchandiseRecordList(thisPage.value);
                });
            } catch (err) {
                console.error(err);
            }
        };

        watch(merchandiseRecordList, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        const aMonthAgo = moment().add(-1,'M').format();

        return {
            updatePageData,
            merchandiseRecordList,
            pageInfo,
            getMerchandiseRecordList,
            keyword,
            isListRequest,
            HandleStartDateChange,
            HandleEndDateChange,
            aMonthAgo,
            confirmRefund
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1515px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 1400px) {
            padding: 0;
        }
        .input-wrap {
            position: absolute;
            top: -68px;
            right: -5px;
            display: flex;
            @include pad_air {
                position: relative;
                top: 0;
                left: -10px;
            }
            @include phone {
                flex-direction: column;
            }

            .input-group {
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 11px;
                height: 53px;
                width: 350px;
                overflow: hidden;
                @include pad_pro {
                    width: 300px;
                    height: 45px;
                }
                .select-wrap {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    display: flex;
                    align-items: center;
                    height: 53px;
                    &:nth-child(1) {
                        justify-content: flex-end;
                    }
                }
                .form-control {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border: none;
                    border-radius: 11px 0px 0px 11px;
                    padding: 0 15px;
                    position: relative;
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    @include hover(90%);
                }
                .input-group-append {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    border-radius: 0 11px 11px 0;
                    @include hover(130%);
                    .btn {
                        height: 100%;
                        padding: 0 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
        .reserve-container {
            justify-content: center;
            @media (max-width: 1400px) {
                overflow: overlay;
                margin: 0;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .reserve-title {
                    position: relative;
                    top: -40px;
                    margin-bottom: 20px;
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #0f2a68;
                    @include pad_pro {
                        margin: 15px 0;
                        margin-top: 20px;
                    }
                }
                .order-number-color {
                    color: #767F98;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #0F2A68;
                }
                .cash-color {
                    color: #A4641A;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1500px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro_lg {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                    position: relative;
                    top: 60px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                        .col-1 {
                            // width: 11.11111%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            img {
                                padding-right: 5px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                            &:last-child {
                                margin: 0 0 0 auto;
                            }
                        }
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 25px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    display: flex;
                    .row {
                        flex: 1;
                        align-items: center;
                        .col-1 {
                            // width: 11.11111%;
                            display: flex;
                            align-content: center;
                            justify-content: center;
                            img {
                                padding-right: 5px;
                            }
                            span {
                                position: relative;
                                top: 2px;
                            }
                            a {
                                text-decoration: underline !important;
                            }
                            &:last-child {
                                margin: 0 0 0 auto;
                            }
                        }
                    }
                    &:hover {
                        filter: contrast(90%);
                    }

                    .btn-cancel {
                        background: #A2A2A2;
                        border-radius: 21px;
                        font: normal normal bold 14px/19px Microsoft JhengHei;
                        letter-spacing: 0px;
                        color: #ffffff;
                        width: 92px;
                        padding: 8px 0;
                        display: flex;
                        align-content: center;
                        justify-content: center;
                        cursor: pointer;
                        @include hover(90%);
                        img {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
    .cursor-pointer {
        cursor: pointer;
    }
}
:deep(.select-group) {
    max-width: 224.5px;
    margin-left: auto;
}
</style>
