import { computed, reactive } from "vue";

const API_EMPTY_LIST = {
    // 郁豪 : 後端API使用
    // 傳入0則代表list為空
    0: 0,
}

export default {
    namespaced: true,
    state: () => ({
        cartList: {
            0: 0,
        },
    }),
    getters: {
        // 詳細
        list(state, getters) {
            return reactive({
                data: state.cartList,
            });
        },
        ids(state, getters) {
            return Object.keys(state.cartList);
        },
    },
    mutations: {
        updateStockData(state, list) {
            state["cartList"] = list;
        },
    },
    actions: {
        add({ commit, state }, item) {
            let list = state.cartList;

            if (item.num === undefined) {
                item.num = 0;
            }

            if (item.num == 0) {
                delete list[item.id];
            } else {
                list[item.id] = item.num;
            }
            commit("updateStockData", list);
        },
        delete({ commit, state }, id) {
            let list = state.cartList;
            delete list[id];
            commit("updateStockData", list);
        },
        empty({ commit }) {
            commit("updateStockData", API_EMPTY_LIST);
        },
    },
};
