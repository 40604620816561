import * as yup from "yup";

const { addMethod, BaseSchema } = yup;

addMethod(BaseSchema, "requireStringOrNumber", function requireStringOrNumber(errMsg) {
    return this.test("test-mix-type", function (value) {
        const { path, createError } = this;
        const convert2Num = Number(value);

        if(isNaN(convert2Num)) {
            return value;
        }

        if (!convert2Num) {
            return createError({ path, message: errMsg });
        }

        return value;
    });
});
