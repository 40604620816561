<template>
    <div class="right">
        <ul class="tab-wrap">
            <li>
                <router-link to="/">
                    <span>進行中訂單</span>
                </router-link>
            </li>
            <li>
                <router-link to="/on_site_room">
                    <span>現場房況</span>
                </router-link>
            </li>
            <li>
                <router-link to="/reservation">
                    <span>本日預約</span>
                </router-link>
            </li>
            <li>
                <router-link to="/reservation_table/multi_aroma">
                    <span>新增預約</span>
                </router-link>
            </li>
            <li>
                <a href="javascript:;" @click="openCouponLightbox">
                    <span>票券購買</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" @click="openChangeCouponLightbox">
                    <span>換劵</span>
                </a>
            </li>
            <li>
                <a href="javascript:;" @click="openMerchandiseLightbox">
                    <span>商品購買</span>
                </a>
            </li>
            <template v-if="$slots.default">
                <li>
                    <slot></slot>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

export default {
    name: "TopTab",
    setup() {
        const { 
            openCouponLightbox, 
            openChangeCouponLightbox,
            openMerchandiseLightbox 
        } = useHandleLightboxOpen();

        return { 
            openCouponLightbox, 
            openChangeCouponLightbox,
            openMerchandiseLightbox 
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.right {
    padding: 5px;
    overflow: overlay;
    @media (max-width: 1200px) {
         margin-top: 30px;
    }
    .tab-wrap {
        display: flex;
        li {
            margin-left: 15px;
            a {
                display: flex;
                align-items: center;
                border-radius: 25px;
                border: 1px solid #84340c;
                font-size: 14px;
                font-weight: bold;
                padding: 10px 30px;
                padding-left: 55px;
                color: #b77f1a;
                text-align: center;
                @include pad_air_lg{
                    padding: 10px 20px;
                    padding-left: 45px;
                }
                @include pad_pro{
                    background: #fff !important;
                    padding: 10px 25px;
                }
                @include pad_air{
                    padding: 10px 25px;
                }
                @include phone {
                    padding: 7px 24px;
                }
                span {
                    line-height: 19px;
                    @include pad {
                        width: 80px;
                    }
                }
            }
            .router-link-active {
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                color: #fff;

            }
            &:nth-child(1){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/house.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/house-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/house-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(2){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/bed.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/bed-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/bed-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(3){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/date.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/date-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/date-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(4){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/add.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/add-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                   background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;

                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/add-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(5){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/coupon.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/coupon-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/coupon-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(6){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/tent.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/tent-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/tent-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
            &:nth-child(7){
                a{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/merchandise.svg"), #fff;
                    transition: all .3s;
                    &:hover{
                        background: 15% 46% no-repeat url("~@/assets/tgd/index/merchandise-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    color: #fff;
                    border: 1px solid #fff;
                    box-shadow: 0px 3px 6px #00000029;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                    }
                }
                .router-link-active{
                    background: 15% 46% no-repeat url("~@/assets/tgd/index/merchandise-white.svg"), transparent
                    linear-gradient(
                        106deg,
                        #ab7e2b 0%,
                        #f1e3b5 14%,
                        #ab7e2b 61%,
                        #c48109 100%
                    )
                    0% 0% no-repeat padding-box !important;
                    @include pad_pro {
                    background:transparent
                    linear-gradient(
                        106deg,
                        #EBC496 0%,
                        #A4641A 50%,
                        #A4641A 100%,
                    )
                    0% 0% no-repeat padding-box !important;
                }
                }
            }
        }
    }
}
</style>
