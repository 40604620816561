import { createStore, createLogger } from "vuex";
import modules from "./modules"; // 引入modules




export default createStore({
    modules,
    plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
});
