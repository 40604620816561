<template>
    <div class="customer-detail">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">
                <router-link to="/customer"> 客戶管理 </router-link>>
                <router-link :to="{ name: 'CouponRecord' }">票劵紀錄</router-link>
                > 詳細頁
            </div>
        </div>
        <div class="wrap">
            <transition appear name="fade" v-if="isTicketRequest">
                <div class="row detail-container">
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="order-list-header">
                                <div class="row g-0" >
                                    <div class="col-2">購買類型</div>
                                    <div class="col-2">票券名稱</div>
                                    <div class="col-4 gray">#券號</div>
                                    <div class="col-2 text-center">是否寄券</div>
                                    <div class="col-2 text-center">金額</div>
                                </div>
                            </div>
                            <div
                                class="order-list num-font"
                                v-show="ticketData.data.list.length"
                            >
                                <div class="row g-0 "
                                    v-for="(item, index) in ticketDetailList"
                                    :key="index"
                                >
                                    <template v-for="(val, i) in item.list" :key="i">
                                        <div class="col-2" v-if="i===0">{{ val.type }} {{  val.package_name ? `- ${val.package_name}` : '' }}</div>
                                        <div class="col-2" v-else></div>
                                        <div class="col-2 fw-normal">{{ val.ticket_name }}</div>
                                        <div class="col-4 gray fw-normal num-font">{{ val.ticket_number }}</div>
                                        <div class="col-2 text-center">
                                            <button
                                                class="circle-old mx-auto"
                                                @click="setTicketSendCoupons(val)"
                                            >
                                                <img
                                                    v-if="val.send_coupons === '是'"
                                                    src="@/assets/tgd/customer/is_old.svg"
                                                    alt=""
                                                />
                                            </button>
                                        </div>
                                        <div class="col-2 text-center red num-font" v-if="i===0">${{ val.price }}</div>
                                        <div class="col-2" v-else></div>
                                    </template>
                                </div>
                            </div>
                            <NoData
                                :isFixed="false"
                                v-show="!ticketData.data.list.length"
                            />
                            <div
                                class="total"
                                v-show="ticketData.data.list.length"
                            >
                                <div class="row border-bottom red">
                                    <div class="col-5 text-start">小計</div>
                                    <div class="col-7 text-end fw-bold num-font">
                                        ${{ ticketData.data.subtotal }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-5 text-start">信用卡付款</div>
                                    <div class="col-7 text-end fw-bold num-font">
                                        ${{ ticketData.data.other_payment }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-5 text-start">現金付款</div>
                                    <div class="col-7 text-end fw-bold num-font">
                                        ${{ ticketData.data.cash_payment  }}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";

import { ref, reactive, watch , computed } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import { flashMessage } from '@/js/swal';
import { debounce } from "lodash";

export default {
    name: "ConsumptionRecordDetail",
    components: {
        Logo,
        NoData,
    },
    setup() {
        const { state } = useStore();
        const isTicketRequest = ref(false);

        // 票券資料
        let ticketData = reactive({ data: {} });

        // 客戶 ID
        const orderId = JSON.parse(localStorage.getItem("orderId"));

        //取得票券購買詳細資料
        const getTicketDetail= async (orderId) => {
            try {
                const res = await basePost(
                    "/api_customer/consumption_ticket_log_details",
                    {
                        order_id: orderId,
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    ticketData.data = data.res_data
                }
            } catch (err) {
                console.error(err);
            }
        };
        getTicketDetail(orderId);

        watch(ticketData, () => {
            if(!isTicketRequest.value) {
                isTicketRequest.value = true;
            }
        })

        const ticketDetailList = computed(()=> {
            if(!ticketData.data.list) return [];
            return [...ticketData.data.list].reduce((prev, curr)=>{
                const colorItem = prev.find(item => item.color === curr.color)
                if(colorItem){
                    colorItem.list.push(curr)
                }else {
                    return [...prev, {
                        color: curr.color,
                        list: [curr]
                    }]
                }
                return [...prev]
            },[])
        })

         //票券設定是否寄券
        const setTicketSendCoupons = debounce(async (item) => {
            try {
                const res = await basePost(
                    "/api_ticket/ticket_send_coupons",
                    {
                        order_id: orderId,
                        type_ticket: item.type_ticket,
                        ticket_number: item.ticket_number,
                        ticket_id: item.ticket_id,
                        send_coupons: item.send_coupons == '是' ? 0 : 1
                    }
                );
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    flashMessage('寄券修改成功');
                    item.send_coupons = item.send_coupons == '是' ? '否' : '是';
                }else {
                    flashMessage(data.res_content, "error");
                }
            } catch (err) {
                console.error(err);
            }
        }, 200);

        return {
            isTicketRequest,
            ticketData,
            ticketDetailList,
            setTicketSendCoupons
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer-detail {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
            a {
                text-decoration: underline !important;
                font: normal normal bold 20px/27px Microsoft JhengHei;
                letter-spacing: 0px;
                color: #ffffff;
            }
            @include phone {
                margin-bottom: 20px;
            }
        }
    }
    .wrap {
        max-width: 1600px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 1400px) {
            padding: 0;
        }
        .detail-container {
            justify-content: center;
            @media (max-width: 1400px) {
                overflow: overlay;
                margin: 0;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
                .money-color {
                    color: #a4641a;
                }
                .pay-type-color {
                    color: #707070;
                }
                .sum-color {
                    color: #c80036;
                }
                .blue {
                    color: #0f2a68;
                }
            }
            .order-list-wrap {
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1600px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 70px 55px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                line-height: 21px;
                @include pad_pro_lg {
                    padding: 0 20px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                    position: relative;
                    top: 60px;
                }
                .order-list-header {
                    position: relative;
                    top: -25px;
                    display: flex;
                    padding: 0 20px;

                    .row {
                        flex: 1;
                        align-items: center;
                    }
                    @include pad_pro {
                        top: -15px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 25px;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    .row {
                        padding: 7px 0;
                        flex: 1;
                        align-items: center;
                        &:nth-child(2n - 1) {
                            background: rgba(214, 214, 214, .2);
                        }
                        .col-1,
                        .col-2,
                        .col-4 {
                            padding: 10px 15px;
                        }


                    }
                    &:hover {
                        filter: contrast(90%);
                    }
                }
            }
            .gray {
                color: #767F98;
            }
        }
        .circle-old {
            width: 20px;
            height: 20px;
            background: #D6D6D6;
            border-radius: 50%;
            padding: 0;
            margin: 0;
            border: none;
        }
    }
    .total {
        width: 450px;
        margin: 0 0 0 auto;
        padding: 10px;
        .row {
            padding: 10px 0;
        }
        .border-bottom {
            border-bottom: 1px dashed #c9c9c9 !important;
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
    }
    .red {
        color: #C80036;
    }
}
</style>
