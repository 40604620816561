import { computed, reactive } from "vue";
import { basePost } from "../../js/services/baseService";

export default {
    namespaced: true,
    state: () => ({
        checkOutData: {},
    }),
    getters: {
        // 詳細
        data(state, getters) {
            return reactive({
                data: state.checkOutData,
            });
        },
    },
    mutations: {
        updateData(state, list) {
            state.checkOutData = list;
        },
        emptyData(state) {
            state.checkOutData = {};
        },
    },
    actions: {
        init({ commit, state }, { store_id, order_id }) {
        },
        add({ commit, state }, list) {
            commit("updateData", list);
        },
        delete({ commit, state }, list) {
            commit("updateData", list);
        },
        empty({ commit }) {
            commit("emptyData");
        },
    },
};
