// 檢查選項是否為正整數
const positiveInteger = (message = "僅能填入大於 0 的數字") => {
    return [
        "檢查正整數",
        message,
        (value) => {
            return Number.isInteger(value) && value >= 0;
        },
    ];
};

// 檢查布林值
const checkBoolean = (message = "僅能選擇是或否") => {
    return [
        "確認參數有效與否",
        message,
        (value) => {
            if (value == 1 || value == 0) {
                return true;
            }
            return false;
        },
    ];
};

// 檢查選項是否包含在內
const checkInOptions = (options = [], message = "選項錯誤") => {
    return [
        "確認參數有效與否",
        message,
        (value) => {
            if (options.indexOf(value) >= 0) {
                return true;
            }
            return false;
        },
    ];
};

export { positiveInteger, checkBoolean, checkInOptions};
