import { createRouter, createWebHistory } from "vue-router";
import { checkLoginStatus } from "../js/services/baseService";
import store from "@/store/index";
import Customer from "@/pages/Customer/Customer.vue";
import ConsumptionRecord from "@/pages/Customer/ConsumptionRecord.vue";
import CouponRecord from "@/pages/Customer/CouponRecord.vue";
import UsageRecord from "@/pages/Customer/UsageRecord.vue";
import CouponRecordDetail from "@/pages/Customer/CouponRecordDetail.vue";
import MerchandiseRecord from "@/pages/Customer/MerchandiseRecord.vue";
import ClockIn from "@/pages/ClockIn.vue";
import Stock from "@/pages/Stock/Stock.vue";
import Record from "@/pages/Stock/Record.vue";
import HandoverRecord from "@/pages/HandoverRecord.vue";
import AromaIndex from "@/pages/Aroma/Index.vue";
import AromaPost from "@/pages/Aroma/Post.vue";
import Info from "@/pages/Aroma/Info.vue";
import AttendanceRecord from "@/pages/Aroma/AttendanceRecord.vue";
import AromaPayroll from "@/pages/Aroma/AromaPayroll.vue";
import AromaPayrollDetail from "@/pages/Aroma/AromaPayrollDetail.vue";
import ClockIndex from "@/pages/ClockIndex.vue";
import QrCodeCard from "@/pages/QrCodeCard.vue";
import { ref } from 'vue';

const { commit, dispatch } = store;

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('@/pages/Index.vue'),
    },
    {
        path: '/post',
        name: 'Post',
        component: () => import('@/pages/Post.vue'),
    },

    {
        path: '/on_site_room',
        name: 'OnSiteRoom',
        component: () => import('@/pages/OnSiteRoom.vue'),
    },

    {
        path: '/reservation',
        name: 'Reservation',
        component: () => import('@/pages/Reservation.vue'),
    },

    // 登入
    {
        path: '/login',
        name: 'LogIn',
        component: () => import('@/pages/LogIn.vue'),
    },

    // 客戶
    {
        path: '/customer',
        name: 'Customer',
        component: Customer,
    },
    {
        path: '/customer/consumption_record',
        name: 'ConsumptionRecord',
        component: ConsumptionRecord,
    },
    {
        path: '/customer/coupon_record',
        name: 'CouponRecord',
        component: CouponRecord,
    },
    {
        path: '/customer/coupon_record/usage_record',
        name: 'UsageRecord',
        component: UsageRecord,
    },
    {
        path: '/customer/coupon_record/detail',
        name: 'CouponRecordDetail',
        component: CouponRecordDetail,
    },
    {
        path: '/customer/merchandise_record',
        name: 'MerchandiseRecord',
        component: MerchandiseRecord,
    },
    {
        path: '/clock_in',
        name: 'ClockIn',
        component: ClockIn,
    },

    // 庫存
    {
        path: '/stock',
        name: 'Stock',
        component: Stock,
    },
    {
        path: '/stock/record',
        name: 'Record',
        component: Record,
    },

    {
        path: '/handover_record',
        name: 'HandoverRecord',
        component: HandoverRecord,
    },

    // 芳療師
    {
        path: '/aroma_index',
        name: 'AromaIndex',
        component: AromaIndex,
    },
    {
        path: '/aroma_post',
        name: 'AromaPost',
        component: AromaPost,
    },
    {
        path: '/info',
        name: 'Info',
        component: Info,
    },
    {
        path: '/attendance_record',
        name: 'AttendanceRecord',
        component: AttendanceRecord,
    },
    {
        path: '/aroma_payroll',
        name: 'AromaPayroll',
        component: AromaPayroll,
    },
    {
        path: '/aroma_payroll_detail/:id',
        name: 'AromaPayrollDetail',
        component: AromaPayrollDetail,
    },

    // 打卡
    {
        path: '/clock_index',
        name: 'ClockIndex',
        component: ClockIndex,
    },

    // 新增預約
    {
        path: '/reservation_table/',
        name: 'ReservationTable',
        component: () => import('@/pages/Reservation/ReservationTable.vue'),
        children: [
            {
                path: 'multi_aroma',
                component: () => import('@/pages/Reservation/MultiAroma.vue'),
            },
            {
                path: 'single_aroma',
                component: () => import('@/pages/Reservation/SingleAroma.vue'),
            },
        ],
    },
    // 芳療師班表
    {
        path: '/schedule',
        name: 'Schedule',
        component: () => import('@/pages/Schedule.vue'),
    },
    // 芳療師班表
    {
        path: '/schedule_canlender',
        name: 'Schedule_canlender',
        component: () => import('@/pages/Schedule_canlender.vue'),
    },
    // qrcode
    {
        path: '/qrcode-card',
        name: 'QrCodeCard',
        component: QrCodeCard,
        beforeEnter: (to, from, next) => {
            document.querySelector('#app').style['background-size'] = '130%';
            document.querySelector('#app').style['background-position'] = 'top right';
            next();
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


router.beforeEach(async (to, from) => {
    const { name, path } = to;
    const { name : fromName, path: fromPath} = from;
    const TOKEN = localStorage.getItem("token");
    let isLoggedIn = JSON.parse(localStorage.getItem("is_logged_in"));

    console.log('to', to)
    // console.log('from', from)

    try {
        const res = await checkLoginStatus();
        const memberType = Number(localStorage.getItem("member_type"));

        console.log('memberType', memberType);
        
        if (TOKEN && isLoggedIn && res === 1) {
            commit("auth/updateMemberData", {
                token: TOKEN,
                member_type: memberType,
                member_name: localStorage.getItem("member_name"),
                store_id: localStorage.getItem("store_id"),
                lounge_id: localStorage.getItem("lounge_id"),
                is_logged_in: isLoggedIn,
                store_name: localStorage.getItem("store_name"),
                member_id: localStorage.getItem("member_id"),
                hierarchy: localStorage.getItem("hierarchy"),
                rank_name: localStorage.getItem("rank_name"),
            });

            if (memberType === 1) {
                // 芳療師不能進入的頁面
                if (
                    name === "Index" ||
                    name === "OnSiteRoom" ||
                    name === "Reservation" ||
                    path === "/reservation_table/multi_aroma" ||
                    path === "/reservation_table/single_aroma" ||
                    path === "/reservation_table/multi_aroma" ||
                    name === "Customer" ||
                    name === "HandoverRecord" ||
                    name === "Schedule" ||
                    name === "Stock" ||
                    name === "ClockIn" ||
                    name === "ConsumptionRecord" ||
                    name === "MerchandiseRecord"
                ){
                    return '/aroma_index';
                }

                commit('auth/setIsCounterStaff', false);

            } else if (memberType === 2 || memberType === 3) {
                // 櫃台不能進入的頁面
                if (
                    name === "AromaIndex" || 
                    name === "AttendanceRecord" || 
                    name === "Info"
                ) {
                    return '/';
                }

                commit('auth/setIsCounterStaff', true);
                
            }

            if (name !== "AromaIndex" && name !== "Index" && name !== "LogIn") {
                return true;
            }
            
        } else {
            commit('auth/setIsCounterStaff', false);
            if (name === "QrCodeCard") return true;
            if (name === "LogIn") return true;
            if (name === "ClockIndex") return true;
            else return "/login";
        }
    } catch (err) {
        console.error(err);
    }
});

export default router;
