<template>
    <div class="index">
        <AromaSideMenu />
        <div class="top">
            <AromaLogo />
        </div>
        <transition appear name="fade">
            <div class="container">
                <div class="info-wrap">
                    <div class="info-header">
                        <h2>基本資訊</h2>
                    </div>
                    <div class="info-body">
                        <!-- <form @submit="handleCheckOut"> -->
                        <form>
                            <div class="item">
                                <h3>帳號密碼</h3>
                                <BaseInput
                                    title="帳號"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control"
                                    name="account"
                                    rootClass="input-height"
                                    disabled
                                />
                                <!-- <BaseInput
                                    title="新密碼"
                                    placeholder="請輸入"
                                    type="password"
                                    class="form-control"
                                    name="new_password"
                                    rootClass="input-height"
                                /> -->
                                <!-- <BaseInput
                                    title="再次輸入密碼"
                                    placeholder="請輸入"
                                    type="password"
                                    class="form-control"
                                    name="again_password"
                                    rootClass="input-height"
                                /> -->
                            </div>
                            <div class="item">
                                <h3>基本資訊</h3>
                                <BaseInput
                                    title="芳療師姓名"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control"
                                    name="name"
                                    rootClass="input-height"
                                    disabled
                                />
                                <BaseInput
                                    title="到職日"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control"
                                    name="onboard_date"
                                    rootClass="input-height"
                                    disabled
                                />
                                <BaseInput
                                    title="調聘日期"
                                    placeholder="請輸入"
                                    type="text"
                                    class="form-control"
                                    name="hierarchy_upgrade_date"
                                    rootClass="input-height"
                                    disabled
                                />
                            </div>
                            <div class="item">
                                <h3>擅長技法</h3>
                                <div class="tab-wrap">
                                    <span class="tab" v-for="(item, index) in aromatherapistData.service_name" :key="index">#{{ item }}</span>
                                </div>
                            </div>
                            <!-- <div class="input-group">
                                <button class="btn" :disabled="isSubmitting">確定</button>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, reactive, computed} from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import { useRouter } from "vue-router";

import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2";

import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";

export default {
    name: "Info",
    components: {
        AromaLogo,
        AromaSideMenu,
    },
    setup() {
        const { state, dispatch, commit  } = useStore();
        const router = useRouter();


        const storeId = computed(() => state.auth.store_id);
        const memberId = computed(() => state.auth.member_id);


        // 芳療師基本資訊
        const aromatherapistData = JSON.parse(localStorage.getItem("memberData"));

        // form驗證設定
        const validationSchema = yup.object({
            account: yup.string().required("必填"),
            new_password: yup.string().required("必填"),
            again_password: yup.string().required("必填"),
            name: yup.string().required("必填"),
            onboard_date: yup.string().required("必填"),
            hierarchy_upgrade_date: yup.string().required("必填"),
        });
        const { values, handleSubmit, isSubmitting } = useForm({
            validateOnMount: false,
            validationSchema,
            initialValues: aromatherapistData,
        });
        const handleCheckOut = handleSubmit(async (values) => {
            const {
                new_password,
                again_password,
                name,
                hierarchy_upgrade_date,
                onboard_date
            } = values;
            try {
                if (new_password === again_password && new_password.toString().length > 6) {
                    const res = await basePost("/api_member/member_checked", {
                        store_id: storeId.value,
                        member_id: memberId.value,
                        password: new_password,
                        confirm_password: again_password,
                        name,
                        hierarchy_upgrade_date,
                        onboard_date,
                    });
                    const { status, data } = res;
                    Swal.fire({
                        title: "送出成功!",
                        icon: "success",
                        confirmButtonText: "確定",
                    }).then(() => {
                        router.push("/aroma_index");
                        // 取得芳療師資訊
                        dispatch("aromaInfoForm/getMemberInfo", {
                            store_id: storeId.value,
                            member_id: memberId.value
                        });
                    });
                } else if (new_password.toString().length < 6) {
                    Swal.fire({
                        title: "密碼最少要6位數",
                        icon: "error",
                        confirmButtonText: "確定",
                    })
                } else {
                    Swal.fire({
                        title: "密碼錯誤!",
                        icon: "error",
                        confirmButtonText: "確定",
                    })
                }

            } catch (err) {
                console.error(err);
            }

        });

        return { handleCheckOut, isSubmitting, aromatherapistData }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.index {
    .container {
        max-width: 1050px;
        margin: 0 auto;
        .info-wrap {
            background: transparent
                linear-gradient(135deg, #ffffff 0%, #e5e5e5 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            padding: 0 185px;
            padding-top: 40px;
            padding-bottom: 85px;
            @include pad {
                padding-right: 100px;
                padding-left: 100px;
            }
            @include phone {
                padding-right: 20px;
                padding-left: 20px;
            }
            .info-header {
                position: relative;
                display: flex;
                justify-content: center;
                &:after {
                    position: absolute;
                    content: "";
                    bottom: -30px;
                    right: -80px;
                    left: -80px;
                    border-bottom: 1px solid #ccc;
                    @include phone {
                        left: -20px;
                        right: -20px;
                    }
                }
                h2 {
                    font: normal normal bold 20px/27px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #a4641a;
                    @include line(-2px, -2px, -15px, "", 7px, #a4641a, block);
                    @include phone{
                        font-size: 18px;
                        @include line(-1px, -1px, -15px, "", 5px, #a4641a, block);
                    }
                }
            }
            .info-body {
                margin-top: 60px;
                form {
                    span {
                        font-size: 12px;
                        color: #f82e2e;
                        position: relative;
                        top: -10px;
                    }
                    .eyes{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        right: 22px;
                        top: 54%;
                        transform:translate(-50%,-50%);
                        z-index: 3;
                    }
                    .btn {
                        width: 100%;
                        border-radius: 11px;
                        padding: 15px;
                        height: 63px;
                        background: transparent
                            linear-gradient(
                                282deg,
                                #ebc496 0%,
                                #a4641a 36%,
                                #b57831 88%,
                                #a4641a 100%
                            );
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #ffffff85;
                        @include hover(140%);
                    }
                    .item {
                        margin-bottom: 35px;
                        h3 {
                            font: normal normal bold 16px/21px Microsoft
                                JhengHei;
                            letter-spacing: 0px;
                            color: #a4641a;
                            margin-bottom: 15px;
                        }
                        .tab-wrap {
                            box-shadow: -3px -4px 8px #ffffff9c,
                                0px 3px 6px #00000029;
                            width: 100%;
                            border: 1px solid #ffffff;
                            padding: 10px;
                            height: 160px;
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border-radius: 11px;
                            font: normal normal bold 16px/21px Microsoft
                                JhengHei;
                            letter-spacing: 0px;
                            color: #000000;
                            overflow-y: auto;
                            &::-webkit-scrollbar-track {
                                background-color: #d6d6d6;
                                border-radius: 2px;
                            }
                            &::-webkit-scrollbar {
                                width: 7px;
                            }
                            &::-webkit-scrollbar-thumb {
                                background: #707070;
                                box-shadow: 0px 3px 6px #00000029;
                                border-radius: 9px;
                            }
                            .tab{
                                display: inline-block;
                                background: #E1E1E1 0% 0% no-repeat padding-box;
                                border-radius: 8px;
                                font: normal normal bold 16px/21px Microsoft JhengHei;
                                letter-spacing: 0px;
                                color: #000000;
                                padding: 6px 14px;
                                top: 0;
                                margin: 5px;
                            }
                        }

                        .input-group {
                            box-shadow: -3px -4px 8px #ffffff9c,
                                0px 3px 6px #00000029;
                            border: 1px solid #ffffff;
                            border-radius: 11px;
                            height: 63px;
                            @include phone{
                                height: 53px;
                            }
                            .input-group-text {
                                background: transparent
                                    linear-gradient(
                                        180deg,
                                        #ececec 0%,
                                        #f9f9f9 35%,
                                        #ffffff 100%
                                    )
                                    0% 0% no-repeat padding-box;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 20px;
                                position: relative;
                                font-weight: bold;
                                @include phone{
                                    padding: 15px;
                                }
                                &:after {
                                    content: "";
                                    position: absolute;
                                    border-right: 1px solid #eee;
                                    top: 10px;
                                    bottom: 10px;
                                    right: 0;
                                    z-index: 1;
                                }
                            }
                            .form-control {
                                background: transparent
                                    linear-gradient(
                                        180deg,
                                        #ececec 0%,
                                        #f9f9f9 35%,
                                        #ffffff 100%
                                    )
                                    0% 0% no-repeat padding-box;
                                border: none;
                                border-radius: 0px 11px 11px 0px;
                                padding: 20px;
                                font: normal normal 600 16px/22px Open Sans;
                                @include phone{
                                    padding: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
