<template>
    <div class="base-counter" :class="counterColor">
        <button
            type="button"
            class="btn-controller"
            data-type="minus"
            @click="reduceCount"
        ></button>
        <div class="input-wrapper">
            <input type="number" :name="name" :value="inputValue" disabled />
            <div class="error-msg" v-show="errorMessage">
                {{ errorMessage }}
            </div>
        </div>
        <button
            type="button"
            class="btn-controller"
            data-type="increase"
            @click="addCount()"
        ></button>
    </div>
</template>

<script>
import { useField } from "vee-validate";
export default {
    name: "BaseCounter2",
    props: {
        value: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            required: true,
        },
        counterColor: {
            type: String,
            default: "",
        },
        maxAmount: {
            type: Number,
            default: "",
        },
    },
    setup(props) {
        const { value, name, maxAmount } = props;

        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
        } = useField(name, undefined, {
            initialValue: value,
        });

        // counter 最高數量
        const maxCount = maxAmount;

        const addCount = () => {
            if (inputValue.value < maxCount) {
                inputValue.value = inputValue.value + 1;
            }
        };

        const reduceCount = () => {
            if (inputValue.value) {
                inputValue.value = inputValue.value - 1;
            }
        };

        return {
            errorMessage,
            inputValue,
            addCount,
            reduceCount,
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";

.base-counter {
    display: flex;
    align-items: center;
    border-radius: 0 11px 11px 0;
    box-sizing: border-box;
    height: 100%;
}
.counter-bg {
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box !important;
}

.stock-counter {
    .btn-controller {
        &::before {
            background-color: #0f2a68;
        }
        &[data-type="increase"] {
            &::after {
                background-color: #0f2a68;
            }
        }
    }
    input {
        color: #0f2a68;
    }
}

.stockBox-counter {
    .btn-controller {
        &::before {
            background-color: #a4641a;
        }
        &[data-type="increase"] {
            &::after {
                background-color: #a4641a;
            }
        }
    }
    input {
        color: #a4641a;
    }
}

.btn-controller {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;

    &::before {
        content: "";
        display: inline-block;
        @include size(12px, 4px);
        background-color: rgba(217, 67, 67, 1);
    }

    &[data-type="increase"] {
        position: relative;

        &::after {
            content: "";
            @include size(4px, 12px);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(217, 67, 67, 1);
        }
    }
}

input {
    width: 50px;
    border: none;
    text-align: center;
    font-family: "Open Sans", "微軟正黑體", "Microsoft JhengHei", "黑體",
        "蘋果儷中黑", serif;
    font-size: 26px;
    font-weight: 600;
    background-color: transparent;
    color: rgba(217, 67, 67, 1);

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
</style>
