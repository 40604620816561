<template>
    <div class="customer">
        <div class="top">
            <Logo />
        </div>
        <div class="index-container">
            <div class="title">客戶管理</div>
        </div>

        <div class="wrap">
            <div class="input-group mb-3">
                <input
                    type="text"
                    class="form-control"
                    placeholder="輸入搜尋關鍵字"
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    v-model.trim="keyword"
                    @keyup.enter="searchKeyword(keyword)"
                />
                <div class="input-group-append">
                    <button
                        class="btn"
                        type="button"
                        @click="searchKeyword(keyword)"
                    >
                        <img src="@/assets/tgd/reserve/magnifier.svg" alt="" />
                    </button>
                </div>
            </div>

            <transition appear name="fade" v-if="isListRequest">
                <NoData v-show="!customerList.data.length" />
            </transition>

            <transition appear name="fade" v-if="isListRequest">
                <div
                    class="row reserve-container"
                    v-show="customerList.data.length"
                >
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="row order-list-header no-gutters">
                                <div class="col-1 text-center">序</div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -20px"
                                >
                                    會員名稱
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: 0px"
                                >
                                    會員電話
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: 20px"
                                >
                                    加入分店
                                </div>
                                <div
                                    class="col-2 position-relative flex-grow-1 ps-3 pe-1"
                                    style="left: 10px"
                                >
                                    會員備註
                                </div>
                                <div
                                    class="col-2 position-relative flex-grow-1 ps-3 pe-1"
                                >
                                    客戶來源
                                </div>
                                <div
                                    class="col-3 position-relative"
                                ></div>
                            </div>
                            <div
                                class="row order-list no-gutters"
                                v-for="(customer, index) in customerList.data"
                                :key="customer.id"
                            >
                                <div class="col-1 text-center num-font">
                                    {{ index + 1 }}
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -20px"
                                >
                                    {{ customer.name }}
                                </div>
                                <div
                                    class="
                                        col-1
                                        text-center
                                        position-relative
                                        num-font
                                    "
                                    style="left: 0px"
                                >
                                    {{ customer.phone }}
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: 20px"
                                >
                                    {{ customer.store_name }}
                                </div>
                                <div
                                    class="
                                        col-2
                                        warning-color
                                        position-relative
                                        flex-grow-1
                                        ps-3 
                                        pe-1
                                    "
                                    style="left: 10px"
                                >
                                    <img
                                        src="@/assets/tgd/reserve/warning.svg"
                                        alt=""
                                        v-if="customer.remark"
                                    />
                                    {{ customer.remark }}
                                </div>
                                <div
                                    class="
                                        col-2
                                        d-flex
                                        position-relative
                                        flex-grow-1
                                        ps-3
                                        pe-1
                                    "
                                >
                                    {{ customer.source }}
                                </div>
                                <div
                                    class="col-3 position-relative"
                                >
                                    <div class="btn-wrap">

                                        <button class="button-sms" @click="openSms(customer.id, customer.name,)">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/>
                                            </svg>
                                        </button>

                                        <router-link
                                            :to="{ name: 'ConsumptionRecord' }"
                                            class="btn btn-consume"
                                            @click="
                                                passId(
                                                    customer.id,
                                                    customer.name,
                                                    customer.phone,
                                                )
                                            "
                                            >消費紀錄</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'CouponRecord' }"
                                            class="btn btn-coupon"
                                            @click="
                                                passId(
                                                    customer.id,
                                                    customer.name,
                                                    customer.phone,
                                                )
                                            "
                                            >票劵紀錄</router-link
                                        >
                                        <router-link
                                            :to="{ name: 'MerchandiseRecord' }"
                                            class="btn btn-merchandise"
                                            @click="passId(customer.id)"
                                            >購買紀錄</router-link
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
        <div class="row">
            <div class="col-12" v-if="pageInfo.totalPage > 1">
                <BasePagination
                    :pageInfo="pageInfo"
                    @pageChange="updatePageData"
                />
            </div>
        </div>

        <SmsRightLightbox
            v-model:isShow="sms.isShow"
            :id="sms.id"
            :name="sms.name"
        />

    </div>
    <FixedOrderInfo/>
</template>

<script>
import Logo from "@/components/includes/Logo.vue";
import NoData from "@/components/includes/NoData.vue";
import SmsRightLightbox from '@/components/lightbox/SmsRightLightbox.vue';

import { ref, reactive, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "Customer",
    components: {
        Logo,
        NoData,
        SmsRightLightbox,
        FixedOrderInfo
    },
    setup() {
        const { state, commit } = useStore();
        const isListRequest = ref(false);
        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });

        // 客戶列表
        let customerList = reactive({ data: [] });
        let keyword = ref(JSON.parse(sessionStorage.getItem("keyword")));

        const searchKeyword = (keyword) => {
            commit("passIdToNextPage/update", {
                key: "keyword",
                value: keyword,
            });
            sessionStorage.setItem("keyword", JSON.stringify(keyword));
            getCustomerList(1);
        };

        //取得客戶列表
        const getCustomerList = async (num = 1) => {
            try {
                const res = await basePost("/api_customer/customer_list", {
                    page: num,
                    store_id: state.auth.store_id,
                    keyword: keyword.value,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    customerList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        if (keyword.value) {
            getCustomerList(1);
        }

        const updatePageData = (num) => {
            getCustomerList(num);
        };

        const passId = (customer_id, customer_name, customer_phone ) => {
            commit("passIdToNextPage/update", {
                key: "customerId",
                value: customer_id,
            });
            commit("passIdToNextPage/update", {
                key: "customerName",
                value: customer_name,
            });
            commit("passIdToNextPage/update", {
                key: "customerPhone",
                value: customer_phone,
            });
            localStorage.setItem("customerId", JSON.stringify(customer_id));
            localStorage.setItem("customerName", JSON.stringify(customer_name));
            localStorage.setItem("customerPhone", JSON.stringify(customer_phone));
        };

        watch(
            () => customerList.data.length,
            (list) => {
                if (!isListRequest.value) {
                    isListRequest.value = true;
                }
            }
        );

        onMounted(() => {
            if (keyword.value) {
                keyword.value = "";
                sessionStorage.setItem("keyword", JSON.stringify(keyword.value));
            }
            getCustomerList();
        });


        // 服務中待班中的編輯
        const sms = reactive({
            isShow: false,
            id: "",
            name: ""
        });
        
        const openSms = (id, name) =>{
            sms.isShow = true;
            sms.id = id;
            sms.name = name
        }

        watch(
            ()=> sms.isShow, 
            (val) => {
                if(!val) {
                    sms.id = "";
                    sms.name = "";
                }
            }
        )

        return {
            updatePageData,
            customerList,
            pageInfo,
            getCustomerList,
            keyword,
            isListRequest,
            passId,
            searchKeyword,
            openSms,
            sms
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.customer {
    .wrap {
        max-width: 1425px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @media (max-width: 1400px) {
            padding: 0;
        }
    }
    .input-group {
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #ffffff;
        border-radius: 11px;
        height: 53px;
        width: 300px;
        position: absolute;
        right: -5px;
        top: -67px;
        @media (max-width: 1400px) {
            right: 0;
        }
        .form-control {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border: none;
            border-radius: 11px 0px 0px 11px;
            padding: 0 15px;
            position: relative;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            letter-spacing: 0px;
            color: #000000;
            @include hover(90%);
        }
        .input-group-append {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            border-radius: 0 11px 11px 0;
            @include hover(130%);
            .btn {
                height: 100%;
                padding: 0 8px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .reserve-container {
        justify-content: center;
        @media (max-width: 1400px) {
            overflow: overlay;
            margin: 0;
        }

        .reserve-wrap {
            position: relative;
            width: 100%;
            padding: 0;
        }
        .order-list-wrap {
            margin-bottom: 15px;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            filter: contrast(100%);
            transition: all 0.3s;
            width: 1424px;
            &:hover {
                filter: contrast(105%);
            }
            @include pad_pro_lg {
                box-shadow: unset;
            }
        }
        .order-list-wrap {
            background: transparent
                linear-gradient(122deg, #ffffff 0%, #d9d9d9 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            padding: 70px 60px;
            padding-bottom: 45px;
            font: normal normal bold 16px/21px Microsoft JhengHei;
            @include pad_pro_lg {
                padding: 0 20px;
                padding-bottom: 40px;
                padding-top: 60px;
            }
            @include pad_pro {
                margin-bottom: 15px;
            }
            @include pad {
                font-size: 14px;
            }
            .order-list-header {
                position: relative;
                top: -30px;
                @include pad_pro {
                    top: -20px;
                }
            }
            .order-list {
                background: transparent
                    linear-gradient(
                        180deg,
                        #ececec 0%,
                        #f9f9f9 35%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                border: 1px solid #ffffff;
                border-radius: 11px;
                padding: 20px 0;
                align-items: center;
                margin-bottom: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                &:hover {
                    filter: contrast(90%);
                }
                .col-2 {
                    display: flex;
                    align-items: center;
                    img {
                        padding-right: 3px;
                    }
                }
                .warning-color {
                    font: normal normal bold 16px/21px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #d67675;
                }
                .btn-wrap {
                    display: flex;
                    align-items: center;
                    .btn {
                        width: 122px;
                        height: 42px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        filter: contrast(100%);
                        transition: all 0.3s;
                        border-radius: 21px;
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        color: #fff;
                        margin: 0 5px;
                        &:hover {
                            filter: contrast(140%);
                        }
                        @media (max-width: 1200px) {
                            font-size: 14px;
                            margin: 0 2px;
                        }
                    }
                    .btn-consume {
                        background: #4a4a4a 0% 0% no-repeat padding-box;
                    }
                    .btn-coupon {
                        background: #a2a2a2 0% 0% no-repeat padding-box;
                    }
                    .btn-merchandise {
                        background: #c05b1b 0% 0% no-repeat padding-box;
                    }
                    .button-sms {
                        background: #007cff;
                        border: none;
                        outline: none;
                        border-radius: 8px;
                        padding: 3px 5px;
                        display: flex;
                        align-items: center;
                        svg {
                            width: 20px;
                            fill: #fff;
                        }
                    }
                }
            }
        }
    }
}
</style>
