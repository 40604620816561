import { basePost } from "@/js/services/baseService";

export default function (obj) {
    // 取得折價券折抵金額
    const getDiscountPrice = async (discount_number) => {
        try {
            const res = await basePost("/api_discount/get_discount_price", {
                discount_number: discount_number,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                return Promise.resolve(data.res_data);
            }
            return Promise.reject(data);
        } catch (err) {
            console.error(err);
        }
    };

    return { getDiscountPrice };
}
