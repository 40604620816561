import Swal from "sweetalert2";

class SwalCustom {
    constructor(params) {
        const { title, text, icon, confirmButtonText } = params;
        this.title = title;
        this.text = text;
        this.icon = icon;
        this.confirmButtonText = confirmButtonText;
    }

    fire(configObj = {}) {
        const configuration = Object.assign(
            {
                title: this.title,
                text: this.text,
                icon: this.icon,
                confirmButtonText: this.confirmButtonText,
            },
            configObj
        );

        return Swal.fire(configuration);
    }
}

export default SwalCustom;
