<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeSideMenu"></div>
        <transition name="slide">
            <div class="side-menu-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <div class="side-menu">
                        <a
                            href="javascript:;"
                            class="menu-icon"
                            @click="closeSideMenu"
                        >
                        </a>
                        <div class="member-name">
                            <p>Hi, {{ aromatherapistInfo.nickname }}</p>
                        </div>
                        <!-- 桌機版 -->
                        <template class="d-block">
                            <router-link
                                to="/aroma_index"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>資訊總覽</span>
                            </router-link>
                            <router-link
                                to="/attendance_record"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>出勤紀錄</span>
                            </router-link>
                            <router-link
                                to="/aroma_payroll"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>工資單</span>
                            </router-link>
                            <router-link
                                to="/info"
                                class="menu-item"
                                @click="closeSideMenu"
                            >
                                <span>基本資訊</span>
                            </router-link>
                            <router-link
                                to="/login"
                                class="menu-item"
                                @click="closeSideMenu(), logOut()"
                            >
                                <span>登出</span>
                            </router-link>
                        </template>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import Swal from "sweetalert2";

export default {
    name: "AromaSideMenu",
    setup() {
        const { state } = useStore();
        const router = useRouter();
        const isShow = computed(() => state.global.isSideMenuShow);
        const { closeSideMenu } = useHandleLightboxOpen();

        // 芳療師基本資訊
        const aromatherapistInfo = computed(
            () => state.aromaInfoForm.aromatherapistInfo
        );

        //登出
        const logOut = () => {
            localStorage.removeItem("token");
            Swal.fire({
                title: "登出成功!",
                icon: "success",
                confirmButtonText: "確定",
            }).then(() => {
                router.push("/login");
                localStorage.removeItem("memberData");
            });
        };

        return { isShow, closeSideMenu, aromatherapistInfo, logOut };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-100%);
}

.side-menu-lightbox {
    position: fixed;
    left: 0;
    top: 0;
    width: 185px;
    min-height: 100%;
    z-index: 4;
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
                    background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            background: transparent
                linear-gradient(171deg, #e5e5e5 0%, #f3f3f3 13%, #e2e2e2 100%)
                0% 0% no-repeat padding-box;
            box-shadow: 36px 2px 77px #00000029;
            border: 2px solid #ffffff8c;
            position: absolute;
            z-index: 3;
            min-height: 100%;
            width: 100%;
            padding-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .menu-icon{
                background: no-repeat  url('~@/assets/tgd/lightbox/menu-open.png');
                width: 40px;
                height: 32px;
            }
            .member-name{
                padding-top: 35px;
                padding-bottom: 20px;
                p {
                    text-align: center;
                    letter-spacing: 0px;
                    color: #707070;
                    font: normal normal 600 19px/26px Open Sans, Microsoft JhengHei;
                }
            }
            .menu-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 92px;
                height: 88px;
                border-radius: 10px;
                margin-bottom: 5px;
                span {
                    text-align: center;
                    font: normal normal normal 12px/16px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #707070;
                    padding-top: 3px;
                }
                &:nth-child(1){
                    padding-top: 54px;
                    background: 50% 32% no-repeat url("~@/assets/tgd/aroma/info.svg");
                    border: 0.5px solid transparent;
                    transition: all .3s;
                    &:hover{
                        background: 50% 32% no-repeat url("~@/assets/tgd/aroma/info-white.svg"), transparent linear-gradient(140deg, #FFEF9C 0%, #F6EECB 14%, #AB7F29 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029, -4px -5px 18px #FFFFFFAD;
                        border: 0.5px solid #FFFFFF;

                        span{
                            color: #fff;
                        }
                    }
                }
                &:nth-child(2){
                    padding-top: 54px;
                    background: 50% 32% no-repeat url("~@/assets/tgd/aroma/record.svg");
                    border: 0.5px solid transparent;
                    transition: all .3s;
                    &:hover{
                        background: 50% 32% no-repeat url("~@/assets/tgd/aroma/record-white.svg"), transparent linear-gradient(140deg, #FFEF9C 0%, #F6EECB 14%, #AB7F29 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029, -4px -5px 18px #FFFFFFAD;
                        border: 0.5px solid #FFFFFF;

                        span{
                            color: #fff;
                        }
                    }
                }
                &:nth-child(3){
                    padding-top: 54px;
                    background: 50% 32% no-repeat url("~@/assets/tgd/aroma/bill.svg");
                    border: 0.5px solid transparent;
                    transition: all .3s;
                    &:hover{
                        background: 50% 32% no-repeat url("~@/assets/tgd/aroma/billw.svg"), transparent linear-gradient(140deg, #FFEF9C 0%, #F6EECB 14%, #AB7F29 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029, -4px -5px 18px #FFFFFFAD;
                        border: 0.5px solid #FFFFFF;

                        span{
                            color: #fff;
                        }
                    }
                }
                &:nth-child(4){
                    padding-top: 54px;
                    background: 50% 32% no-repeat url("~@/assets/tgd/aroma/user.svg");
                    border: 0.5px solid transparent;
                    transition: all .3s;
                    &:hover{
                        background: 50% 32% no-repeat url("~@/assets/tgd/aroma/user-white.svg"), transparent linear-gradient(140deg, #FFEF9C 0%, #F6EECB 14%, #AB7F29 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029, -4px -5px 18px #FFFFFFAD;
                        border: 0.5px solid #FFFFFF;

                        span{
                            color: #fff;
                        }
                    }
                }
                &:nth-child(5){
                    padding-top: 54px;
                    background: 50% 32% no-repeat url("~@/assets/tgd/lightbox/sign-out.svg");
                    border: 0.5px solid transparent;
                    transition: all .3s;
                    &:hover{
                        background: 50% 32% no-repeat url("~@/assets/tgd/lightbox/sign-out-white.svg"), transparent linear-gradient(140deg, #FFEF9C 0%, #F6EECB 14%, #AB7F29 100%) 0% 0% no-repeat padding-box;
                        box-shadow: inset 0px 3px 6px #00000029, -4px -5px 18px #FFFFFFAD;
                        border: 0.5px solid #FFFFFF;

                        span{
                            color: #fff;
                        }
                    }
                }
            }
            .menu-icon {
                @include hover(150%);
            }
            ul{
                li{
                    padding-top: 33px;
                    a{
                        display: flex;
                        align-items: center;
                        color: #fff;
                        font-size: 16px;
                        img{
                            width: 30px;
                        }
                        span{
                            padding-left: 30px;
                        }
                    }
                }
            }
        }
    }
}
</style>
