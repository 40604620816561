import { basePost } from '@/js/services/baseService';

function useAromaList(store_id, type) {
    const getGoodAtService = async (service_id, order_id) => {
        try {
            const res = await basePost('/api_member/get_good_at_service_member', {
                store_id: store_id,
                service_id: JSON.stringify(service_id),
                type,
                order_id
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                return Promise.resolve(data.res_data);
            }
            return Promise.reject(data);
        } catch (err) {
            console.error(err);
        }
    };

    function mixService(service, external) {
        let serviceContent = [...(service || [])];
        if (!external) return serviceContent;
        const externalContent = [...external]
            .map((item) => {
                return item.list.map((val) => ({
                    service_id: val.service_id,
                    type: 3,
                    txt: item.external_number,
                    external_id: item.external_id,
                    is_old: val.is_old,
                }));
            })
            .reduce((prev, curr) => {
                return [...prev, ...curr];
            }, []);
        return [...serviceContent, ...externalContent];
    }

    function mixRecordService(service, external) {
        let serviceContent = [...(service || [])];
        if (!external) return serviceContent;
        const externalContent = [...external]
            .map((item) => {
                return item.list.map((val) => ({
                    service_id: val.service_id,
                    type: 3,
                    txt: item.external_number,
                    external_id: item.external_id,
                    is_old: val.is_old,
                    order_details_id: val.order_details_id
                }));
            })
            .reduce((prev, curr) => {
                return [...prev, ...curr];
            }, []);
        return [...serviceContent, ...externalContent];
    }

    return { mixService, getGoodAtService, mixRecordService };
}

export default useAromaList;
