<template>
    <div class="row mb-3">
        <div class="col-5">
            <div class="input-group">
                <div class="input-group-prepend">
                    <span class="input-group-text">票劵 {{ index }}</span>
                </div>
                <input
                    :placeholder="placeholder"
                    type="text"
                    :style="{
                        'border-radius': couponNum > 1 ? '0 !important' : '',
                    }"
                    class="form-control coupon-text text-start"
                    :name="rangeName"
                    @input="handleChangeRange"
                    :value="startRange"
                    index="index"
                    disabled
                />
                <span v-if="couponNum > 1">-</span>
                <input
                    type="text"
                    class="form-control coupon-text gray-text"
                    v-if="couponNum > 1"
                    :value="endRangeValue"
                />
            </div>
        </div>
        <div class="col-7" style="max-width: 80% !important">
            <div class="input-group">
                <div class="input-group-prepend counter">
                    <span class="input-group-text py-0">
                        <a href="javascript:;" @click="reduceNum()">-</a>
                        <input
                            type="text"
                            class="form-control text-center"
                            readonly
                            :name="couponName"
                            @input="handleChangeCoupon"
                            :value="couponNum"
                        />
                        <a href="javascript:;" @click="addNum()">+</a>
                    </span>
                </div>
                <input
                    type="text"
                    class="form-control money-text"
                    readonly
                    :value="price"
                />
            </div>
        </div>
    </div>
    <div class="error-msg" v-show="rangeErrorMessage">
        {{ rangeErrorMessage }}
    </div>
</template>

<script>
import { ref, reactive, computed } from "vue";
import { useStore } from "vuex";
import { useField } from "vee-validate";
import { basePost } from "@/js/services/baseService";

export default {
    name: "BaseRadio",
    props: {
        rangeName: {
            type: String,
            default: "",
        },
        couponName: {
            type: String,
            default: "",
        },
        index: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: "",
        },
        couponPrice: {
            type: String,
            default: "",
        },
    },
    setup(props) {
        const { state } = useStore();
        const { rangeName, couponName, couponPrice } = props;
        // validate
        const {
            errorMessage: rangeErrorMessage,
            value: range,
            handleChange: handleChangeRange,
        } = useField(rangeName, undefined);

        const {
            errorMessage: couponErrorMessage,
            value: coupon,
            handleChange: handleChangeCoupon,
        } = useField(couponName, undefined);

        let startRange = ref(1);
        let endRange = ref(2);
        let endRangeValue = reactive([]);
        let couponNum = ref(startRange.value ? 1 : 0);
        let couponVerify = reactive({ data: {} });

        let price = computed(() => {
            return "$" + couponPrice * couponNum.value;
        });

        //驗證區間內所有劵號
        const getCouponVerify = async (couponNum) => {
            // try {
            //     const res = await basePost("/api_ticket/useTicketVerify", {
            //         ticket_id: 1,
            //         start_num: startRange.value,
            //         amount: couponNum,
            //         store_id: 1,
            //     });
            //     const { status, data } = res;
            //     couponVerify.data = res.data.res_data;
            //     console.log("couponVerify:", couponVerify.data);
            // } catch (err) {
            //     console.error(err);
            // }
        };

        const reduceNum = () => {
            if (startRange.value) {
                if (couponNum.value > 1) {
                    couponNum.value = couponNum.value - 1;
                    endRange.value = endRange.value - 1;
                }
            } else {
                if (couponNum.value > 0) {
                    couponNum.value = couponNum.value - 1;
                }
                if (couponNum.value >= 1) {
                    endRange.value = endRange.value - 1;
                }
            }
            getCouponVerify(couponNum.value);
        };
        const addNum = () => {
            if (couponNum.value < 10) {
                couponNum.value = couponNum.value + 1;
            }
            if (couponNum.value > 1 && endRange.value < 9) {
                endRangeValue = startRange.value.split(3);
                endRangeValue = endRangeValue + 1;
            }
            getCouponVerify(couponNum.value);
        };

        return {
            rangeErrorMessage,
            couponErrorMessage,
            range,
            coupon,
            handleChangeRange,
            handleChangeCoupon,
            reduceNum,
            addNum,
            couponNum,
            startRange,
            endRange,
            couponVerify,
            price,
        };
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/grid";
@import "~@/css/mixins";
$side-menu-width: 640px;
$tab-width: 120px;
$tab-menu-width: 895px;

.coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0px;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 3;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;

            .col-12 {
                padding: 0;
            }
        }
        .side-menu-top {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            .side-menu-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                }
                label {
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"] + span {
                        display: inline-block;
                        padding-left: 35px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/check.svg")
                            no-repeat;
                        user-select: none;
                        font: normal normal 600 20px/27px Open Sans;
                        letter-spacing: 0px;
                        color: #986d46;
                    }
                    input[type="checkbox"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/checked.svg")
                            no-repeat;
                    }
                }
                .col-5 {
                    flex: 0 0 45%;
                    max-width: 45%;
                }
                .col-7 {
                    flex: 0 0 55%;
                    max-width: 55%;
                }
                .col-5 {
                    padding-right: 0;
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        padding: 0;
                    }
                }
                .col-7 {
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        padding: 0;
                    }
                }
                .col-7 {
                    position: relative;
                    .cancel-img {
                        position: absolute;
                        right: -31px;
                        top: 50%;
                        transform: translate(0%, -31%);
                        img {
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        align-items: center;
                        font: normal normal bold 20px/22px Open Sans;
                        letter-spacing: 0px;
                        color: #a4641a;
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        .counter {
                            flex: 20%;
                            @include hover(90%);
                            .input-group-text {
                                font: normal normal bold 22px/30px Open Sans;
                                letter-spacing: 0px;
                                color: #a4641a;
                                a {
                                    font: normal normal bold 24px/30px Open Sans;
                                    letter-spacing: 0px;
                                    color: #a4641a;
                                    @include hover(200%);
                                }
                            }
                            input {
                                border-radius: 0 !important;
                                padding: 15px;
                                margin: 0 8px;
                            }
                        }
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px 0px 0px 11px;
                            padding: 13px;
                            position: relative;
                            font-weight: bold;
                            width: 100%;
                            justify-content: space-between;
                            &:after {
                                content: "";
                                position: absolute;
                                border-right: 1px solid #eee;
                                top: 10px;
                                bottom: 10px;
                                right: 0;
                                z-index: 1;
                            }
                        }

                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 0px 11px 11px 0px;
                            padding: 13px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        .money-text {
                            text-align: right;
                            font: normal normal bold 19px/26px Open Sans;
                            letter-spacing: 0px;
                            color: #d94343;
                        }
                        .coupon-text {
                            color: #a4641a;
                            @include phone {
                                text-align: center;
                            }
                        }
                        .gray-text {
                            color: #707070;
                            @include phone {
                                text-align: center;
                            }
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 100.5%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .btn {
                        width: 223px;
                        height: 53px;

                        border-radius: 11px;
                        background: transparent
                            linear-gradient(
                                282deg,
                                #ebc496 0%,
                                #a4641a 36%,
                                #b57831 88%,
                                #a4641a 100%
                            );
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid #ffffff85;
                        @include phone {
                            width: 136px;
                            height: 44px;
                        }
                        span {
                            font: normal normal bold 16px/19px Microsoft
                                JhengHei;
                            letter-spacing: 0px;
                            color: #ffffff;
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }
}
</style>
