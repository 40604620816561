<template>
    <div class="post">
        <AromaSideMenu />
        <div class="top">
            <AromaLogo />
        </div>
        <div class="index-container">
            <div class="title">公告</div>
        </div>
        <transition appear name="fade" v-if="isListRequest">
            <NoData v-show="!announcementList.data.length" />
        </transition>
        <transition appear name="fade" v-if="isListRequest">
            <div v-show="announcementList.data.length">
                <div class="row post-container">
                    <div class="col-12">
                        <div class="post-wrap">
                            <ul class="post-list">
                                <li
                                    class="post-list-item"
                                    v-for="(item, index) in announcementList.data"
                                    :key="index"
                                >
                                    <a href="javascript:;">
                                        <p class="post-title">
                                            <div class="left">{{ item.title }}</div>
                                            <div class="right num-font">
                                                {{ item.create_time }}
                                            </div>
                                        </p>
                                        <div class="post-body num-font" v-html="item.content"></div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row position-relative">
                    <div class="col-12" v-if="pageInfo.totalPage > 1">
                        <BasePagination
                            :pageInfo="pageInfo"
                            @pageChange="updatePageData"
                        />
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { ref, reactive, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";
import NoData from "@/components/includes/NoData.vue";

export default {
    name: "Post",
    components: {
        AromaLogo,
        AromaSideMenu,
        NoData
    },
    setup() {
        const { state } = useStore();
        const isListRequest = ref(false);
        let pageInfo = reactive({
            totalPage: 0,
            perPage: 5,
        });
        let announcementList = reactive({ data: [] });

        // 取得公告清單
        const getPost = async (num = 1) => {
            try {
                const res = await basePost("/api_common/announcement_list", {
                    store_id: state.auth.store_id,
                    page: num,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    announcementList.data = res_data.list;
                    pageInfo.totalPage = res_data.max_page;
                }
            } catch (err) {
                console.error(err);
            }
        };

        const updatePageData = (num) => {
            getPost(num);
        };

        getPost();

        watch(announcementList, () => {
            if (!isListRequest.value) {
                isListRequest.value = true;
            }
        });

        return {
            updatePageData,
            announcementList,
            pageInfo,
            isListRequest
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.post {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
        }
    }
    .post-container {
        overflow: overlay;
        padding-bottom: 102px;
        @include pad_pro_lg{
            padding: 0;
        }
        .post-wrap {
            margin: 0 auto;
            width: 1425px;
            background: transparent
                linear-gradient(121deg, #ffffff 0%, #e5e5e5 18%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            box-shadow: 0px 34px 77px #00000042;
            border: 1px solid #ffffff;
            border-radius: 15px;
            padding: 70px;
            @include pad_pro_lg{
                padding: 50px 25px;
                box-shadow: unset;
            }
            @include phone {
                font-size: 14px;
            }
            .post-list {
                height: 700px;
                overflow-y: auto;
                padding-right: 80px;
                &::-webkit-scrollbar-track {
                    background-color: #d6d6d6;
                    border-radius: 2px;
                }
                &::-webkit-scrollbar {
                    width: 7px;
                }
                &::-webkit-scrollbar-thumb {
                    background: #707070;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 9px;
                }
                .post-list-item {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    padding: 15px 50px;
                    border-radius: 10px;
                    height: 105px;
                    margin: 15px;
                    margin-top: 0;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(90%);
                    }
                    .post-title {
                        font: normal normal bold 16px/21px Microsoft JhengHei;
                        letter-spacing: 0.8px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .left {
                            color: #b77f1a;
                        }
                        .right {
                            color: rgba(0, 0, 0, 0.5);
                        }
                    }
                    .post-body {
                        font: normal normal normal 16px/21px Microsoft JhengHei;
                        letter-spacing: 0.8px;
                        color: #000000;
                        padding-top: 10px;
                        height: 32px;
                        @include text-ellipsis;
                    }
                }
            }
        }
    }
    .pagination{
        position: relative;
        top: -80px;
        @include pad_air_lg {
            top: 0;
        }
    }
}
</style>
