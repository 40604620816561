<template>
    <div class="left">
        <div class="menu">
            <a href="javascript:;" @click="openSideMenu">
                <img src="@/assets/tgd/index/menu.svg" alt=""  class="pc"/>
                <img src="@/assets/tgd/index/menu2.svg" alt=""  class="mobile"/>
            </a>
        </div>
        <div class="title">
            <div class="logo">
                <router-link to="/">
                    <img src="@/assets/tgd/index/logo.png" alt="" />
                </router-link>
            </div>
            <span>泰晶殿 {{ storeName }}</span>
        </div>
    </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";

export default {
    name: "AromaLogo",
    setup() {
        const { state } = useStore();
        const { openSideMenu } = useHandleLightboxOpen();

         const storeName = computed(() => state.auth.store_name);

        return { openSideMenu, storeName };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.pc{
    display: block !important;
}

.mobile{
    display: none;
}

@media screen and (max-width: 610px) {
    .pc{
        display: none !important;
    }
    .mobile{
        display: block;
    }
}

.left {
    display: flex;
    align-items: center;
    @include phone {
        padding: 0 20px;
        padding-top: 20px;
    }
    .menu {
        margin-right: 25px;
        img {
            width: 40px;
            @include phone {
                width: 32px;
            }
        }
    }
    .title {
        display: flex;
        align-items: center;
        .logo {
            @include phone {
                display: none;
            }
        }
        span {
            margin-left: 20px;
            font-weight: bold;
            font-size: 20px;
            color: #fff;
            @include pad_pro{
                color: #A4641A;
            }

            @include phone {
                margin: 0;
                font-size: 14px;
            }
        }
    }
}
</style>
