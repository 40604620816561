<template>
    <div class="base-counter" :class="counterColor">
        <button
            type="button"
            class="btn-controller"
            data-type="minus"
            @click="reduceCount"
        ></button>
        <div class="input-wrapper">
            <input
                ref="refInput"
                type="number"
                :name="name"
                :value="thisModelValue"
            />
            <div class="error-msg" v-show="errorMessage">
                {{ errorMessage }}
            </div>
        </div>
        <button
            type="button"
            class="btn-controller"
            data-type="increase"
            @click="addCount()"
        ></button>
    </div>
</template>

<script>
import { useField } from "vee-validate";
import { ref, reactive, computed, watch, onMounted } from "vue";
export default {
    name: "BaseCounter",
    emits: ["update:modelValue"],
    props: {
        modelValue:{
            type: Number,
            default: 0
        },
        value: {
            type: Number,
            default: 0,
        },
        name: {
            type: String,
            required: true,
        },
        counterColor: {
            type: String,
            default: "",
        },
        maxAmount: {
            type: Number,
            default: "",
        },
        minAmount: {
            type: Number,
            default: 0,
        },
    },
    setup(props, context) {
        const { emit } = context;

        const { value, name, maxAmount, minAmount } = props;

        const refInput = ref(null);

        const thisModelValue = computed(()=> props.modelValue);

        // 是否檢查上限
        const shouldCheckMax = computed(()=> {
            var result = false;
            if ( !isNaN(parseFloat(maxAmount)) ) {
                result = true
            }
            return result
        })

        // 是否檢查下限
        const shouldCheckMin = computed(()=> {
            var result = false;
            if ( !isNaN(parseFloat(minAmount)) ) {
                result = true
            }
            return result
        })

        const {
            value: inputValue,
            errorMessage,
            handleBlur,
            handleChange,
        } = useField(name, undefined, {
            initialValue: value,
        });

        // counter 最高數量
        const maxCount = maxAmount;

        const addCount = () => {
            var newValue = thisModelValue.value;

            if ( (newValue < maxCount) || !shouldCheckMax.value) {
                newValue = newValue + 1;
            }

            emit("update:modelValue", newValue);
        };

        const reduceCount = () => {
            var newValue = thisModelValue.value;

            if ( (newValue > minAmount) || !shouldCheckMin.value ) {
                newValue = newValue - 1;
            }
            emit("update:modelValue", newValue);
        };

        function emitVal(e) {
            var newValue = thisModelValue.value;

            if (newValue > maxCount) {
                newValue = maxCount;
            }

            if (newValue < 0) {
                newValue = 0;
            }
                // console.log('emit', newValue)

            emit("update:modelValue", newValue);
        }

        // 處理直接輸入狀況
        function keyBoardTypeHandle(){

            refInput.value.addEventListener('keypress', (e)=>{
                var charCode = (e.which) ? e.which : e.keyCode;

                // 只限數字
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                    return false;
                }

            });

            refInput.value.addEventListener('keyup', (e)=>{

                var newVal = parseInt(refInput.value.value)

                if ( isNaN(newVal) ) {
                    newVal = 0
                }

                if ( newVal !== '') {
                    
                    if ( shouldCheckMin.value ) {
                        if ( newVal < props.minAmount ) {
                            newVal = props.minAmount
                            // 因 model 紀錄仍為舊值
                            // 故以直接訂方式更新 dom attribue
                            refInput.value.value = newVal
                        }
                    }

                    if ( shouldCheckMax.value ) {
                        if ( newVal > props.maxAmount ) {
                            newVal = props.maxAmount;
                            // 因 model 紀錄仍為舊值
                            // 故以直接訂方式更新 dom attribue
                            refInput.value.value = newVal
                        }
                    }
                }

                context.emit('update:modelValue', newVal)
            })
        }

        onMounted(()=>{
            keyBoardTypeHandle()
        })

        return {
            emitVal,
            refInput,
            errorMessage,
            inputValue,
            addCount,
            reduceCount,
            thisModelValue
        };
    },
};
</script>

<style scoped lang="scss">
@import "~@/css/mixins";

.base-counter {
    display: flex;
    align-items: center;
    border-radius: 0 11px 11px 0;
    box-sizing: border-box;
    height: 100%;
}
.counter-bg {
    background: transparent
        linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%) 0% 0%
        no-repeat padding-box !important;
}

.stock-counter {
    .btn-controller {
        &::before {
            background-color: #0f2a68;
        }
        &[data-type="increase"] {
            &::after {
                background-color: #0f2a68;
            }
        }
    }
    input {
        color: #0f2a68;
    }
}

.stockBox-counter {
    .btn-controller {
        &::before {
            background-color: #a4641a;
        }
        &[data-type="increase"] {
            &::after {
                background-color: #a4641a;
            }
        }
    }
    input {
        color: #a4641a;
    }
}

.btn-controller {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;

    &::before {
        content: "";
        display: inline-block;
        @include size(12px, 4px);
        background-color: rgba(217, 67, 67, 1);
    }

    &[data-type="increase"] {
        position: relative;

        &::after {
            content: "";
            @include size(4px, 12px);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(217, 67, 67, 1);
        }
    }
}

input {
    width: 50px;
    border: none;
    text-align: center;
    font-family: "Open Sans", "微軟正黑體", "Microsoft JhengHei", "黑體",
        "蘋果儷中黑", serif;
    font-size: 26px;
    font-weight: 600;
    background-color: transparent;
    color: rgba(217, 67, 67, 1);

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}
</style>
