import { ref, onUnmounted } from 'vue';
import { debounce } from 'lodash';

function useBarcodeScanner() {
    const isBarcodeScanner = ref(false);
    let lastTime = null;
    let nextTime = null;
    let accumulation = 0;

    const barcodeScanner = () => {
        nextTime = new Date();
        if (lastTime && nextTime - lastTime < 100) {
            accumulation += 1;
        } else {
            accumulation = 0;
        }
        lastTime = nextTime;
        debounced();
    };
    const debounced = debounce(function () {
        if (accumulation > 4) {
            isBarcodeScanner.value = true;
        } else {
            isBarcodeScanner.value = false;
        }
    }, 200);

    window.addEventListener('keydown', barcodeScanner);
    onUnmounted(() => {
        window.removeEventListener('keydown', barcodeScanner);
    });

    const resetBarcodeScanner = () => {
        isBarcodeScanner.value = false;
        accumulation = 0;
    };

    return {
        isBarcodeScanner,
        resetBarcodeScanner,
    };
}

export default useBarcodeScanner;
