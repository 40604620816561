import { basePost } from "@/js/services/baseService";
import moment from 'moment';

export default {
    namespaced: true,
    state: {
        // 訂單服務資訊
        list: [],
        // 訂單id
        id: 0,
    },
    getters: {},
    mutations: {
        update(state, params) {
            const { key, value } = params;
            state[key] = value;
        },
        delListByIdx(state, index) {
            state.list.answer_list.demand.splice(index, 1);
        },
    },
    actions: {
        // 取得本日預約服務清單
        async getServiceList({ commit }, params) {
            try {
                const { store_id, reserve_id, time } = params;

                commit("update", {
                    key: "id",
                    value: reserve_id,
                });

                const res = await basePost("/api_reserve/reserve_details", {
                    store_id,
                    reserve_id,
                    date: moment().format("YYYY-MM-DD"),
                    time,
                    type: 'start_service'
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    commit("update", {
                        key: "list",
                        value: data.res_data,
                    });
                    // console.log("serviceListData: ", data.res_data);
                }
            } catch (err) {
                console.error(err);
            }
        },
    },
};
