<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeLightbox"></div>
        <form class="sign-lightbox" v-show="isShow">
            <button type="button" class="btn-close" @click="closeLightbox"></button>
            <div class="lightbox-header">
                <div class="title">員工簽名</div>
            </div>
            <div class="lightbox-body">
                <div class="content">
                    <div class="box"> 
                        <vue-esign 
                            ref="esign" 
                            :width="760" 
                            :height="290" 
                            :isCrop="isCrop"
                            :lineWidth="lineWidth" 
                            :lineColor="lineColor" 
                            v-model:bgColor="bgColor" 
                            class="vue-esign" 
                        />
                    </div>
                </div>
            </div>
            <div class="lightbox-footer">
                <div class="content">
                    <button type="button" class="btn reset-btn" @click="handleReset">重簽</button>
                    <button type="button" class="btn  check-btn" @click="handleSave">確認</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { ErrorMessage } from "vee-validate";
import vueEsign from 'vue-esign'


export default {
    name: "SignLightbox",
    components: {
        ErrorMessage,
        vueEsign
    },
    props: {
        isShow: Boolean,
    },
    emits: ["update:isShow", 'save'],
    setup(props, { emit }) {
        const isShow = computed(() => props.isShow);

        const esign = ref(null);
        const lineWidth = ref(6)

        const lineColor = ref('#000000')

        const bgColor = ref('#fff')

        const resultImg = ref('')
        const isCrop = ref(false)


        function closeLightbox() {
            emit("update:isShow", false)
        }

        function handleSave() {
            esign.value.generate().then(res => {
                resultImg.value = res
                const file = dataURLtoFile(res, 'sign.png')
                emit('save', file)
            }).catch(err => {
                alert(err) // 画布没有签字时会执行这里 'Not Signned'
            })
        }

        // base64轉file
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            // 轉換成file物件
            return new File([u8arr], filename, {
                type: mime
            });
        }

        const handleReset = () => {
            esign.value.reset()
        }
        
        return {
            closeLightbox,
            handleSave,
            isShow,
            esign, lineWidth, lineColor, bgColor, resultImg, isCrop, handleReset
        };
    },
};
</script>


<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";

.sign-lightbox {
    width: 100%;
    max-width: clamp(320px, 50vw, 900px);
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff
}

.btn-close {
    @include size(28px);
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 1);
    background-image: unset;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    opacity: 1;
    box-sizing: border-box;

    &::before,
    &::after {
        content: "";
        display: inline-block;
        @include size(14px, 2px);
        background-color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
    }

    &::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

.lightbox-header {
    .title {
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        padding: 40px 0 20px;
        border-bottom: 1px solid rgba(112, 112, 112, .2);
    }
}

.lightbox-body {
    padding: 40px 0 20px;

    .content {
        p {
            color: #A4641A;
            font-size: 21px;
            font-weight: bold;
        }
    }
}

.content {
    // width: clamp(280px,26vw,400px);
    width: 100%;
    padding-left: clamp(20px, 8vw, 70px);
    padding-right: clamp(20px, 8vw, 70px);
    margin: 0 auto;

}

.lightbox-footer {
    padding: 10px 0 31px 0;
    transition: all 0.3s ease;
    text-align: end;
}

.btn {
    display: inline-block;
    border-radius: 11px;
    padding: clamp(12px, 1.5vw, 24px);
    text-align: center;
    color: #fff;

    @media (max-width:640px) {
        font-size: 16px;
    }

    &:hover {
        box-shadow: 4px 9px 12px #00000050;
        color: #fff;
    }

    +.btn {
        margin-left: 10px;
    }
}

.reset-btn {
    width: clamp(80px, 8vw, 130px);
    background-color: #A2A2A2;
}

.check-btn {
    width: clamp(120px, 10vw, 170px);
    background: transparent linear-gradient(100deg, #EBC496 0%, #A4641A 36%, #B57831 88%, #A4641A 100%) 0% 0% no-repeat padding-box;
}


// 簽名板

.sign-lightbox {
    background-color: #E5E5E5;
}

.vue-esign {
    background-color: #fff !important;
    border-radius: 5px !important;
}
</style>
