import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import { covertComp2GlobalUse } from "./js/functions";
import Router from "./js/routes";
//style
import BootstrapVue3 from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "@/css/app.scss";
// datetimepicker
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
// v-calendar
import VCalendar from "v-calendar";
import "@/js/yupCustomDefinition";
import i18n from './i18n'

const app = createApp(App);
app.use(i18n);
app.use(Router);
app.use(store);
app.use(BootstrapVue3);
app.use(VCalendar, {});
app.component("Datepicker", Datepicker);

covertComp2GlobalUse(
    require.context("./components/", true, /Base[A-Z]\w+\.(vue|js)$/),
    app
);

app.mount("#app");
