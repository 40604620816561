<template>
    <div class="clock-in">
        <div class="top">
            <Logo />
        </div>

        <div class="index-container">
            <div class="title">打卡紀錄</div>
        </div>
        <div class="wrap">
            <div class="input-group">
                <BaseDateSelect
                    name="year"
                    selectStyle="width: 40%"
                    selectClass="radius-left"
                    @selectChange="handleYearChange"
                    @init="setSelectY"
                    :options="dateYearAry"
                />
                <BaseDateSelect
                    name="month"
                    selectStyle="width: 25%"
                    @selectChange="handleMonthChange"
                    :options="dateMonthAry"
                    @init="setSelectM"
                />
                <BaseDateSelect
                    name="day"
                    selectStyle="width: 35%"
                    selectClass="radius-right"
                    @selectChange="handleDayChange"
                    :options="dateDayAry"
                    @init="setSelectD"
                />
            </div>
            <transition appear name="fade" v-if="isListRequest">
                <NoData v-show="!rosterRecord.data.length" />
            </transition>
            <transition appear name="fade" v-if="isListRequest">
                <div
                    class="row reserve-container"
                    v-show="rosterRecord.data.length"
                >
                    <div class="reserve-wrap">
                        <div class="order-list-wrap">
                            <div class="row order-list-header">
                                <div class="col-1 text-center">序</div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -60px"
                                >
                                    員工
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -60px"
                                >
                                    班別
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -30px"
                                >
                                    上班時間
                                </div>
                                <div class="col-1 text-center"></div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -30px"
                                >
                                    上班
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -20px"
                                >
                                    簽到
                                </div>
                                <div
                                    class="col-1 text-center position-relative"
                                    style="left: -10px"
                                >
                                    下班
                                </div>
                                <div class="col-1 text-center">加班</div>
                            </div>
                            <div
                                class="row order-list num-font"
                                v-for="(item, index) in rosterRecord.data"
                                :key="index"
                            >
                                <div class="col-1 text-center">
                                    {{ index + 1 }}
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -60px"
                                >
                                    {{ item.member_name }}
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    :class="[
                                        {
                                            'morning-color':
                                                item.class_num == '早',
                                        },
                                        {
                                            'evening-color':
                                                item.class_num == '午',
                                        },
                                        {
                                            'night-color':
                                                item.class_num == '晚',
                                        },
                                    ]"
                                    style="left: -60px"
                                >
                                    {{ item.class_num }}
                                </div>
                                <div
                                    class="col-2 text-center position-relative"
                                    style="left: -30px"
                                >
                                    {{ item.work_time ? item.work_time : '-' }}
                                </div>
                                <div class="col-1 text-center"></div>
                                <div
                                    class="
                                        col-1
                                        text-center
                                        position-relative
                                    "
                                    :class="[
                                        {
                                            'gold': isOnEntryTime(item.work_time, item.entry_time),
                                        },
                                        {
                                            'no-on-time-color': !isOnEntryTime(item.work_time, item.entry_time),
                                        },
                                    ]"
                                    style="left: -30px"
                                >
                                    {{
                                        item.entry_time ? item.entry_time : "-"
                                    }}
                                </div>
                                <div
                                    class="
                                        col-1
                                        text-center
                                        position-relative
                                        gray
                                    "
                                    style="left: -20px"
                                >
                                    {{ item.sign_time ? item.sign_time : "-" }}
                                </div>
                                <div
                                    class="
                                        col-1
                                        text-center
                                        position-relative
                                    "
                                    :class="[
                                        {
                                            'night-color': isOnOutTime(item.work_time, item.entry_time),
                                        },
                                        {
                                            'no-on-time-color': !isOnOutTime(item.work_time, item.entry_time),
                                        },
                                    ]"
                                    style="left: -10px"
                                >
                                    {{ item.out_time ? item.out_time : "-" }}
                                </div>
                                <div class="col-1 text-center gray">
                                    {{ item.overtime ? item.overtime : "-" }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
    <FixedOrderInfo/>
</template>

<script>
import useHandleLightboxOpen from "@/hooks/common/useHandleLightboxOpen";
import { ref, reactive, computed, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";

import Logo from "@/components/includes/Logo.vue";
import TopTab from "@/components/includes/TopTab.vue";
import NoData from "@/components/includes/NoData.vue";
import moment from 'moment';
import FixedOrderInfo from '@/components/inline/FixedOrderInfo';

export default {
    name: "Reservation",
    components: {
        Logo,
        TopTab,
        NoData,
        FixedOrderInfo
    },
    setup() {
        const { state } = useStore();

        const isListRequest = ref(false);

        // 打卡紀錄
        let rosterRecord = reactive({ data: [] });

        // today
        var today = new Date();
        var todayY = today.getFullYear();
        var todayM = today.getMonth() + 1;
        var todayD = today.getDate();

        // 選中的日期
        const dateSelect = reactive({
            year: todayY,
            month: todayM,
            day: todayD,
            date: computed(() => {
                return `${dateSelect.year}-${
                    Number(dateSelect.month) < 10 ? '0' + dateSelect.month : dateSelect.month
                }-${Number(dateSelect.day) < 10 ? '0' + dateSelect.day : dateSelect.day}`;
            }),
        })
        // 日期數
        let dateDayNum = '';

        // 年份
        let dateYearAry = reactive([]);

        // 月份
        let dateMonthAry = reactive([]);

        // 日期
        let dateDayAry = reactive([]);

        let selectY;
        let selectM;
        let selectD;

        function setSelectY(e){
            selectY = e;
            selectY.setValue(todayY)
        }

        function setSelectM(e){
            selectM = e;
            selectM.setValue(todayM)
        }

        function setSelectD(e){
            selectD = e
            selectD.setValue(todayD)
        window.selectD = selectD

        }

        // 取得打卡紀錄列表
        const getRosterRecord = async () => {
            try {
                const res = await basePost("/api_member/member_punch_list", {
                    store_id: state.auth.store_id,
                    date: dateSelect.date
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    rosterRecord.data = res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };
        getRosterRecord();


        // 取得年份
        const getYearNum = () => {
            for (let i = 2005; i <= new Date().getFullYear(); i++){
                dateYearAry.unshift({
                    id: i,
                    name: i
                });
            }
        }
        getYearNum();

        // 取得月份
        const getMonthNum = () => {
            for (let i = 1; i <= 12; i++){
                dateMonthAry.push({
                    id: i,
                    name: i
                });
            }
        }
        getMonthNum();

        // 取得日期數
        const getDateNum = () => {
            dateDayNum = new Date(dateSelect.year,dateSelect.month,0).getDate()
            if (dateSelect.year && dateSelect.month) {
                 dateDayAry.length = 0;
                 for (let i = 1; i <= dateDayNum; i++){
                    dateDayAry.push({
                        id: i,
                        name: i
                    });
                }
             }
        }
        getDateNum()

        // 取得選中的日期
        const handleYearChange = (selectVal) => {
            dateSelect.year = selectVal.value;
            getDateNum();
        };
        const handleMonthChange = (selectVal) => {
            dateSelect.month = selectVal.value;
            getDateNum();

            if ( dateSelect.day > dateDayAry.length ){
                selectD.setValue(1)
            }
        };

        const handleDayChange = (selectVal) => {
            dateSelect.day = selectVal.value;
        };

        watch(dateSelect, () => {
            getRosterRecord();
        })


        watch(rosterRecord, () => {
            if(!isListRequest.value) {
                isListRequest.value = true;
            }
        })

        // 是否為準時上班
        const isOnEntryTime = (workTime, entryTime) => {
            if(workTime && entryTime) {
                const selectedDate = `${dateSelect.year}-${dateSelect.month}-${dateSelect.day}`;
                const correctEntryTime = workTime.split('-')[0];
                const isSameOrBefore = moment(`${selectedDate} ${entryTime}`).isSameOrBefore(`${selectedDate} ${correctEntryTime}`);

                return isSameOrBefore;
            }
        };

        const isOnOutTime = (workTime, outTime) => {
            if(workTime && outTime) {
                const selectedDate = `${dateSelect.year}-${dateSelect.month}-${dateSelect.day}`;
                const correctOutTime = workTime.split('-')[1];
                const isSameOrAfter = moment(`${selectedDate} ${outTime}`).isSameOrAfter(`${selectedDate} ${correctOutTime}`);

                return isSameOrAfter;
            }
        }

        return {
            rosterRecord,
            handleYearChange,
            handleMonthChange,
            handleDayChange,
            dateYearAry,
            dateMonthAry,
            dateDayAry,
            isListRequest,

            todayY,
            todayM,
            todayD,

            setSelectY,
            setSelectM,
            setSelectD,

            isOnEntryTime,
            isOnOutTime
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.clock-in {
    .index-container {
        .title {
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            color: #fff;
            margin-left: 50px;
            margin-bottom: 30px;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: -15px;
                border-left: 7px solid #fff;
            }
        }
    }
    .wrap {
        max-width: 1400px;
        margin: 0 auto;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        @include pad_pro_lg {
            padding: 0;
        }
        @include phone {
            padding: 20px;
        }
        .input-group {
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #ffffff;
            border-radius: 11px;
            height: 53px;
            width: 319px;
            position: absolute;
            right: -5px;
            top: -67px;
            @include phone {
                height: 45px;
                position: relative;
                top: 0;
                right: 10px;
            }
        }

        .reserve-container {
            justify-content: center;
            @include pad_pro_lg {
                overflow: overlay;
                justify-content: start;
            }
            .reserve-wrap {
                position: relative;
                width: unset;
                padding: 0;
            }
            .order-list-wrap {
                min-height: 959px;
                margin-bottom: 15px;
                box-shadow: 0px 34px 77px #00000042;
                border: 1px solid #ffffff;
                border-radius: 15px;
                filter: contrast(100%);
                transition: all 0.3s;
                width: 1400px;
                &:hover {
                    filter: contrast(105%);
                }
                @include pad_pro_lg {
                    box-shadow: unset;
                }
                .row {
                    .col-1 {
                        flex: 0 0 10.33333%;
                        max-width: 10.33333%;
                    }
                    .col-2 {
                        flex: 0 0 12.66666%;
                        max-width: 12.66666%;
                    }
                }
            }
            .order-list-wrap {
                background: transparent
                    linear-gradient(
                        122deg,
                        #ffffff 0%,
                        #d9d9d9 18%,
                        #ffffff 100%
                    )
                    0% 0% no-repeat padding-box;
                padding: 75px;
                padding-bottom: 45px;
                font: normal normal bold 16px/21px Microsoft JhengHei;
                @include pad_pro_lg {
                    padding: 0 30px;
                    padding-bottom: 40px;
                    padding-top: 60px;
                }
                @include pad_pro {
                    margin-bottom: 15px;
                }
                @include pad {
                    font-size: 14px;
                }
                .order-list-header {
                    position: relative;
                    top: -35px;
                    @include pad_pro_lg {
                        top: -22px;
                    }
                }
                .order-list {
                    background: transparent
                        linear-gradient(
                            180deg,
                            #ececec 0%,
                            #f9f9f9 35%,
                            #ffffff 100%
                        )
                        0% 0% no-repeat padding-box;
                    box-shadow: -3px -4px 8px #ffffff9c, 0px 3px 6px #00000029;
                    border: 1px solid #ffffff;
                    border-radius: 11px;
                    padding: 30px 0;
                    align-items: center;
                    margin-bottom: 15px;
                    filter: contrast(100%);
                    transition: all 0.3s;
                    &:hover {
                        filter: contrast(90%);
                    }
                    .morning-color {
                        color: #b77f1a;
                    }
                    .evening-color {
                        color: #6d4545;
                    }
                    .night-color {
                        color: #0f2a68;
                    }
                    .gray {
                        color: #7d7d7d;
                    }
                    .gold {
                        color: #a4641a;
                    }
                    .no-on-time-color {
                        color: #d94343;
                    }
                    .blue {
                        color: #0f2a68;
                    }
                }
            }
        }
    }
}
</style>
