import store from "@/store/index";
const { commit } = store;

function useHandleLightboxOpen() {
    const openSideMenu = () => {
        commit("global/update", { key: "isSideMenuShow", value: true });
    };
    const closeSideMenu = () => {
        commit("global/update", { key: "isSideMenuShow", value: false });
    };

    const openCheckoutLightbox = () => {
        commit("global/update", { key: "isCheckoutLightboxShow", value: true });
    };
    const closeCheckoutLightbox = () => {
        commit("global/update", { key: "isCheckoutLightboxShow", value: false});
    };

    const openPreMassageLightbox = () => {
        commit("global/update", { key: "isPreMassageLightboxShow", value: true });
    };
    const closePreMassageLightbox = () => {
        commit("global/update", { key: "isPreMassageLightboxShow", value: false });
    };

    const openCouponLightbox = () => {
        commit("global/update", { key: "isCouponLightboxShow", value: true });
    };
    const closeCouponLightbox = () => {
        commit("global/update", { key: "isCouponLightboxShow", value: false });
    };

    const openChangeCouponLightbox = () => {
        commit("global/update", { key: "isChangeCouponLightboxShow", value: true });
    };
    const closeChangeCouponLightbox = () => {
        commit("global/update", { key: "isChangeCouponLightboxShow", value: false });
    };

    const openEditStockLightbox = () => {
        commit("global/update", { key: "isEditStockLightboxShow", value: true });
    };
    const closeEditStockLightbox = () => {
        commit("global/update", { key: "isEditStockLightboxShow", value: false });
    };

    const openStartServiceLightbox = () => {
        commit("global/update", { key: "isStartServiceLightboxShow", value: true });
    };
    const closeStartServiceLightbox = () => {
        commit("global/update", { key: "isStartServiceLightboxShow", value: false });
    };

    const openAddReservation = () => {
        commit("global/update", { key: "isAddReservationShow", value: true });
    };
    const closeAddReservation = () => {
        commit("global/update", { key: "isAddReservationShow", value: false });
    };

    const openShiftLightbox = () => {
        commit("global/update", { key: "isShiftLightboxShow", value: true });
    };
    const closeShiftLightbox = () => {
        commit("global/update", { key: "isShiftLightboxShow", value: false });
    };

    const openMerchandiseLightbox = () => {
        commit("global/update", { key: "isMerchandiseLightboxShow", value: true });
    };
    const closeMerchandiseLightbox = () => {
        commit("global/update", { key: "isMerchandiseLightboxShow", value: false });
    };

    return {
        openSideMenu,
        closeSideMenu,
        openCheckoutLightbox,
        closeCheckoutLightbox,
        openPreMassageLightbox,
        closePreMassageLightbox,
        openCouponLightbox,
        closeCouponLightbox,
        openChangeCouponLightbox,
        closeChangeCouponLightbox,
        openEditStockLightbox,
        closeEditStockLightbox,
        openStartServiceLightbox,
        closeStartServiceLightbox,
        openAddReservation,
        closeAddReservation,
        openShiftLightbox,
        closeShiftLightbox,
        openMerchandiseLightbox,
        closeMerchandiseLightbox
    };
}

export default useHandleLightboxOpen;
