<template>
  <div class="customer">
    <AromaSideMenu />
    <div class="top">
      <AromaLogo />
    </div>
    <div class="index-container">
      <div class="wrap">
        <div class="title_box">
          <div class="title expansion d-none d-xl-block">工資單</div>
          <div class="date_box">
            <div class="date_box_item">
              <Datepicker uid="auto" month-picker v-model="date_start" :format="getFormatDate" auto-apply />
              <div class="lang-select-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9">
                  <path id="Polygon_1" data-name="Polygon 1"
                    d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z"
                    transform="translate(17 9) rotate(180)" fill="#A4641A" />
                </svg>
              </div>
            </div>
            <div class="line"></div>
            <div class="date_box_item">
              <Datepicker uid="auto" month-picker v-model="date_end" :format="getFormatDate" auto-apply />
              <div class="lang-select-arrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="9" viewBox="0 0 17 9">
                  <path id="Polygon_1" data-name="Polygon 1"
                    d="M7.773.77a1,1,0,0,1,1.454,0l6.18,6.544A1,1,0,0,1,14.68,9H2.32a1,1,0,0,1-.727-1.687Z"
                    transform="translate(17 9) rotate(180)" fill="#A4641A" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="wrap">
        <div class="aroma-payroll">
          <transition appear name="fade" v-if="isLoading">
            <NoData :isFixed="false" />
          </transition>
          <transition-group appear name="fade" v-else>
            <ul class="payroll-list">
              <li class="list-item" v-for="item in aromaPayroll.data" :key="item.id">
                <router-link :to="{ name: 'AromaPayrollDetail', params: { id: item.id } }" class="link">
                  <span class="item-title">{{ `${item.year}年${item.month}月 工資單` }}</span>
                  <span class="confirmed" v-if="item.confirm_status === 2">已確認</span>
                  <span class="confirm" v-else>未確認</span>
                </router-link>
              </li>
            </ul>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, computed, nextTick, watch } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { basePost } from "@/js/services/baseService";
// import { round } from "lodash";

import AromaLogo from "@/components/includes/AromaLogo.vue";
import AromaSideMenu from "@/components/includes/AromaSideMenu.vue";
import NoData from "@/components/includes/NoData.vue";

export default {
  name: "AromaPayroll",
  components: {
    AromaLogo,
    AromaSideMenu,
    NoData
  },
  setup() {
    const { state } = useStore();
    const isLoading = ref(false);

    let aromaPayroll = reactive({
      data: []
    });

    const today = moment();

    const date_start = ref({
      month: 0,
      year: today.format("YYYY")
    })

    const date_end = ref({
      month: today.format("M") - 1,
      year: today.format("YYYY")
    })

    const start_date = computed(() => {
      return moment(`${date_start.value.year}-${date_start.value.month + 1}`,'YYYY-M').format("YYYY-MM");
    });

    const end_date = computed(() => {
      return moment(`${date_end.value.year}-${date_end.value.month + 1}`,'YYYY-M').format("YYYY-MM");
    });

    const getFormatDate = (date) => {
      return moment(date).format("YYYY-MM");
    };

    // 取得出勤紀錄列表
    const getSalarySettlementsList = async () => {
      try {
        const res = await basePost("/api_salary/salary_settlements_list", {
          member_id: state.auth.member_id,
          start_date: start_date.value,
          end_date: end_date.value
        });

        let { status, data } = res;

        if (status === 200 && data.res_code === 1) {
          const { res_data } = data;
          aromaPayroll.data = res_data.list;
        }
      } catch (err) {
        console.error(err);
      }
    };

    watch([start_date, end_date], () => {
      getSalarySettlementsList();
    }, { immediate: true })

    return { isLoading, aromaPayroll, getFormatDate, date_start, date_end };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";


.customer {
  .index-container {
    .title {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #ffffff;
      margin-left: 50px;
      margin-bottom: 30px;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        left: -15px;
        border-left: 7px solid #fff;
      }

      a {
        text-decoration: underline !important;
        font: normal normal bold 20px/27px Microsoft JhengHei;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }
}

.wrap {
  max-width: 1424px;
  margin: auto;

  >.title {
    &.expansion {
      margin-left: -88px !important;

      @media (max-width:1480px) {
        margin-left: 0 !important;
      }
    }
  }
}


.aroma-payroll {
  // border: 1px solid #fff;
  border-radius: 15px;
  box-shadow: 0 34px 77px 0 rgba(0, 0, 0, 0.26);
  background: transparent linear-gradient(121deg, #FFFFFF 0%, #E5E5E5 18%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  padding: clamp(30px, 5vw, 75px) clamp(40px, 6vw, 90px);
  border: 1px solid #fff;

  .payroll-list {
    max-height: 680px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 9px;
      background-color: #D6D6D6;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-button {
      background: transparent;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track-piece {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(112, 112, 112, .6);
      border: 1px solid slategrey;
    }

    &::-webkit-scrollbar-track {
      box-shadow: transparent;
    }

    .list-item {
      box-shadow: -3px -4px 8px #FFFFFF9C, 3px 3px 8px #cdcdcd9c;
      border: 1px solid #FFFFFF;
      border-radius: 11px;
      background: transparent linear-gradient(180deg, #ECECEC 0%, #f3f3f3 30%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
      transition: background .3s, box-shadow .3s;

      +.list-item {
        margin-top: 15px;
      }

      &:hover {
        background: transparent linear-gradient(180deg, #D9D6D6 0%, #EEEEEE 81%, #F8F6F6 100%) 0% 0% no-repeat padding-box;
        box-shadow: -3px -4px 8px #FFFFFF9C, 3px 3px 8px #aaaaaa9c;
      }

      .link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: clamp(20px, 2vw, 25px) clamp(30px, 3vw, 50px);
      }

      .item-title {
        color: #B77F1A;
        font-weight: 700;
        letter-spacing: 1px;
      }

      .confirm,
      .confirmed {
        padding: 10px 25px;
        border-radius: 50px;
        font-weight: 700;
      }

      .confirm {
        background: transparent linear-gradient(117deg, #EBC496 0%, #A4641A 36%, #A4641A 100%) 0% 0% no-repeat padding-box;
        color: #ffffff;
      }

      .confirmed {
        background-color: #E1E1E1;
        color: #A4641A;
      }
    }
  }
}

.title_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.date_box {
  display: flex;
  margin-bottom: 30px;
  padding: 14px 36px;
  overflow: hidden;
  background: linear-gradient(180deg, #ececec, #f9f9f9, white);
  box-shadow: -3px -4px 8px 0 rgba(255, 255, 255, 0.61), 0px 3px 6px rgba(0, 0, 0, 0.29);
  border-radius: 11px;
  border: 1px solid white;
  position: relative;
  align-items: center;

  .date_box_item {
    position: relative;
    width: 100px;

    :deep(.dp__input_wrap) {
      input {
        padding-left: 0 !important;
        background: transparent;
      }

      svg {
        display: none;
      }
    }

  }

  .line {
    display: block;
    width: 18px;
    height: 2px;
    background-color: rgba(112, 112, 112, .4);
    margin-left: 20px;
    margin-right: 20px;
  }
}

.lang-select-arrow {
  position: absolute;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  pointer-events: none;
  // svg{
  //     filter: invert(1);
  // }
}
</style>
