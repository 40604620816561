<template>
    <div class="no-print-sec">
        <div class="mask" v-show="isShow" @click="$emit('update:opened', false)"></div>

        <transition name="slide">
            <div class="coupon-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <form class="side-menu" @submit="handleCheckOut">
                        <div
                            class="side-menu-close"
                            @click="$emit('update:opened', false)"
                        >
                            <img
                                src="@/assets/tgd/lightbox/close.svg"
                                alt=""
                            />
                        </div>
                        <div class="side-menu-top pt-3">
                            <div class="side-menu-item">
                                <div class="title mb-3">客戶資訊：</div>
                                <div class="row form">
                                    <div class="col-5">
                                        <BaseInput
                                            title="會員電話"
                                            placeholder="請輸入"
                                            type="tel"
                                            class="form-control"
                                            name="phone"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="會員名稱"
                                            placeholder="請輸入"
                                            type="text"
                                            class="form-control"
                                            name="name"
                                            disabled
                                        />

                                    </div>
                                    <div class="col-5" v-if="opened">
                                        <BaseDatepicker
                                            label="販售日期" 
                                            name="order_date" 
                                            initVal=""
                                        />
                                    </div>
                                    <div class="col-7">
                                        <BaseInput
                                            title="販售時間"
                                            placeholder="請輸入"
                                            type="text"
                                            class="form-control"
                                            name="start_time"
                                            @inputBlurred="timeFormat"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu-item">
                                <div class="form">
                                    <div class="col-5 p-0 mb-3">
                                        <button
                                            type="button"
                                            class="btn"
                                            @click="addCoupon"
                                        >
                                            <img
                                                src="@/assets/tgd/lightbox/add-button.svg"
                                                alt=""
                                            />
                                            <span> 新增票劵 </span>
                                            <span></span>
                                        </button>
                                    </div>
                                    <template
                                        v-for="(field, idx ) in fields"
                                        :key="field.key"
                                    >
                                        <div class="position-relative">
                                            <div class="row g-0">
                                                <div class="col-4">
                                                    <BaseInput
                                                        title="票劵"
                                                        placeholder=""
                                                        type="text"
                                                        class="form-control"
                                                        :name="`ticket[${idx}].start_num`"
                                                        @Blur="keyTicket(values.ticket[idx])"
                                                        :validateOnValueUpdate="false"
                                                    />
                                                </div>
                                                <div class="col-4">
                                                    <div class="input-group overflow-hidden">
                                                        <input
                                                            type="text"
                                                            class="form-control coupon-text text-start"
                                                            v-model="values.ticket[idx].info.name"
                                                            disabled
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div
                                                        class="input-group d-inline-block me-2"
                                                        :style="{
                                                            'width': 'calc(100% - 88px)',
                                                        }"
                                                    >
                                                        <div class="input-group-prepend counter" >
                                                            <span class="input-group-text py-0">
                                                                <a
                                                                    href="javascript:;"
                                                                    @click="
                                                                        values.ticket[idx].amount > 1 && changeAmount(values.ticket[idx], false)
                                                                    "
                                                                >-</a>
                                                                <input
                                                                    type="number"
                                                                    class="form-control text-center amount0number-input"
                                                                    v-model="values.ticket[idx].amount"
                                                                    @change="adjustmentAmount(values.ticket[idx])"
                                                                />
                                                                <a
                                                                    href="javascript:;"
                                                                    @click="changeAmount(values.ticket[idx], true)"
                                                                >+</a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <label class="d-inline-block">
                                                        <input
                                                            type="checkbox"
                                                            v-model="values.ticket[idx].is_send"
                                                            :true-value="1"
                                                            :false-value="0"
                                                        />
                                                        <span>寄劵</span>
                                                    </label>
                                                </div>
                                                <!-- <div
                                                    class="col-12 mt-2"
                                                    style="color:grey; font-size: 14px;"
                                                >{{
                                                    (item.amount > 0 && item.start_num.length == 11)?'購買票券編號 : ' + Math.abs(item.start_num.replace(/[^0-9]/ig, "")) + '~' +  (Math.abs(item.start_num.replace(/[^0-9]/ig, "")) + Math.abs(item.amount) - 1):''
                                                }}</div> -->
                                            </div>
                                            <div
                                                class="cancel-img"
                                                @click="remove(idx)"
                                            >
                                                <a href="javascript:;">
                                                    <img
                                                        src="@/assets/tgd/lightbox/del.svg"
                                                        alt=""
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                            <div class="side-menu-item">
                                <div class="form">
                                    <div class="col-5 p-0 mb-3">
                                        <button
                                            type="button"
                                            class="btn violation"
                                            @click="addPackageCoupon"
                                        >
                                            <img
                                                src="@/assets/tgd/lightbox/add-button.svg"
                                                alt=""
                                            />
                                            <span> 新增套組 </span>
                                            <span></span>
                                        </button>
                                    </div>
                                    <template
                                        v-for="(field, idx) in fieldsPackage"
                                        :key="field.key"
                                    >
                                        <div class="position-relative">
                                            <div class="row">
                                                <div
                                                    class="col-5 position-relative violation"
                                                    style="z-index: 100"
                                                >
                                                    <BaseSelect
                                                        :options="couponSelect.data.package_ticket"
                                                        :name="`package_ticket[${idx}].package_id`"
                                                        @selectChange="
                                                            changePackageTicket(idx)
                                                        "
                                                    />
                                                    <div
                                                        class="cancel-img"
                                                        @click="removePackage(idx)"
                                                    >
                                                        <a href="javascript:;">
                                                            <img
                                                                src="@/assets/tgd/lightbox/del.svg"
                                                                alt=""
                                                            />
                                                        </a>
                                                    </div> 
                                                </div>
                                            </div>
                                            <template
                                                v-for="(val, index ) in field.value.ticket_list"
                                                :key="field.key"
                                            >
                                                <div class="row g-0">
                                                    <div class="col-4">
                                                        <BaseInput
                                                            title="票劵"
                                                            placeholder=""
                                                            type="text"
                                                            class="form-control"
                                                            :name="`package_ticket[${idx}].ticket_list[${index}].start_num`"
                                                            @Blur="keyTicket(values.package_ticket[idx].ticket_list[index])"
                                                            :validateOnValueUpdate="false"
                                                        />
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="input-group overflow-hidden">
                                                            <input
                                                                type="text"
                                                                class="form-control coupon-text text-start"
                                                                v-model="values.package_ticket[idx].ticket_list[index].name"
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div
                                                            class="input-group d-inline-block me-2"
                                                            :style="{'width': 'calc(80% - 88px)'}"
                                                        >
                                                            <div class="input-group-prepend counter" >
                                                                <span class="input-group-text py-0">
                                                                    <input
                                                                        type="text"
                                                                        class="form-control text-center"
                                                                        readonly
                                                                        v-model="values.package_ticket[idx].ticket_list[index].amount"
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <label class="d-inline-block">
                                                            <input
                                                                type="checkbox"
                                                                v-model="values.package_ticket[idx].ticket_list[index].is_send"
                                                                :true-value="1"
                                                                :false-value="0"
                                                            />
                                                            <span>寄劵</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="side-menu-bottom">
                            <div class="side-menu2-item">
                                <div class="row no-gutters sum">
                                    <div class="col-5">小計</div>
                                    <div class="col-7">
                                        $ {{ numberFormat(coupon_count) }}
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu2-item">
                                <div class="form">
                                    <div class="row no-gutters">
                                        <div class="col-5">現金付款</div>
                                        <div class="col-7">
                                            <BaseCalcSelectInput
                                                selectDate=""
                                                inputClass="custom-radius"
                                                placeholder="請輸入"
                                                selectName="calc_select"
                                                inputName="calc_input"
                                            />
                                        </div>
                                    </div>
                                    <div class="row no-gutters">
                                        <div class="col-5">剩餘款項</div>
                                        <div class="col-7">
                                            <div class="row no-gutters">
                                                <div class="col-6 px-0 pay-type">
                                                    信用卡
                                                </div>
                                                <div class="col-6 px-0 text-end fz-20 last-color">
                                                    ${{
                                                        numberFormat(
                                                            coupon_count -
                                                            values.calc_input
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-5"></div>
                                        <div class="col-7">
                                            <BaseInput
                                                rootClass="authorization-input"
                                                title="授權碼"
                                                placeholder="請輸入授權碼"
                                                type="text"
                                                class="form-control"
                                                name="authorization_code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="side-menu2-item">
                                <div class="form">
                                    <div class="row no-gutters">
                                        <div class="col-5">統編</div>
                                        <div class="col-7">
                                            <BaseCalcSelectInput
                                                selectDate=""
                                                inputClass="custom-radius"
                                                placeholder="請輸入"
                                                selectName="select_editor"
                                                inputName="input_editor"
                                            />
                                        </div>
                                    </div>
                                    <div 
                                        v-if="couponSelect.data.store_data &&couponSelect.data.store_data.is_two"
                                        class="row" 
                                    >
                                        <div class="col-5">選擇要開立發票的公司</div>
                                        <div class="col-7 radio-wrap">
                                            <template
                                                v-for="store_data in couponSelect
                                                    .data
                                                    .store_data
                                                    .invoice_list"
                                                :key="store_data.id"
                                            >
                                                <BaseRadio
                                                    type="radio"
                                                    name="invoice_type"
                                                    :label="store_data.uniform_numbers"
                                                    :value="store_data.id"
                                                    v-model="invoice_type"
                                                />
                                            </template>
                                        </div>
                                    </div>
                                    <div class="row pt-3">
                                        <div class="col-5 ps-0">
                                            <div class="input-group">
                                                <button
                                                    class="
                                                        btn
                                                        btn-block
                                                        btn-cancel
                                                    "
                                                    @click="
                                                        $emit('update:opened', false)
                                                    "
                                                >
                                                    取消
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col-7 p-0">
                                            <div class="input-group">
                                                <button
                                                    class="
                                                        btn
                                                        btn-block
                                                        btn-checkout
                                                    "
                                                    :disabled="isSubmitting"
                                                >
                                                    <span>總計</span>
                                                    <div class="price">
                                                        <span>NT$</span>
                                                        <strong>{{
                                                            numberFormat(
                                                                coupon_count -
                                                                values.calc_input
                                                            )
                                                        }}</strong>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, computed, watch, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { debounce } from "lodash";
import { useForm, ErrorMessage, useFieldArray } from "vee-validate";
import * as yup from "yup";
import { basePost } from "@/js/services/baseService";
import { flashMessage } from "@/js/swal";
import { numberFormat, timeFormat } from "@/js/functions";
import moment from 'moment';

export default {
    name: "RecordTicketLightbox",
    components: {
        ErrorMessage,
    },
    props: {
        opened: {
            type: Boolean,
            required: false,
        },
        submitType: {
            type: [Number, String],
            required: true,
        },
        name: String,
        phone: String,
    },
    emits: ["update:opened"],
    setup(props, {emit}) {
        const { state, dispatch } = useStore();
        const isShow = computed(() => props.opened);
        let couponSelect = reactive({ data: {} });

        const storeId = computed(() => state.auth.store_id);

        // 全部票券加起來的價格
        let coupon_count = ref(0);

        // 現在所選的券的價格
        let current_coupon_price = ref(0);

        // form驗證設定
        const validationSchema = yup.object({
            phone: yup
                .string()
                .trim('不可留空')
                .matches(/^[0-9]+$/, '請輸入數字')
                .required("必填"),
            name: yup.string().required("必填"),
            calc_input: yup.string().required("必填"),
            ticket: yup.array()
                .of(
                    yup.object({
                        start_num: yup.string().required("必填")
                    })
                ).strict(),
            package_ticket: yup.array()
                .of(
                    yup.object({
                        ticket_list: yup.array()
                        .of(
                            yup.object({
                                start_num: yup.string().required("必填")
                            })
                        )
                        .strict(),
                    })
                ).strict(),
            authorization_code: yup.string().when("calc_input", (calc_input, schema) => {
                if (coupon_count.value - Number(calc_input) > 0) {
                    return schema.required("必填");
                }
                return schema;
            }),
            order_date: yup.string().required('必填'),
            start_time: yup
                .string()
                .matches(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, '請輸入正確格式')
                .required('必填'),
        });

        const { values, handleSubmit, isSubmitting, setFieldValue, resetForm, setValues } =
            useForm({
                validateOnMount: false,
                validationSchema,
                // initialValues: {
                //     invoice_type: 1
                // }
            });

        const { remove, push, fields, replace } = useFieldArray("ticket");
        const { remove: removePackage, push: pushPackage, fields: fieldsPackage, replace: replacePackage } = useFieldArray("package_ticket");
        replace([]);
        replacePackage([]);


        const handleCheckOut = handleSubmit(async (values) => {

            const order_date_time = `${values.order_date} ${values.start_time}:00`;
            // 新增的時間 需要是昨天以前
            if (!moment(order_date_time, 'YYYY-MM-DD hh:mm:ss').isBefore(moment('08:00:00', 'hh:mm:ss'))) {
                flashMessage('請選擇昨天以前的時間', 'error');
                return;
            }

            try {
                let ticket = []
                let package_ticket = []
                if(values.ticket){
                    ticket = values.ticket.map(item =>{
                        return {
                            start_num: item.start_num,
                            amount: item.amount,
                            is_send: item.is_send,
                            type: item.info.type,
                            ticket_id: item.info.ticket_id,
                        }
                    });
                    var has0Amount = ticket.find(item => item.amount <= 0 )
                    if(has0Amount){
                        flashMessage('請輸入票劵數量', 'error');
                        return;
                    }
                }
                if(values.package_ticket){
                    package_ticket = values.package_ticket.map(item=> {
                        return {
                            package_id: item.package_id,
                            ticket_list: item.ticket_list.map(val =>{
                                return {
                                    start_num: val.start_num,
                                    amount: val.amount,
                                    is_send: val.is_send,
                                    type: val.type,
                                    ticket_id: val.ticket_id,
                                }
                            })
                        }
                    })
                }
                const res = await basePost("/api_ticket/buy_ticket_check", {
                    store_id: storeId.value,
                    ticket: JSON.stringify(ticket),
                    package_ticket: JSON.stringify(package_ticket),
                    cash_payment: values.calc_input,
                    payment_method: values.pay_type || 'credit',
                    customer_phone: values.phone,
                    uniform_numbers: values.input_editor,
                    name: values.name,
                    authorization_code: values.authorization_code,
                    invoice_type: values.invoice_type,
                    order_date_time
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    flashMessage("購買成功");
                    emit('update');
                    emit('update:opened', false)
                    // 訂單是否開立發票 0不開立 1開立
                    if(data.res_data.is_issue_invoice == 0) return;
                    dispatch('ticket/getTicketHtml', data.res_data.order_id);
                } else {
                    flashMessage(data.res_content, "error");
                }
            } catch (err) {}
        });

        //取得下拉選項
        const getCouponSelect = async () => {
            try {
                const res = await basePost("/api_ticket/buy_ticket", {
                    store_id: storeId.value,
                });
                const { status, data } = res;
                couponSelect.data = res.data.res_data;

                // console.log('=================setData',couponSelect.data)

                if (couponSelect.data.store_data && couponSelect.data.store_data.invoice_list && couponSelect.data.store_data.invoice_list.length > 0) {
                    setValues({ ...values, invoice_type: couponSelect.data.store_data.invoice_list[0].id });
                }

            } catch (err) {
                console.error(err);
            }

            values.calc_input = 0;

        };

        watch(isShow, () => {
            if (isShow.value) {
                resetForm();
                replace([]);
                replacePackage([]);
                getCouponSelect();
                setFieldValue('phone', props.phone);
                setFieldValue('name', props.name);
            }
        });


        watch([
                () => values.calc_input,
                () => values.ticket,
                () => values.package_ticket
            ],
            () => {
                countResult();
            }
        );

        const countResult = async () => {
            let total_amount = 0
            if(values.ticket) {
                total_amount += values.ticket
                    .map((item) => {
                        return (item.info && item.info.total_price) || 0
                    })
                    .reduce((total, now) => {
                        return total + now;
                    }, 0);
            }
            if(values.package_ticket) {
                total_amount += values.package_ticket
                    .map((item) => {
                        return item.total_price || 0
                    })
                    .reduce((total, now) => {
                        return total + now;
                    }, 0);
            }
            coupon_count.value = total_amount;
        };

        const addCoupon = () => {
            push({ amount: 1, start_num: "" , info: {}, is_send: 0 });
        };

        const addPackageCoupon = () => {
            const first = couponSelect.data.package_ticket[0];
            const firstTicketList = first.ticket.map(item =>{
                return {
                    type: item.type,
                    amount: item.num,
                    name: item.ticket_name,
                    ticket_id: item.ticket_id,
                    start_num: '',
                    is_send: 0
                }
            })
            pushPackage({ package_id: first.id, total_price: first.price, ticket_list: [...firstTicketList]});
        };

        // 更換套組選項時
        const changePackageTicket = (idx) => {
            const thisPackage = values.package_ticket[idx];

            const newPackage = couponSelect.data.package_ticket.find(item => {
                return item.id === thisPackage.package_id
            })

            values.package_ticket[idx].total_price = newPackage.price;
            values.package_ticket[idx].ticket_list = newPackage.ticket.map(item => {
                return {
                    type: item.type,
                    amount: item.num,
                    name: item.ticket_name,
                    ticket_id: item.ticket_id,
                    start_num: '',
                    is_send: 0
                }
            });
            countResult();
        };

        //驗證票
        const ticketVerify = async (item) => {
            if(item.start_num === '') return;
            const res = await basePost("/api_ticket/useTicketVerify", {
                store_id: storeId.value,
                amount: item.amount,
                start_num: item.start_num,
            });
            const { status, data } = res;
            if (status === 200 && data.res_code === 1) {
                item.info = data.res_data;
            } else {
                item.info = {};
                flashMessage(data.res_content, "error", 3000);
            }
            countResult();
        }
        const keyTicket = debounce(ticketVerify, 500)

        function changeAmount(item, bool) {
            if(bool){
                item.amount ++;
            }else{
                item.amount --;
            }
            keyTicket(item);
        }
        function adjustmentAmount(item) {
            if(item.amount > 0) {
                keyTicket(item);
            }else{
                flashMessage('請輸入票劵數量', 'error');
            }
        }

        return {
            isShow,
            values,
            handleCheckOut,
            isSubmitting,
            couponSelect,
            remove,
            push,
            fields,
            coupon_count,
            current_coupon_price,
            addCoupon,
            changeAmount,
            adjustmentAmount,
            keyTicket,
            addPackageCoupon,
            removePackage,
            fieldsPackage,
            changePackageTicket,
            numberFormat,
            timeFormat
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 800px;
.coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0px;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;

    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu {
            box-shadow: -3px -4px 41px #2e2e2e5e;
            position: absolute;
            z-index: 3;
            height: 100%;
            width: 100%;
            background: #e1e1e1;
        }
        .side-menu-top {
            background: transparent
                linear-gradient(180deg, #ececec 0%, #f9f9f9 35%, #ffffff 100%)
                0% 0% no-repeat padding-box;
            .side-menu-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #eee;
                @include phone {
                    padding: 20px;
                }
                label {
                    input[type="checkbox"] {
                        display: none;
                    }
                    input[type="checkbox"] + span {
                        display: inline-block;
                        padding-left: 35px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/check.svg")
                            no-repeat;
                        user-select: none;
                        font: normal normal 600 20px/27px Open Sans;
                        letter-spacing: 0px;
                        color: #986d46;
                    }
                    input[type="checkbox"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/checked.svg")
                            no-repeat;
                    }
                }
                .col-4 {
                    padding: 0 5px;
                }
                .col-5 {
                    flex: 0 0 45%;
                    max-width: 45%;
                }
                .col-7 {
                    flex: 0 0 55%;
                    max-width: 55%;
                }
                .col-5 {
                    padding-right: 0;
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                    }
                }
                .col-7 {
                    @include phone {
                        flex: 0 0 100% !important;
                        max-width: 100% !important;
                        padding: 0;
                    }
                }

                .cancel-img {
                    position: absolute;
                    right: -40px;
                    top: 50%;
                    transform: translate(0%, -57%);
                    img {
                        filter: contrast(100%);
                        transition: all 0.3s;
                        &:hover {
                            filter: contrast(130%);
                        }
                    }
                }
                .title {
                    color: #a4641a;
                    font-size: 18px;
                    font-weight: bold;
                }
                .form {
                    .input-group {
                        box-shadow: -3px -4px 8px #ffffff9c,
                            0px 3px 6px #00000029;
                        border: 1px solid #ffffff;
                        border-radius: 11px;
                        height: 53px;
                        align-items: center;
                        font: normal normal bold 20px/22px Open Sans;
                        letter-spacing: 0px;
                        color: #a4641a;
                        background: transparent
                            linear-gradient(
                                180deg,
                                #ececec 0%,
                                #f9f9f9 35%,
                                #ffffff 100%
                            )
                            0% 0% no-repeat padding-box;
                        .counter {
                            flex: 20%;
                            @include hover(90%);
                            .input-group-text {
                                font: normal normal bold 22px/30px Open Sans;
                                letter-spacing: 0px;
                                color: #a4641a;
                                a {
                                    font: normal normal bold 24px/30px Open Sans;
                                    letter-spacing: 0px;
                                    color: #a4641a;
                                    @include hover(200%);
                                }
                            }
                        }
                        .input-group-text {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px;
                            padding: 13px;
                            position: relative;
                            font-weight: bold;
                            width: 100%;
                            justify-content: space-between;
                            &:after {
                                display: none;
                            }
                        }

                        .form-control {
                            background: transparent
                                linear-gradient(
                                    180deg,
                                    #ececec 0%,
                                    #f9f9f9 35%,
                                    #ffffff 100%
                                )
                                0% 0% no-repeat padding-box;
                            border: none;
                            border-radius: 11px !important;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                        }
                        .money-text {
                            height: 100%;
                            text-align: right;
                            font: normal normal bold 19px/26px Open Sans;
                            letter-spacing: 0px;
                            color: #d94343;
                        }
                        .coupon-text {
                            color: #a4641a;
                            @include phone {
                                text-align: center;
                            }
                        }
                        .gray-text {
                            color: #707070;
                            @include phone {
                                text-align: center;
                            }
                        }
                        select {
                            flex: 1;
                            background: #f9f9f9
                                url("~@/assets/tgd/lightbox/select-arrow-box.svg")
                                no-repeat 100.5%;
                            border: none;
                            border-radius: 11px;
                            padding: 15px;
                            font: normal normal 600 16px/22px Open Sans;
                            appearance: none;
                            filter: contrast(100%);
                            transition: all 0.3s;
                            &:hover {
                                filter: contrast(130%);
                            }
                        }
                    }
                    .btn {
                        width: 100%;
                        border-radius: 11px;
                        padding: 15px;
                        background: transparent
                            linear-gradient(
                                282deg,
                                #a4641a 0%,
                                #b57831 36%,
                                #a4641a 88%,
                                #ebc496 100%,
                            );
                        box-shadow: 0px 3px 6px #00000029;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border: 1px solid #ffffff85;
                        &.violation {
                            background: transparent
                                linear-gradient(
                                    282deg,
                                    #481e00 0%,
                                    #9c5828 36%,
                                    #cf9064 88%,
                                    #8f5126 100%,
                                );
                        }
                    }
                }
            }
        }
        .side-menu-bottom {
            background: #e1e1e1;
            .side-menu2-item {
                padding-top: 30px;
                padding-bottom: 20px;
                padding-left: 45px;
                padding-right: 72px;
                border-bottom: 1px solid #ccc;
                @include phone {
                    padding: 20px;
                }
                label {
                    margin-bottom: 10px !important;
                    margin-right: 10px !important;
                    input[type="radio"] {
                        display: none;
                    }
                    input[type="radio"] + span {
                        display: inline-block;
                        padding-left: 38px;
                        line-height: 28px;
                        background: url("~@/assets/tgd/lightbox/radio-none.svg")
                            no-repeat;
                        user-select: none;
                    }
                    input[type="radio"]:checked + span {
                        background: url("~@/assets/tgd/lightbox/radio-checked.svg")
                            no-repeat;
                        color: #a4641a;
                    }
                    span {
                        font-size: 16px;
                    }
                }
                .sum {
                    letter-spacing: 0px;
                    color: #000000;
                    .col-5 {
                        font: normal normal bold 18px/24px Microsoft JhengHei;
                    }
                    .col-7 {
                        font: normal normal bold 20px/27px Open Sans;
                        text-align: right;
                    }
                }
                .col-5 {
                    flex: 0 0 48%;
                    max-width: 48%;
                    @include phone {
                        flex: 0 0 40% !important;
                        max-width: 40% !important;
                        padding-right: 5px;
                    }
                }
                .col-7 {
                    flex: 0 0 52%;
                    max-width: 52%;
                    @include phone {
                        flex: 0 0 60% !important;
                        max-width: 60% !important;
                        padding-left: 5px !important;
                    }
                }
                .col-4 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .col-8 {
                    @include phone {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
                .form {
                    font: normal normal bold 18px/24px Microsoft JhengHei;
                    letter-spacing: 0px;
                    color: #000000;
                    .row {
                        align-items: center;
                        padding-bottom: 10px;
                        .last-color {
                            letter-spacing: 0px;
                            color: #c80036;
                            font: normal normal bold 18px/27px Open Sans;
                        }
                        .fz-20 {
                            font-size: 20px;
                        }
                        .input-group {
                            border-radius: 11px;
                            height: 70px;
                            .input-group-text {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 11px 0px 0px 11px;
                                padding: 15px;
                                font-weight: bold;
                                width: 100%;
                                &:after {
                                    display: none;
                                }
                            }
                            .money-text {
                                color: #a4641a;
                            }
                            .select-icon {
                                font-weight: blod;
                                color: #a4641a;
                                font-size: 30px;
                                text-align: center;
                            }

                            .form-control {
                                background: #f6f6f6;
                                border: none;
                                border-radius: 0px 11px 11px 0px;
                                padding: 15px;
                                font: normal normal 600 20px/22px Open Sans;
                                text-align: right;
                            }
                            .custom-rounded {
                                border-radius: 11px;
                            }
                            .btn {
                                height: 70px;
                                border-radius: 11px;
                                padding: 15px;
                                box-shadow: 0px 3px 6px #00000029;
                                color: #fff;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                font: normal normal bold 18px/24px Microsoft
                                    JhengHei;
                                letter-spacing: 0px;
                                @include phone {
                                    height: 60px;
                                    font-size: 16px;
                                }
                                span {
                                    position: relative;
                                    top: 2px;
                                }
                                .price {
                                    display: flex;
                                    align-items: center;
                                    font: normal normal 600 16px/22px Open Sans;
                                    strong {
                                        font: normal normal bold 29px/39px Open
                                            Sans;
                                        letter-spacing: 0px;
                                        padding-left: 20px;
                                        @include phone {
                                            font-size: 18px;
                                        }
                                    }
                                }
                            }
                            .btn-cancel {
                                background: #a2a2a2 0% 0% no-repeat padding-box;
                                justify-content: center;
                            }
                            .btn-checkout {
                                background: #4a4a4a 0% 0% no-repeat padding-box;
                            }
                        }
                        .radio-wrap {
                            box-sizing: border-box;
                            padding-top: 10px;
                            padding-left: 0;
                        }
                    }
                }

                .pay-type {
                    height: 43px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }

                :deep(.authorization-input) {
                    margin-bottom: 0 !important;
                    box-shadow: none !important;
                }
            }
        }
    }
}

.violation {
    :deep(.base-select) {
        .arrow-icon {
            background: transparent linear-gradient(1deg,  #CF9064 0%, #9C5828 61%, #81471D 100%) 0 0 no-repeat;
        }
    }
}

.amount0number-input {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

</style>
